
.home {

  main {
    .contents {
      padding-top: 0;
    }
  }
  
  .page {
    padding: 0;
  }

  .kv__outer {
    width: 100%;
    border-radius: 0;
    margin: 0;
    height: 100vh;  // JSでセットされるが仮で入れておく
    @include mq(sp) {
      height: auto;
    }
  }
  .video {
    &__wrap {
      @include mq(sp) {
        overflow: hidden;
      }
    }
    &__main {
      @include mq(sp) {
        margin-left: -150vw;
      }
    }
  }

  .mv {
    &__copy {
      position: absolute;
      left: vw(255, $pc_w);
      top: vw(145, $pc_w);
      z-index: 10;
      overflow: hidden;
      @include mq(sp) {
        left: vw(30, $sp_w);
        top: vw(120, $sp_w);
      }
    }
    &__copy2 {
      position: absolute;
      left: vw(705, $pc_w);
      top: vw(394, $pc_w);
      z-index: 10;
      overflow: hidden;
      @include mq(sp) {
        left: vw(121, $sp_w);
        top: vw(250, $sp_w);
      }
    }
    &__copy3 {
      position: absolute;
      left: vw(675, $pc_w);
      top: vw(494, $pc_w);
      z-index: 10;
      overflow: hidden;
      @include mq(sp) {
        left: vw(7, $sp_w);
        top: vw(370, $sp_w);
      }
    }

    &__line {
      margin-bottom: vw(58, $pc_w);
      @include mq(sp) {
        margin-bottom: vw(14, $sp_w);
      }
    }
    &__ttl {
      opacity: 0;
      overflow: hidden;

      &.-line1 {
        width: vw(699, $pc_w);
        @include mq(sp) {
          width: vw(239, $sp_w);
          display: block;
          font-size: 6px;
          transform: scale(0.8);
          transform-origin: 0 0;
        }
      }
      &.-line2 {
        width: vw(480, $pc_w);
        @include mq(sp) {
          width: vw(164, $sp_w);
          display: block;
          font-size: 6px;
          transform: scale(0.8);
          transform-origin: 0 0;
        }
      }
      &.-line3 {
        width: vw(835, $pc_w);
        @include mq(sp) {
          width: vw(288, $sp_w);
          display: block;
          font-size: 6px;
          transform: scale(0.8);
          transform-origin: 0 0;
        }
      }
      &.-anime {
        animation: mv_ttl 1s cubic-bezier(0.23,1,0.32,1) 0s forwards;
        &.-line1 {
        }
        &.-line2 {
          animation: mv_ttl 1s cubic-bezier(0.23,1,0.32,1) 0.5s forwards;
        }
        &.-line3 {
          animation: mv_ttl 1s cubic-bezier(0.23,1,0.32,1) 1s forwards;
        }
      }
      img {
      }
    }
    &__text {
      box-sizing: border-box;
      margin-top: vw(26, $pc_w);
      width: vw(373, $pc_w);
      opacity: 0;
      overflow: hidden;
      font-family: $font_en;
      font-size: vw(10, $pc_w);
      font-weight: 500;
      line-height: 1.25;
      @include mq(sp) {
        font-size: vw(9, $sp_w);
        transform: scale(0.8);
        margin-top: vw(5, $sp_w);
        width: vw(300, $sp_w);
      }

      &.-anime {
        animation: mv_text 1s cubic-bezier(0.23,1,0.32,1) 0s forwards;
        &.-line1 {
          animation: mv_text 1s cubic-bezier(0.23,1,0.32,1) 0.2s forwards;
        }
        &.-line2 {
          animation: mv_text 1s cubic-bezier(0.23,1,0.32,1) 0.7s forwards;
        }
        &.-line3 {
          animation: mv_text 1s cubic-bezier(0.23,1,0.32,1) 1.2s forwards;
        }
      }
      img {
      }
    }
    &__sign {
      width: vw(681, $pc_w);
      opacity: 0;
      overflow: hidden;
      @include mq(sp) {
        width: vw(223, $sp_w);
      }

      &.-anime {
        animation: mv_ttl 1s cubic-bezier(0.23,1,0.32,1) 1.5s forwards;
      }
    }
    &__message {
      width: vw(990, $pc_w);
      opacity: 0;
      overflow: hidden;
      @include mq(sp) {
        width: vw(361, $sp_w);
      }
      &.-anime {
        animation: mv_text 1s cubic-bezier(0.23,1,0.32,1) 2s forwards;
      }
    }

  }
  // mv

  .section__mission {
    .section__inner {
      position: relative;
      /* height: vw(917, $pc_w); */
      /* height: vw(960, $pc_w); */
      height: vw(1000, $pc_w);
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 0;
      @include mq(sp) {
        height: vw(550, $sp_w);
      }
    }
  }

  &__mission {
    /* padding-top: vw(100, $pc_w); */
    @include mq(sp) {
      padding-top: 0;
    }
  }

  .mission {
    &__inner {
      overflow: hidden;
    }
    &__text {
      text-align: center;
      @include mq(sp) {
        padding-top: 15vw;
      }

      .sp-button {
        @include mq(sp) {
          margin: 5vw auto 0;
        }
      }
    }
    &__title {
      color: $yblue;
      font-weight: 700;
      font-family: $font_en;
      font-size: vw(25, $pc_w);
      @include mq(sp) {
        font-size: vw(25, $sp_w);
      }
    }
    &__ttl {
      font-weight: 700;
      font-size: vw(60, $pc_w);
      margin-top: vw(30, $pc_w);
      letter-spacing: 0.05em;
      @include mq(sp) {
        font-size: vw(25, $sp_w);
        margin-top: vw(18, $sp_w);
        line-height: 1.4;
      }
    }
    &__body {
      font-weight: 700;
      margin-top: vw(30, $pc_w);
      font-size: vw(22, $pc_w);
      line-height: 2;
      letter-spacing: 0.05em;
      @include mq(sp) {
        font-weight: 500;
        font-size: vw(14, $sp_w);
        margin-top: vw(18, $sp_w);
        line-height: 1.6;
      }
    }
    .sp-button {
      display: none;
      @include mq(sp) {
        display: block;
        margin-top: vw(20, $sp_w) auto 0;
        width: vw(28, $sp_w);
      }
    }
    &__image {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate3d(-50%, -50%, 0);
      z-index: -1;
      /* transition: all 0.8s ease-out 0s forwards; */
      transition: all 0.8s ease-out 0s;

      &.-img0 {
        width: vw(338, $pc_w);
        opacity: 0;
        transform: translate3d(-50%, -50%, 0);
        &.-anime {
          opacity: 1;
        }
        @include mq(sp) {
          width: vw(80, $sp_w);
        }
      }
      &.-img1 {
        width: vw(321, $pc_w);
        opacity: 0;
        /* transform: translate3d(-50%, -175%, 0); */
        transform: translate3d(-50%, -260%, 0);
        &.-anime {
          opacity: 1;
          transform: translate3d(-50%, -175%, 0);
          @include mq(sp) {
            transform: translate3d(-50%, -267%, 0);
          }
        }
        @include mq(sp) {
          transform: translate3d(-50%, -345%, 0);
          width: vw(80, $sp_w);
        }
      }
      &.-img2 {
        width: vw(321, $pc_w);
        opacity: 0;
        /* transform: translate3d(50%, -125%, 0); */
        transform: translate3d(141%, -195%, 0);
        &.-anime {
          opacity: 1;
          transform: translate3d(50%, -125%, 0);
          @include mq(sp) {
            transform: translate3d(120%, -250%, 0);
          }
        }
        @include mq(sp) {
          width: vw(80, $sp_w);
          transform: translate3d(180%, -320%, 0);
        }
      }
      &.-img3 {
        width: vw(372, $pc_w);
        opacity: 0;
        /* transform: translate3d(125%, -50%, 0); */
        transform: translate3d(250%, -50%, 0);
        &.-anime {
          opacity: 1;
          transform: translate3d(125%, -50%, 0);
          @include mq(sp) {
            transform: translate3d(190%, -50%, 0);
          }
        }
        @include mq(sp) {
          width: vw(80, $sp_w);
          transform: translate3d(250%, -50%, 0);
        }
      }
      &.-img4 {
        width: vw(321, $pc_w);
        opacity: 0;
        /* transform: translate3d(50%, 25%, 0); */
        transform: translate3d(140%, 90%, 0);
        &.-anime {
          opacity: 1;
          transform: translate3d(50%, 25%, 0);
          @include mq(sp) {
            transform: translate3d(125%, 170%, 0);
          }
        }
        @include mq(sp) {
          width: vw(80, $sp_w);
          transform: translate3d(205%, 250%, 0);
        }
      }
      &.-img5 {
        width: vw(321, $pc_w);
        opacity: 0;
        /* transform: translate3d(-50%, 100%, 0); */
        transform: translate3d(-50%, 223%, 0);
        &.-anime {
          opacity: 1;
          transform: translate3d(-50%, 100%, 0);
          @include mq(sp) {
            transform: translate3d(-50%, 320%, 0);
          }
        }
        @include mq(sp) {
          width: vw(80, $sp_w);
          transform: translate3d(-50%, 370%, 0);
        }
      }
      &.-img6 {
        width: vw(322, $pc_w);
        opacity: 0;
        /* transform: translate3d(-150%, 25%, 0); */
        transform: translate3d(-232%, 105%, 0);
        &.-anime {
          opacity: 1;
          transform: translate3d(-150%, 25%, 0);
          @include mq(sp) {
            transform: translate3d(-220%, 225%, 0);
          }
        }
        @include mq(sp) {
          width: vw(80, $sp_w);
          transform: translate3d(-300%, 305%, 0);
        }
      }
      &.-img7 {
        width: vw(321, $pc_w);
        opacity: 0;
        /* transform: translate3d(-250%, -50%, 0); */
        transform: translate3d(-360%, -50%, 0);
        &.-anime {
          opacity: 1;
          transform: translate3d(-250%, -50%, 0);
          @include mq(sp) {
            transform: translate3d(-300%, -50%, 0);
          }
        }
        @include mq(sp) {
          width: vw(80, $sp_w);
          transform: translate3d(-400%, -50%, 0);
        }
      }
      &.-img8 {
        width: vw(321, $pc_w);
        opacity: 0;
        /* transform: translate3d(-150%, -125%, 0); */
        transform: translate3d(-232%, -184%, 0);
        &.-anime {
          opacity: 1;
          transform: translate3d(-150%, -125%, 0);
          @include mq(sp) {
            transform: translate3d(-225%, -225%, 0);
          }
        }
        @include mq(sp) {
          width: vw(80, $sp_w);
          transform: translate3d(-305%, -305%, 0);
        }
      }
      &.-imgp {
        width: vw(321, $pc_w);
        opacity: 0;
        /* transform: translate3d(-150%, -125%, 0); */
        transform: translate3d(300%, -300%, 0);
        &.-anime {
          opacity: 1;
          transform: translate3d(200%, -225%, 0);
        }
      }
    }
  }
  // mission

  &__service {
    .section-ttl {
      &__en {
        margin: 0 auto;
        @include mq(sp) {
        }
      }
    }
  }

  &__news {
    .section-ttl {
      &__en {
        margin: 0 auto;
      }
    }
  }

  .recruit {
    &__link {
    }
    &__image {
      position: relative;
      width: 100%;
      box-sizing: border-box;
      border-radius: vw(15, $pc_w);
      overflow: hidden;
      @include mq(sp) {
        border-radius: vw(15, $sp_w);
      }
    }
    &__text {
      position: absolute;
      left: 50%;
      top: 50%;
      color: $white;
      text-align: center;
      transform: translateX(-50%) translateY(-50%);
    }
    &__ttl {
      font-family: $font_en;
      font-weight: 700;
      font-size: vw(90, $pc_w);
      @include mq(sp) {
        font-size: vw(30, $sp_w);
      }
    }
    &__body {
      margin-top: vw(15, $pc_w);
      font-size: vw(24, $pc_w);
      font-weight: 700;
      @include mq(sp) {
        margin-top: vw(10, $sp_w);
        font-size: vw(18, $sp_w);
        letter-spacing: 0.05em;
        line-height: 1.5;
        white-space: nowrap;
      }
    }
    &__button {
      border: 1px solid $white;
      border-radius: vw(10, $pc_w);
      display: flex;
      justify-content: center;
      width: vw(147, $pc_w);
      padding: vw(15, $pc_w) 0;
      box-sizing: border-box;
      margin: vw(25, $pc_w) auto 0;

      @include mq(sp) {
        margin: vw(20, $sp_w) auto 0;
        width: vw(127, $sp_w);
        padding: vw(10, $sp_w) 0;
        border: none;
        background: $yblue;
        border-radius: vw(10, $sp_w);
      }

      &--text {
        position: relative;
        font-size: vw(14, $pc_w);
        font-weight: 700;
        margin-left: -1em;
        @include mq(sp) {
          font-size: vw(14, $sp_w);
        }
        &:after {
          content: "";
          border-top: 1px solid $white;
          border-right: 1px solid $white;
          width: vw(9, $pc_w);
          height: vw(9, $pc_w);
          position: absolute;
          top: 32%;;
          right: -1em;
          transform: rotate(45deg) translateY(-50%);
          @include mq(sp) {
            top: 32%;
            width: vw(9, $sp_w);
            height: vw(9, $sp_w);
          }
        }
      }
    }
  }
  // recruit

  .office {
    margin-top: vw(30, $pc_w);
    @include mq(sp) {
      margin-top: vw(20, $sp_w);
    }

    .office-list {
      display: flex;
      justify-content: space-between;
      @include mq(sp) {
        flex-direction: column;
      }

      &__item {
        width: 32.125%;
        @include mq(sp) {
          width: 100%;
          margin-bottom: vw(20, $sp_w);
        }
      }
      &__image {
        overflow: hidden;
        border-radius: vw(15, $pc_w);
        @include mq(sp) {
          border-radius: vw(15, $sp_w);
        }
        &--image {
          height: vw(250, $pc_w);
          object-fit: cover;
          @include mq(sp) {
            height: vw(120, $sp_w);
          }
        }
      }
      &__info {
        margin-top: vw(20, $pc_w);
        @include mq(sp) {
          margin-top: vw(10, $sp_w);
        }
      }
      &__title {
        display: flex;
        align-items: center;
      }
      &__icon {
        width: vw(17, $pc_w);
        margin-right: vw(7, $pc_w);
        box-sizing: border-box;
        @include mq(sp) {
          width: vw(14, $sp_w);
          margin-right: vw(5, $sp_w);
        }
      }
      &__ttl {
        font-size: vw(26, $pc_w);
        font-family: $font_en;
        font-weight: 700;
        @include mq(sp) {
          font-size: vw(16, $sp_w);
        }
      }
      &__ext {
        display: none;
        @include mq(sp) {
          display: block;
          margin-left: vw(5, $sp_w);
          width: vw(10, $sp_w);
        }
      }
      &__address {
        margin-top: vw(10, $pc_w);
        font-size: vw(12, $pc_w);
        @include mq(sp) {
          margin-top: vw(10, $sp_w);
          font-size: vw(12, $sp_w);
          line-height: 1.4;
        }
      }
    }
    // office-list
  }
  // office

  .scroll-dot {
    position: absolute;
    left: 50%;
    /* top: 49.5vw; */
    top: 43vw;
    transform: translateX(-50%);
    z-index: 200;
    @include mq(sp) {
      top: 140vw;
    }

    &__wrap {
      display: flex;
      box-sizing: border-box;
      flex-direction: column;
      align-items: center;
    }
    &__inner {
      position: relative;
      height: vw(80, $pc_w);
      @include mq(sp) {
        height: vw(80, $sp_w);
      }

      &:before {
        content: "";
        width: 0.5px;
        height: 100%;
        background: $black;
        position: absolute;
        top: 0;
        left: 50%;
      }
    }
    &__point {
      width: vw(14, $pc_w);
      height: vw(14, $pc_w);
      position: absolute;
      left: 50%;
      top: vw(-8, $pc_w);
      transform: translateX(-50%);
      animation: point_scroll_front 2.6s ease-out infinite;
      @include mq(sp) {
        width: vw(11, $sp_w);
        height: vw(11, $sp_w);
        animation: point_scroll_front_sp 2.6s ease-out infinite;
        top: vw(-6, $sp_w);
      }

      &:before {
        content: "";
        /* background: rgba(0,0,0,.08); */
        background: rgba(130, 169, 224, 0.8);
        border-radius: 50%;
        width: vw(30, $pc_w);
        height: vw(30, $pc_w);
        position: absolute;
        opacity: 0;
        top: vw(-30, $pc_w);
        left: 50%;
        z-index: 3;
        transform: translateX(-50%);
        animation: point_scroll_back 2.6s ease-out infinite;
        @include mq(sp) {
          width: vw(30, $sp_w);
          height: vw(30, $sp_w);
          top: vw(-15, $sp_w);
          animation: point_scroll_back_sp 2.6s ease-out infinite;
        }
      }
      &:after {
        content: "";
        background: url(/images/dot.png);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        width: vw(14, $pc_w);
        height: vw(14, $pc_w);
        z-index: 5;
        position: absolute;
        left: 0;
        top: vw(-8, $pc_w);
        @include mq(sp) {
          width: vw(11, $sp_w);
          height: vw(11, $sp_w);
          top: vw(-8, $sp_w);
        }
      }
    }
    &__label {
      margin-top: vw(20, $pc_w);
      @include mq(sp) {
        margin-top: vw(20, $sp_w);
      }
    }
    &__text {
      font-family: $font_en;
      font-size: vw(12, $pc_w);
      font-weight: 700;
      @include mq(sp) {
        font-size: vw(12, $sp_w);
      }
    }
  }
  // scroll-dot
}

@keyframes mv_ttl {
  0% {
    opacity: 0;
    transform: translate3d(0,100%,0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0,0,0);
  }
}
@keyframes mv_text {
  0% {
    opacity: 0;
    transform: translate3d(-100%,0,0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0,0,0);
  }
}


@keyframes point_scroll_front {
  0% {
    top: vw(-8, $pc_w);
  }
  100% {
    top: vw(80, $pc_w);
  }
}
@keyframes point_scroll_front_sp {
  0% {
    top: vw(-5, $sp_w);
  }
  100% {
    top: vw(86, $sp_w);
  }
}

@keyframes point_scroll_back {
  0% {
    top: vw(-15, $pc_w);
  }
  90% {
    opacity: 1;
  }
  95% {
    opacity: 1;
  }
  100% {
    opacity: 0.4;
    top: vw(-15, $pc_w);
  }
}

@keyframes point_scroll_back_sp {
  0% {
    top: vw(-17, $sp_w);
  }
  90% {
    opacity: 1;
  }
  95% {
    opacity: 1;
  }
  100% {
    opacity: 0.4;
    top: vw(-17, $sp_w);
  }
}

