

body {
  overflow-x: hidden;

  font-family: $font_jp;
  color: $blk;
  font-size: rem(14);

  @include mq(sp) {
    font-size: vw(13, $sp_w);
  }
}
.font-ja {
  font-family: $font_jp;
}
.font-jp {
  font-family: $font_jp;
}
.font-mincho {
  font-family: $font_mincho;
}
.font-en {
  font-family: $font_en;
}

img {
  /* max-width: 100%; */
  width: 100%;
  height: auto;
  vertical-align: bottom;
}
a {
  text-decoration: none;
  transition: opacity 0.2s ease-in-out;

  &:hover {
    opacity: 0.8;
  }
  &.no-link {
    &:hover {
      opacity: 1;
    }
  }
}
.-hover {
  transition: opacity 0.2s ease-in-out;
  &:hover {
    opacity: 0.8;
  }
}
.dispnone {
  display: none !important;
}
.pc-none {
  @include mq(pc) {
    display: none !important;
  }
}
.sp-none {
  @include mq(sp) {
    display: none !important;
  }
}

.-palt {
  font-feature-settings: "palt";
}


/* layout */

.wrapper {
  width: 100%;
}

.wrap-inner {
  min-height: 100vh;
  box-sizing: border-box;
  margin-bottom: vw(60, $pc_w);
  @include mq(sp) {
    margin-bottom: vw(60, $sp_w);
  }
}

.-block { display: block !important; }
.-inline-block { display: inline-block !important; }
.-inline { display: inline !important; }

.-red { color: $red; }

.txt-center { text-align: center !important; }
.txt-left { text-align: left !important; }
.txt-right { text-align: right !important; }
.txt-bold { font-weight: bold !important; }

.w2em { width: 2em !important; }
.w3em { width: 3em !important; }
.w4em { width: 4em !important; }
.w5em { width: 5em !important; }
.w8em { width: 8em !important; }
.w10em { width: 10em !important; }
.w11em { width: 11em !important; }
.w12em { width: 12em !important; }
.w14em { width: 14em !important; }
.w15em { width: 15em !important; }
.w16em { width: 16em !important; }
.w20em { width: 20em !important; }
.w30em { width: 30em !important; }
.w40em { width: 40em !important; }
.w60em { width: 60em !important; }
.w70em { width: 70em !important; }

.mtop05em { margin-top: 0.5em !important; }
.mtop1em { margin-top: 1em !important; }
.mtop2em { margin-top: 2em !important; }
.mtop3em { margin-top: 3em !important; }
.mtop4em { margin-top: 4em !important; }
.mtop5em { margin-top: 5em !important; }

.mb05em { margin-bottom: 0.5em !important; }
.mb1em { margin-bottom: 1em !important; }
.mb2em { margin-bottom: 2em !important; }
.mb3em { margin-bottom: 3em !important; }
.mb4em { margin-bottom: 4em !important; }
.mb5em { margin-bottom: 5em !important; }

.ml0 { margin-left: 0 !important; }
.ml1em { margin-left: 1em !important; }
.ml2em { margin-left: 2em !important; }
.ml3em { margin-left: 3em !important; }
.ml5em { margin-left: 5em !important; }
.mr0 { margin-right: 0 !important; }
.mr1em { margin-right: 1em !important; }
.mr2em { margin-right: 2em !important; }
.mr3em { margin-right: 3em !important; }
.mr5em { margin-right: 5em !important; }

.em05 { font-size: 0.5em !important; }
.em08 { font-size: 0.8em !important; }
.em10 { font-size: 1em !important; }
.em12 { font-size: 1.2em !important; }
.em15 { font-size: 1.5em !important; }
.em20 { font-size: 2em !important; }


.bg-gray {
  background: #eee;
}

.-nowrap {
  white-space: nowrap;
}
.-break-all {
  word-break: break-all;
}

.-zoom {
  img {
    transition: all 0.5s;
  }
  &:active,
  &:hover {
    img {
      transform: scale(1.05, 1.05);
    }
  }
}

.underline {
  text-decoration: underline;
}

