
/* 対角線の長さから1辺の長さを返す */
@function diagonal2line($width) {
  @return calc($width / 1.41421356237);
}

/* 1辺の長さから対角線の長さを返す */
@function line2diagonal($width) {
  @return calc($width * 1.41421356237);
}
