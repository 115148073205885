/**
 * 接頭辞変数（for Global）
 *
 * Description:  .l- Layoutレイヤー
 *               .c- Componentレイヤー
 *               .p- Projectレイヤー
 *               .u- Utilityレイヤー
 *               .t- Themeレイヤー
 *               .s- Scopeレイヤー
 *               .qa- .te- QA/Testレイヤー
 *               .js- クリックなどのイベントが発生している要素に付与する
 *                    JavaScriptから参照される要素（スタイルは当てない
 *
 */

$layout-prefix:    'l' !default;
$component-prefix: 'c' !default;
$modifire-prefix:  'm' !default;
$project-prefix:   'p' !default;
$utility-prefix:   'u' !default;
$theme-prefix:     't' !default;
// $scope-prefix:  's' !default;
// $qa-prefix:     'qa' !default;
$js-prefix:        'js' !default;
