@charset "UTF-8";
/**
 * 接頭辞変数（for Global）
 *
 * Description:  .l- Layoutレイヤー
 *               .c- Componentレイヤー
 *               .p- Projectレイヤー
 *               .u- Utilityレイヤー
 *               .t- Themeレイヤー
 *               .s- Scopeレイヤー
 *               .qa- .te- QA/Testレイヤー
 *               .js- クリックなどのイベントが発生している要素に付与する
 *                    JavaScriptから参照される要素（スタイルは当てない
 *
 */
/* 対角線の長さから1辺の長さを返す */
/* 1辺の長さから対角線の長さを返す */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.home .main {
  margin-top: 0;
}

@media screen and (max-width: 900px) {
  .main {
    padding-bottom: 0;
  }
}

.page {
  padding-left: 0;
  padding: 0;
}

.innerelem {
  max-width: none;
}

@media screen and (max-width: 900px) {
  .pageheader__title {
    bottom: 0;
  }
}

/*
body.recruit-interview,
body.recruit,
body.news,
body.ir {
  .contents {
    padding: 0 vw(160, $pc_w);
    @include mq(sp) {
      padding: 0 vw(15, $sp_w);
    }
  }
}
*/
body.ir .ir-contents * {
  font-size: 1.25vw;
}
@media screen and (max-width: 900px) {
  body.ir .ir-contents * {
    font-size: 3.2vw;
  }
}

.ir__topInfoTxt {
  font-size: 1rem;
}
@media screen and (max-width: 900px) {
  .ir__topInfoTxt {
    font-size: 1rem;
    line-height: 1.5;
  }
}

body.sustainability .para {
  line-height: 1.5;
}
body.sustainability .box-info .text {
  line-height: 1.5;
}

body.recruit .interview__title {
  font-size: 1.25vw;
  line-height: 1.5;
}
@media screen and (max-width: 900px) {
  body.recruit .interview__title {
    font-size: 4.2666666667vw;
  }
}

body.recruit-interview .voice__kv-inner {
  left: 0;
}
body.recruit-interview .voice__content-inner {
  width: 60%;
}
@media screen and (max-width: 900px) {
  body.recruit-interview .voice__content-inner {
    width: 100%;
  }
}
body.recruit-interview .voice__title {
  font-size: 1.875vw;
  line-height: 1.5;
}
@media screen and (max-width: 900px) {
  body.recruit-interview .voice__title {
    font-size: 5.3333333333vw;
  }
}
@media screen and (max-width: 900px) {
  body.recruit-interview .voice__name {
    font-size: 3.7333333333vw;
  }
}
body.recruit-interview .voice__header {
  padding-top: 2.6041666667vw;
}
@media screen and (max-width: 900px) {
  body.recruit-interview .voice__header {
    padding-top: 13.3333333333vw;
  }
}
@media screen and (max-width: 900px) {
  body.recruit-interview .tag.-jobname {
    font-size: 3.2vw;
  }
}
body.recruit-interview .voice__profileIndex {
  line-height: 1.5;
}
@media screen and (max-width: 900px) {
  body.recruit-interview .voice__profileDetail {
    font-size: 3.2vw;
  }
}
@media screen and (max-width: 900px) {
  body.recruit-interview .voice__qaQuestion {
    font-size: 3.7333333333vw;
    line-height: 1.5;
  }
}
body.recruit-interview .voice__qaTitle {
  font-size: 1.875vw;
  font-weight: 700;
}
@media screen and (max-width: 900px) {
  body.recruit-interview .voice__qaTitle {
    font-size: 4.2666666667vw;
    line-height: 1.5;
  }
}
body.recruit-interview .voice__qaText {
  font-size: 1.25vw;
}
@media screen and (max-width: 900px) {
  body.recruit-interview .voice__qaText {
    font-size: 3.7333333333vw;
  }
}
body.recruit-interview .pageelem__title {
  font-size: 1.6666666667vw;
  line-height: 1.5;
}
@media screen and (max-width: 900px) {
  body.recruit-interview .pageelem__title {
    font-size: 5.3333333333vw;
  }
}
body.recruit-interview .pageelem__desc {
  font-size: 1.25vw;
}
@media screen and (max-width: 900px) {
  body.recruit-interview .pageelem__desc {
    font-size: 3.7333333333vw;
  }
}

body.recruit-interview {
  font-size: 0.7291666667vw;
}
@media screen and (max-width: 900px) {
  body.recruit-interview {
    font-size: 3.7333333333vw;
  }
}
body.recruit-interview .voice__qaQuestion {
  font-size: 1.3541666667vw;
}
@media screen and (max-width: 900px) {
  body.recruit-interview .voice__qaQuestion {
    font-size: 4.2666666667vw;
  }
}
body.recruit-interview .voice__qaTitle {
  font-size: 1.3541666667vw;
}
@media screen and (max-width: 900px) {
  body.recruit-interview .voice__qaTitle {
    font-size: 4.2666666667vw;
  }
}
body.recruit-interview .tag.-jobname {
  font-size: 0.8333333333vw;
}
@media screen and (max-width: 900px) {
  body.recruit-interview .tag.-jobname {
    font-size: 2.6666666667vw;
  }
}

/* リクルートの青文字 */
.linkPanel__header .rec__title-en {
  font-size: 1.875vw;
}
@media screen and (max-width: 900px) {
  .linkPanel__header .rec__title-en {
    font-size: 4.2666666667vw;
  }
}

.rec__title-ja {
  font-size: 1rem;
}
@media screen and (max-width: 900px) {
  .rec__title-ja {
    font-size: 0.75rem;
  }
}

.-small .menuCard__name {
  font-size: 1rem;
}

.bradcrumb__item-text a,
.topicpath__item-text a {
  color: initial;
}

.topicpath__item-text::after {
  content: none;
}

.graph-caption sup {
  top: auto;
}
.graph-caption span {
  white-space: nowrap;
}
.graph-caption .item {
  margin-bottom: 1em;
}
@media screen and (max-width: 900px) {
  .graph-caption .item {
    line-height: 1.5;
  }
}

@media screen and (max-width: 900px) {
  .graph-note {
    line-height: 1.5;
  }
}

/* プレスリリースなどのタイトル */
.pageelem__title {
  font-size: 1.4rem;
}
@media screen and (max-width: 900px) {
  .pageelem__title {
    font-size: 1rem;
  }
}

@media screen and (max-width: 900px) {
  .indexNews__header {
    margin-top: 0.85714em;
  }
}

/* もっと読む */
.indexNews__btn .btn__text {
  font-size: 1.2rem;
}
@media screen and (max-width: 900px) {
  .indexNews__btn .btn__text {
    font-size: 1rem;
  }
}

@media screen and (max-width: 900px) {
  .rec__more span {
    font-size: 0.75rem;
  }
}

/* ir */
.ir-contents .s_yearController {
  font-size: 1rem;
}

@media screen and (max-width: 900px) {
  .eir_pdfNote_item img {
    width: 40vw;
  }
}

.pnexIcon,
.eir_pnexNote_item-right {
  display: flex;
  justify-content: flex-end;
}

body.financial-highlights {
  line-height: 1.5;
}

.ir__stockQuoteBtn {
  width: 13.0208333333vw;
}
@media screen and (max-width: 900px) {
  .ir__stockQuoteBtn {
    width: 32vw;
  }
}
body.recruit-benefit .benefit__name {
  font-size: 1rem;
  font-weight: 700;
}
body.recruit-benefit .benefit__summary {
  font-size: 0.8rem;
}

.cards__comment-ttl {
  font-size: 1rem;
}

@media screen and (max-width: 900px) {
  .cards__comment-more, .cards__comment-summary {
    font-size: 0.75rem;
  }
}

@media screen and (max-width: 900px) {
  .cards__filter-btn {
    font-size: 1rem;
  }
}

.-benefit-line {
  display: inline-block;
  vertical-align: bottom;
}

/* sustainability */
@media screen and (max-width: 900px) {
  .main section.innerelem .section-ttl {
    font-size: 1.2rem;
    line-height: 1.5;
  }
}

@media screen and (max-width: 900px) {
  .main section.innerelem .sub-ttl {
    font-size: 1rem;
  }
}

@media screen and (max-width: 900px) {
  body.sustainability .para {
    font-size: 0.8rem;
    line-height: 1.5;
  }
}

@media screen and (max-width: 900px) {
  .sustainability__table table {
    font-size: 0.75rem;
  }
}
@media screen and (max-width: 900px) {
  .sustainability__table table tbody td:first-child {
    vertical-align: middle;
  }
}

body {
  overflow-x: hidden;
  font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", "メイリオ", "Hiragino Kaku Gothic ProN", "Hiragino Sans", sans-serif;
  color: #000;
  font-size: 0.875rem;
}
@media screen and (max-width: 900px) {
  body {
    font-size: 3.4666666667vw;
  }
}

.font-ja {
  font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", "メイリオ", "Hiragino Kaku Gothic ProN", "Hiragino Sans", sans-serif;
}

.font-jp {
  font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", "メイリオ", "Hiragino Kaku Gothic ProN", "Hiragino Sans", sans-serif;
}

.font-mincho {
  font-family: "Yu Mincho", "YuMincho", serif;
}

.font-en {
  font-family: "Montserrat", sans-serif;
}

img {
  /* max-width: 100%; */
  width: 100%;
  height: auto;
  vertical-align: bottom;
}

a {
  text-decoration: none;
  transition: opacity 0.2s ease-in-out;
}
a:hover {
  opacity: 0.8;
}
a.no-link:hover {
  opacity: 1;
}

.-hover {
  transition: opacity 0.2s ease-in-out;
}
.-hover:hover {
  opacity: 0.8;
}

.dispnone {
  display: none !important;
}

@media screen and (min-width: 901px) {
  .pc-none {
    display: none !important;
  }
}

@media screen and (max-width: 900px) {
  .sp-none {
    display: none !important;
  }
}

.-palt {
  font-feature-settings: "palt";
}

/* layout */
.wrapper {
  width: 100%;
}

.wrap-inner {
  min-height: 100vh;
  box-sizing: border-box;
  margin-bottom: 3.125vw;
}
@media screen and (max-width: 900px) {
  .wrap-inner {
    margin-bottom: 16vw;
  }
}

.-block {
  display: block !important;
}

.-inline-block {
  display: inline-block !important;
}

.-inline {
  display: inline !important;
}

.-red {
  color: #ff0000;
}

.txt-center {
  text-align: center !important;
}

.txt-left {
  text-align: left !important;
}

.txt-right {
  text-align: right !important;
}

.txt-bold {
  font-weight: bold !important;
}

.w2em {
  width: 2em !important;
}

.w3em {
  width: 3em !important;
}

.w4em {
  width: 4em !important;
}

.w5em {
  width: 5em !important;
}

.w8em {
  width: 8em !important;
}

.w10em {
  width: 10em !important;
}

.w11em {
  width: 11em !important;
}

.w12em {
  width: 12em !important;
}

.w14em {
  width: 14em !important;
}

.w15em {
  width: 15em !important;
}

.w16em {
  width: 16em !important;
}

.w20em {
  width: 20em !important;
}

.w30em {
  width: 30em !important;
}

.w40em {
  width: 40em !important;
}

.w60em {
  width: 60em !important;
}

.w70em {
  width: 70em !important;
}

.mtop05em {
  margin-top: 0.5em !important;
}

.mtop1em {
  margin-top: 1em !important;
}

.mtop2em {
  margin-top: 2em !important;
}

.mtop3em {
  margin-top: 3em !important;
}

.mtop4em {
  margin-top: 4em !important;
}

.mtop5em {
  margin-top: 5em !important;
}

.mb05em {
  margin-bottom: 0.5em !important;
}

.mb1em {
  margin-bottom: 1em !important;
}

.mb2em {
  margin-bottom: 2em !important;
}

.mb3em {
  margin-bottom: 3em !important;
}

.mb4em {
  margin-bottom: 4em !important;
}

.mb5em {
  margin-bottom: 5em !important;
}

.ml0 {
  margin-left: 0 !important;
}

.ml1em {
  margin-left: 1em !important;
}

.ml2em {
  margin-left: 2em !important;
}

.ml3em {
  margin-left: 3em !important;
}

.ml5em {
  margin-left: 5em !important;
}

.mr0 {
  margin-right: 0 !important;
}

.mr1em {
  margin-right: 1em !important;
}

.mr2em {
  margin-right: 2em !important;
}

.mr3em {
  margin-right: 3em !important;
}

.mr5em {
  margin-right: 5em !important;
}

.em05 {
  font-size: 0.5em !important;
}

.em08 {
  font-size: 0.8em !important;
}

.em10 {
  font-size: 1em !important;
}

.em12 {
  font-size: 1.2em !important;
}

.em15 {
  font-size: 1.5em !important;
}

.em20 {
  font-size: 2em !important;
}

.bg-gray {
  background: #eee;
}

.-nowrap {
  white-space: nowrap;
}

.-break-all {
  word-break: break-all;
}

.-zoom img {
  transition: all 0.5s;
}
.-zoom:active img, .-zoom:hover img {
  transform: scale(1.05, 1.05);
}

.underline {
  text-decoration: underline;
}

@media screen and (max-width: 900px) {
  body.-fixed {
    width: 100%;
    height: 100%;
    position: fixed;
  }
}

main .contents {
  padding-top: 8vw;
  padding-bottom: 0;
}
@media screen and (max-width: 900px) {
  main .contents {
    padding-top: 16vw;
  }
}

.page-ttl__wrap {
  overflow: hidden;
}
.page-ttl__en {
  font-family: "Montserrat", sans-serif;
  font-size: 7.8125vw;
  font-weight: 700;
  color: #000;
  opacity: 0;
}
@media screen and (max-width: 900px) {
  .page-ttl__en {
    font-size: 12vw;
  }
}
.page-ttl__en.-anime {
  /* animation: "page_ttl_en" 3s cubic-bezier(0.23,1,0.32,1) 0s forwards; */
  animation: "page_ttl_en" 1.2s linear 0s forwards;
}
.page-ttl__ja {
  font-size: 1.3020833333vw;
  font-weight: 700;
  color: #000;
  opacity: 0;
  /* transition: all 1s cubic-bezier(0.23,1,0.32,1) 0.5s; */
  transition: all 1.5s linear 0.5s;
}
@media screen and (max-width: 900px) {
  .page-ttl__ja {
    font-size: 3.7333333333vw;
    margin-top: 4vw;
  }
}
.page-ttl__ja.-anime {
  opacity: 1;
}
.page .indexNews__list.news.press_release.-ja {
  flex-wrap: wrap;
}
.page .indexNews__list.news.press_release.-ja .indexNews__item {
  width: 19.6875vw;
  margin-bottom: 2.6041666667vw;
}
@media screen and (max-width: 900px) {
  .page .indexNews__list.news.press_release.-ja .indexNews__item {
    width: 48%;
    margin-bottom: 10.6666666667vw;
  }
}
.page .news-link {
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  margin-top: 1.5625vw;
}
@media screen and (max-width: 900px) {
  .page .news-link {
    margin-top: 5.3333333333vw;
  }
}
.page .news-link__anchor {
  box-sizing: border-box;
  background: #E0E3EA;
  color: #000;
  position: relative;
  border-radius: 0.4166666667vw;
  padding: 0.5208333333vw 2.0833333333vw 0.5208333333vw 1.5625vw;
  text-align: center;
  font-size: 0.7291666667vw;
  text-align: center;
  font-weight: 700;
}
@media screen and (max-width: 900px) {
  .page .news-link__anchor {
    font-size: 3.7333333333vw;
    padding: 2.1333333333vw 8vw 2.1333333333vw 5.3333333333vw;
    border-radius: 2.1333333333vw;
  }
}
.page .news-link__anchor:after {
  content: "";
  border-top: 1px solid #000;
  border-right: 1px solid #000;
  width: 0.4166666667vw;
  height: 0.4166666667vw;
  position: absolute;
  right: 1.5625vw;
  top: 43%;
  transform: rotate(45deg) translateY(-50%);
}
@media screen and (max-width: 900px) {
  .page .news-link__anchor:after {
    width: 2.1333333333vw;
    height: 2.1333333333vw;
    right: 5.3333333333vw;
  }
}
.page .pager .pager-block {
  margin: 0.5208333333vw auto 0;
}
@media screen and (max-width: 900px) {
  .page .pager .pager-block {
    width: 100%;
    margin: 8vw auto 13.3333333333vw;
  }
}
.page .pager .pager-block .paging {
  display: flex;
  justify-content: center;
}
.page .pager .pager-block .paging .item {
  margin: 0 0.78125vw;
}
@media screen and (max-width: 900px) {
  .page .pager .pager-block .paging .item {
    margin: 0 2.1333333333vw;
  }
}
.page .pager .pager-block .paging .item a {
  display: block;
  background: #F9FAFB;
  color: #132638;
  box-sizing: border-box;
  width: 2.6041666667vw;
  height: 2.6041666667vw;
  border-radius: 0.2604166667vw;
  filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.3));
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 1.0416666667vw;
  font-family: "Montserrat", sans-serif;
  transform: translateZ(0);
}
@media screen and (max-width: 900px) {
  .page .pager .pager-block .paging .item a {
    width: 9.0666666667vw;
    height: 13.3333333333vw;
    font-size: 4.2666666667vw;
    border-radius: 1.3333333333vw;
  }
}
@media screen and (max-width: 900px) {
  .page .pager .pager-block .paging .item a.-recruit {
    width: 13.3333333333vw;
  }
}
.page .pager .pager-block .paging .item a.previous-link {
  position: relative;
}
.page .pager .pager-block .paging .item a.previous-link:after {
  content: "";
  display: block;
  border-top: 2px solid #132638;
  border-left: 2px solid #132638;
  width: 0.5208333333vw;
  height: 0.5208333333vw;
  position: absolute;
  left: 60%;
  top: 40%;
  transform: rotate(-45deg) translate(-50%, -50%);
}
@media screen and (max-width: 900px) {
  .page .pager .pager-block .paging .item a.previous-link:after {
    width: 2.6666666667vw;
    height: 2.6666666667vw;
  }
}
.page .pager .pager-block .paging .item a.previous-link.-no-page:after {
  border-top: 2px solid #CDD1DC;
  border-left: 2px solid #CDD1DC;
}
.page .pager .pager-block .paging .item a.next-link {
  position: relative;
}
.page .pager .pager-block .paging .item a.next-link:after {
  content: "";
  display: block;
  border-top: 2px solid #132638;
  border-right: 2px solid #132638;
  width: 0.5208333333vw;
  height: 0.5208333333vw;
  position: absolute;
  left: 40%;
  top: 55%;
  transform: rotate(45deg) translate(-50%, -50%);
}
@media screen and (max-width: 900px) {
  .page .pager .pager-block .paging .item a.next-link:after {
    width: 2.6666666667vw;
    height: 2.6666666667vw;
  }
}
.page .pager .pager-block .paging .item a.next-link.-no-page:after {
  border-top: 2px solid #CDD1DC;
  border-right: 2px solid #CDD1DC;
}
.page .pager .pager-block .paging .item.current a {
  color: #00a9e0;
  cursor: default;
  font-weight: 700;
}

@keyframes page_ttl_en {
  0% {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    color: #000;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
    color: #00a9e0;
  }
}
.section {
  margin-bottom: 8.8541666667vw;
}
@media screen and (max-width: 900px) {
  .section {
    margin-bottom: 21.3333333333vw;
  }
}
.section.-header {
  margin-bottom: 5.2083333333vw;
}
@media screen and (max-width: 900px) {
  .section.-header {
    margin-bottom: 10.6666666667vw;
  }
}
.section.-header.-news {
  margin-bottom: 3.125vw;
}
.section.-header.-index {
  margin-bottom: 4.1666666667vw;
}
@media screen and (max-width: 900px) {
  .section.-header.-index {
    margin-bottom: 6.6666666667vw;
  }
}
.section__inner {
  padding: 0 8.3333333333vw;
}
@media screen and (max-width: 900px) {
  .section__inner {
    padding: 0 4vw;
  }
}
.section-ttl {
  box-sizing: border-box;
}
.section-ttl__wrap {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  overflow: hidden;
}
@media screen and (max-width: 900px) {
  .section-ttl__wrap {
    flex-direction: column;
    align-items: flex-start;
  }
}
.section-ttl__en {
  color: #00a9e0;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 2.6041666667vw;
  opacity: 0;
  transform: translate3d(0, 100%, 0);
}
@media screen and (max-width: 900px) {
  .section-ttl__en {
    font-size: 7.4666666667vw;
  }
}
.section-ttl__en.-anime {
  animation: "page_ttl_en" 1.2s linear 0s forwards;
}
.section-ttl__en.-show {
  opacity: 1;
  transform: none;
}
.section-ttl__ja {
  margin-left: 1.0416666667vw;
  color: #000;
  font-weight: 700;
  font-size: 1.0416666667vw;
  opacity: 0;
  transition: all 2s linear 1s;
}
@media screen and (max-width: 900px) {
  .section-ttl__ja {
    font-size: 3.2vw;
    margin-left: 0;
    margin-top: 2.6666666667vw;
  }
}
.section-ttl__ja.-anime {
  opacity: 1;
}
.section__service {
  position: relative;
}
.section__service .info-box {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 1.0416666667vw;
  position: relative;
  z-index: 1;
}
@media screen and (max-width: 900px) {
  .section__service .info-box {
    flex-direction: column;
    margin-top: 4vw;
  }
}
.section__service .info-box:after {
  content: "";
  background: url(/images/type/service.png) no-repeat center center;
  width: 86.82vw;
  height: 16.14vw;
  background-size: contain;
  position: absolute;
  right: -100vw;
  top: 28%;
  z-index: -1;
  transition: all 0.5s linear 0s;
  pointer-events: none;
}
@media screen and (max-width: 900px) {
  .section__service .info-box:after {
    content: "";
    background: url(/images/type/service.png) no-repeat center center;
    width: 92vw;
    background-size: contain;
    position: absolute;
    right: -100vw;
    top: 50%;
    z-index: -1;
    transition: all 0.5s linear 0s;
    pointer-events: none;
  }
}
.section__service .info-box.-anime:after {
  right: 0;
  top: 28%;
}
@media screen and (max-width: 900px) {
  .section__service .info-box.-anime:after {
    right: 0;
    top: 50%;
  }
}
.section__service .info-box .item {
  width: 26.5vw;
  height: 35.46875vw;
  box-sizing: border-box;
  display: flex;
  justiy-content: center;
  align-items: center;
  /* background: $yblue_9; */
  border-radius: 1.0416666667vw;
}
@media screen and (max-width: 900px) {
  .section__service .info-box .item {
    border-radius: 2.6666666667vw;
    padding-bottom: 5vw;
  }
}
.section__service .info-box .item:hover {
  background: rgba(0, 169, 224, 0.05);
}
@media screen and (max-width: 900px) {
  .section__service .info-box .item {
    width: 100%;
    height: auto;
    margin-bottom: 10.6666666667vw;
  }
}
@media screen and (max-width: 900px) {
  .section__service .info-box .item:last-child {
    margin-bottom: 0;
  }
}
.section__service .info-box .item__inner a img {
  transform: scale(1.2);
}
@media screen and (max-width: 900px) {
  .section__service .info-box .item__inner a img {
    transform: none;
  }
}
.section__service .info-box .item__link:hover {
  opacity: 1;
}
.section__service .info-box .item .text-wrap {
  text-align: center;
}
.section__service .info-box .item .logo {
  width: 4.2708333333vw;
  margin: 0 auto;
}
@media screen and (max-width: 900px) {
  .section__service .info-box .item .logo {
    width: 21.8666666667vw;
  }
}
.section__service .info-box .item .logo.-crm {
  width: 7.2916666667vw;
}
@media screen and (max-width: 900px) {
  .section__service .info-box .item .logo.-crm {
    width: 37.3333333333vw;
  }
}
.section__service .info-box .item .ttl {
  font-weight: 700;
  color: #000;
  font-size: 1.25vw;
  margin-top: 0.625vw;
  letter-spacing: 0.05em;
}
@media screen and (max-width: 900px) {
  .section__service .info-box .item .ttl {
    font-size: 4.8vw;
    margin-top: 3.2vw;
    letter-spacing: 0.08em;
  }
}
.section__service .info-box .item .body {
  color: #000;
  font-size: 0.9375vw;
  line-height: 1.5;
  margin-top: 1.0416666667vw;
  letter-spacing: 0.05em;
  padding: 0 1.0416666667vw;
}
@media screen and (max-width: 900px) {
  .section__service .info-box .item .body {
    font-size: 3.7333333333vw;
    margin-top: 2.6666666667vw;
    letter-spacing: 0.08em;
    padding: 0;
  }
}
.section__service .info-box .item .button {
  display: none;
}
@media screen and (max-width: 900px) {
  .section__service .info-box .item .button {
    display: inline-block;
    width: 7.4666666667vw;
    margin-top: 2.6666666667vw;
  }
}

.bradcrumb,
.topicpath {
  /* section__innerと同じ */
  padding: 0 8.3333333333vw;
}
@media screen and (max-width: 900px) {
  .bradcrumb,
  .topicpath {
    display: block;
    padding: 0;
  }
}
.bradcrumb__inner,
.topicpath__inner {
  background: #F4F9FF;
  border-radius: 0.5208333333vw;
  overflow: hidden;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  margin: 0;
  padding: 0;
  height: 2.6041666667vw;
  max-width: none;
}
@media screen and (max-width: 900px) {
  .bradcrumb__inner,
  .topicpath__inner {
    padding: 0 4vw;
    background: transparent;
    height: 13.3333333333vw;
    border-radius: 2.6666666667vw;
  }
}
.bradcrumb__list,
.topicpath__list {
  position: static;
  transform: none;
  height: 2.6041666667vw;
  align-items: center;
  box-sizing: border-box;
}
@media screen and (max-width: 900px) {
  .bradcrumb__list,
  .topicpath__list {
    height: 13.3333333333vw;
  }
}
.bradcrumb__item,
.topicpath__item {
  line-height: 1;
  margin-right: 1.0416666667vw;
}
.bradcrumb__item.-home,
.topicpath__item.-home {
  background: #000;
  width: 2.8125vw;
  height: 100%;
  box-sizing: border-box;
}
@media screen and (max-width: 900px) {
  .bradcrumb__item.-home,
  .topicpath__item.-home {
    background: transparent;
    width: 5.3333333333vw;
    margin-top: 1vw;
  }
}
.bradcrumb__item.-home a,
.topicpath__item.-home a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.6041666667vw;
  box-sizing: border-box;
  width: 100%;
  position: relative;
}
@media screen and (max-width: 900px) {
  .bradcrumb__item.-home a,
  .topicpath__item.-home a {
    height: 11.7333333333vw;
    justify-content: flex-start;
  }
}
.bradcrumb__item.-home a img,
.topicpath__item.-home a img {
  width: 0.8333333333vw;
}
@media screen and (max-width: 900px) {
  .bradcrumb__item.-home a img,
  .topicpath__item.-home a img {
    width: 2.6666666667vw;
  }
}
.bradcrumb__item-text,
.topicpath__item-text {
  color: #ACB4C1;
  font-size: 0.625vw;
  line-height: 1;
  padding: 0;
  margin: 0;
  position: relative;
  display: block;
  padding-left: 2em;
}
@media screen and (max-width: 900px) {
  .bradcrumb__item-text,
  .topicpath__item-text {
    font-size: 2.4vw;
    color: #000;
  }
}
.bradcrumb__item-text:before,
.topicpath__item-text:before {
  content: "";
  border-top: 1px solid #ACB4C1;
  border-right: 1px solid #ACB4C1;
  width: 0.4166666667vw;
  height: 0.4166666667vw;
  position: absolute;
  top: 50%;
  left: 0;
  transform-origin: bottom right;
  transform: rotate(45deg) translateY(-50%);
}
@media screen and (max-width: 900px) {
  .bradcrumb__item-text:before,
  .topicpath__item-text:before {
    border-top: 1px solid #000;
    border-right: 1px solid #000;
    width: 1.6vw;
    height: 1.6vw;
  }
}
.bradcrumb__item-text a,
.topicpath__item-text a {
  opacity: 1 !important;
  color: #ACB4C1;
  padding-bottom: 0;
  padding-top: 0;
}
@media screen and (max-width: 900px) {
  .bradcrumb__item-text a,
  .topicpath__item-text a {
    color: #000;
  }
}
.bradcrumb__item-text a:hover,
.topicpath__item-text a:hover {
  opacity: 1;
}
.bradcrumb__item-text a.-bold,
.topicpath__item-text a.-bold {
  font-weight: 700;
}
.bradcrumb__item:first-child .topicpath__item-text,
.topicpath__item:first-child .topicpath__item-text {
  padding-left: 0;
}
.bradcrumb__item:first-child .topicpath__item-text:before,
.topicpath__item:first-child .topicpath__item-text:before {
  content: none;
}

.section.banner {
  background: #F4F7FB;
  box-sizing: border-box;
  width: 100%;
  margin-top: 1.5625vw;
  margin-bottom: 0;
}
.section.banner .bnr {
  background: none;
  flex-wrap: wrap;
  padding: 2.6041666667vw 0;
}
@media screen and (max-width: 900px) {
  .section.banner .bnr {
    padding: 5.3333333333vw 0;
  }
}
.section.banner .bnr__item {
  box-sizing: border-box;
  width: 23.6%;
  border-radius: 0.5208333333vw;
  overflow: hidden;
  transition: all 1s cubic-bezier(0.23, 1, 0.32, 1);
  opacity: 0;
  transform: translateY(20%);
}
@media screen and (max-width: 900px) {
  .section.banner .bnr__item {
    width: 47.82%;
    margin-top: 0;
    margin-bottom: 4vw;
    background: #fff;
    border-radius: 2.6666666667vw;
    overflow: hidden;
  }
}
.section.banner .bnr__item:nth-of-type(1) {
  transition: all 1s cubic-bezier(0.23, 1, 0.32, 1);
}
.section.banner .bnr__item:nth-of-type(2) {
  transition: all 1s cubic-bezier(0.23, 1, 0.32, 1) 0.1s;
}
.section.banner .bnr__item:nth-of-type(3) {
  transition: all 1s cubic-bezier(0.23, 1, 0.32, 1) 0.2s;
}
.section.banner .bnr__item:nth-of-type(4) {
  transition: all 1s cubic-bezier(0.23, 1, 0.32, 1) 0.3s;
}
.section.banner .bnr__item.-anime {
  transform: translateY(0);
  opacity: 1;
}
@media screen and (max-width: 900px) {
  .section.banner .bnr__item:nth-of-type(n+3) {
    margin-bottom: 0;
  }
}
.section.banner .bnr__item a {
  border: none;
}
.section.banner .bnr__item img {
  height: 5.2083333333vw;
  min-width: 100%;
  object-fit: cover;
  object-position: center center;
}
@media screen and (max-width: 900px) {
  .section.banner .bnr__item img {
    height: 15.2vw;
  }
}
.section.banner .innerelem {
  margin: 0;
  padding: 0;
}
.section.banner .text {
  background: #fff;
  box-sizing: border-box;
  padding: 0.78125vw 0.5208333333vw;
  text-align: center;
  font-size: 0.78125vw;
  font-weight: 700;
  line-height: 1.5;
  font-feature-settings: "palt";
  color: #8692A5;
  letter-spacing: 0.05em;
}
@media screen and (max-width: 900px) {
  .section.banner .text {
    font-size: 2.6666666667vw;
    padding: 4vw 0;
  }
}

.section.contact {
  background: #F4F7FB;
  width: 100%;
  margin-bottom: 0;
  position: relative;
}
.section.contact:before {
  content: "";
  position: absolute;
  top: 0;
  left: -300vw;
  z-index: 0;
  background: rgb(0, 169, 224);
  /* background: $yblue; */
  width: 200vw;
  height: 100%;
  transform: skewX(-25deg);
  transition: all 1s;
}
.section.contact .contact__wrap {
  background: url(/images/type/corporate.svg) no-repeat center bottom;
  background-size: 100vw auto;
  position: relative;
  z-index: 2;
}
.section.contact .contact-list {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 900px) {
  .section.contact .contact-list {
    flex-direction: column;
  }
}
.section.contact .contact-list__wrap {
  padding: 7.8125vw 0;
}
@media screen and (max-width: 900px) {
  .section.contact .contact-list__wrap {
    padding: 17.3333333333vw 0;
  }
}
.section.contact .contact-list__item {
  width: 49.375%;
  box-sizing: border-box;
  border-radius: 0.78125vw;
  overflow: hidden;
}
@media screen and (max-width: 900px) {
  .section.contact .contact-list__item {
    width: 100%;
    border-radius: 2.6666666667vw;
  }
}
@media screen and (max-width: 900px) {
  .section.contact .contact-list__item:first-child {
    margin-bottom: 3.7333333333vw;
  }
}
.section.contact .contact-list__link {
  display: block;
  background: #00a9e0;
  color: #fff;
  box-sizing: border-box;
  padding: 3.6458333333vw 0;
  text-align: center;
  transition: all 0.5s;
}
@media screen and (max-width: 900px) {
  .section.contact .contact-list__link {
    padding: 5.3333333333vw 0;
  }
}
.section.contact .contact-list__text.-en {
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  font-size: 2.8645833333vw;
}
@media screen and (max-width: 900px) {
  .section.contact .contact-list__text.-en {
    font-size: 8vw;
  }
}
.section.contact .contact-list__text.-ja {
  font-weight: 700;
  font-size: 1.0416666667vw;
  margin-top: 0.78125vw;
  letter-spacing: 0.05em;
}
@media screen and (max-width: 900px) {
  .section.contact .contact-list__text.-ja {
    font-size: 3.2vw;
    margin-top: 1.8666666667vw;
  }
}
.section.contact.-anime:before {
  left: -50vw;
  /* animation: skewanime .5s forwards; */
}
.section.contact.-anime .contact-list__link {
  background: #fff;
  color: #00a9e0;
}

@keyframes skewanime {
  100% {
    left: -150vw;
  }
}
.btn-more {
  width: 4.6875vw;
  position: absolute;
  left: 50%;
  top: 0;
  opacity: 0;
  z-index: 100;
}
@media screen and (max-width: 900px) {
  .btn-more {
    display: none;
  }
}

.sp-button {
  display: none;
}
@media screen and (max-width: 900px) {
  .sp-button {
    display: block;
    width: 7.4666666667vw;
  }
}

.stalker {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  opacity: 0;
  transition: all 0.8s ease-out 0s;
  pointer-events: none;
  /* mix-blend-mode: difference; */
  /*
  pointer-events: none;
  transform: translate3d(-10px, -10px, 0);
  */
}
@media screen and (max-width: 900px) {
  .stalker {
    display: none;
  }
}
.stalker.-show {
  opacity: 1;
}
.stalker__inner {
  width: 4.6875vw;
  height: 4.6875vw;
  background: url(/images/btn_more.svg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.-ja .indexNews__inner {
  width: 100%;
}
.-ja .indexNews__thumb img {
  width: 20.15625vw;
  height: 10.3125vw;
  object-position: center center;
  object-fit: cover;
}
@media screen and (max-width: 900px) {
  .-ja .indexNews__thumb img {
    width: 44vw;
    height: 22.9333333333vw;
  }
}

.indexNews__list {
  margin-top: 2.6041666667vw;
}
@media screen and (max-width: 900px) {
  .indexNews__list {
    margin-top: 4vw;
  }
}
.indexNews__list.-index {
  margin-top: 1.0416666667vw;
}
.indexNews__content {
  width: 100%;
}
.indexNews__item {
  width: 24.2%;
}
@media screen and (max-width: 900px) {
  .indexNews__item {
    width: 47.6%;
    margin-bottom: 5.8666666667vw;
  }
}
.indexNews__header--dt {
  display: flex;
  justify-content: flex-start;
}
.indexNews__cat {
  color: #ACB4C1;
  font-size: 0.625vw;
  font-weight: 700;
}
@media screen and (max-width: 900px) {
  .indexNews__cat {
    font-size: 2.9333333333vw;
  }
}
.indexNews__date {
  color: #ACB4C1;
  font-size: 0.625vw;
  font-weight: 700;
  margin-right: 0.78125vw;
  margin-top: 0;
}
@media screen and (max-width: 900px) {
  .indexNews__date {
    font-size: 2.9333333333vw;
    margin-right: 1.6vw;
  }
}
.indexNews__name {
  font-size: 0.9375vw;
  line-height: 1.5;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  line-clamp: 3;
  -webkit-line-clamp: 3;
}
@media screen and (max-width: 900px) {
  .indexNews__name {
    font-size: 3.2vw;
    letter-spacing: 0.05em;
    line-clamp: 5;
    -webkit-line-clamp: 5;
  }
}
.indexNews__button {
  margin-top: 2.6041666667vw;
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 900px) {
  .indexNews__button {
    margin-top: 0.8333333333vw;
  }
}
.indexNews__button--link {
  display: block;
  width: 8.3333333333vw;
  background: #EEF1F9;
  border-radius: 0.5208333333vw;
  text-align: center;
  display: flex;
  justify-content: center;
  padding: 0.78125vw 0;
  box-sizing: border-box;
}
@media screen and (max-width: 900px) {
  .indexNews__button--link {
    width: 7.4666666667vw;
    background: transparent;
    border-radius: 0;
    padding: 0;
  }
}
.indexNews__button--text {
  position: relative;
  font-size: 0.7291666667vw;
  font-weight: 700;
  box-sizing: border-box;
  margin-left: -1em;
}
.indexNews__button--text:after {
  content: "";
  border-top: 1px solid #000;
  border-right: 1px solid #000;
  width: 0.46875vw;
  height: 0.46875vw;
  position: absolute;
  top: 15%;
  right: -1em;
  transform: rotate(45deg);
}

.page .indexNews__list.news.press_release.-ja:before {
  content: "";
  width: 24.2%;
  order: 1;
}
.page .indexNews__list.news.press_release.-ja:after {
  content: "";
  width: 24.2%;
  order: 2;
}
@media screen and (max-width: 900px) {
  .page .indexNews__list.news.press_release.-ja {
    margin-bottom: 2.6666666667vw;
  }
}
.page .indexNews__list.news.press_release.-ja .indexNews__item {
  width: 24.2%;
}
@media screen and (max-width: 900px) {
  .page .indexNews__list.news.press_release.-ja .indexNews__item {
    width: 48%;
    margin-bottom: 5.8666666667vw;
  }
}

.header2 {
  position: fixed;
  width: 100%;
  z-index: 1000;
  left: 0;
  top: 0;
  transition: all 0.8s;
  /* spは別に記載 */
  /* sp menu button */
}
.header2.-hide {
  top: -20vw;
}
.header2.-white {
  background: #fff;
}
.header2__inner {
  padding: 1.5625vw;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media screen and (max-width: 900px) {
  .header2__inner {
    padding: 4vw;
  }
}
.header2__logo {
  width: 5.625vw;
}
@media screen and (max-width: 900px) {
  .header2__logo {
    width: 14.1333333333vw;
  }
}
.header2 .right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
@media screen and (max-width: 900px) {
  .header2__nav {
    display: none;
  }
}
.header2 .menu-list {
  display: flex;
}
.header2 .menu-list__link {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 0.8854166667vw;
  color: #000;
  margin-left: 2.0833333333vw;
  position: relative;
}
.header2 .menu-list__link.-opened {
  color: #00a9e0;
}
.header2 .menu-list__link:hover {
  color: #00a9e0;
}
.header2 .submenu-list {
  display: none;
  width: 11.9791666667vw;
  box-sizing: border-box;
  padding: 1.0416666667vw 1.5625vw;
  background: #00a9e0;
  position: absolute;
  top: 2vw;
  left: 1vw;
  border-radius: 0.78125vw;
  overflow: hidden;
  z-index: 1001;
}
.header2 .submenu-list.-show {
  display: block;
}
.header2 .submenu-list__link {
  display: block;
  padding: 0.7291666667vw 0;
  color: #fff;
  font-weight: bold;
  font-size: 0.7291666667vw;
  font-weight: 700;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  letter-spacing: 0.05em;
}
.header2 .submenu-list__link:after {
  content: "";
  border-top: 1px solid #fff;
  border-right: 1px solid #fff;
  width: 0.4166666667vw;
  height: 0.4166666667vw;
  position: absolute;
  top: 40%;
  right: 0;
  transform: rotate(45deg);
}
.header2 .submenu-list__link.-ext:after {
  content: "";
  width: 0.46875vw;
  height: 0.46875vw;
  border: none;
  background-image: url(/images/icon/ico_extlink.svg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  position: absolute;
  top: 40%;
  right: 0;
  transform: rotate(45deg);
}
.header2 .submenu-list__link:hover:after {
  animation: submenu_move 0.4s ease-out 0s forwards;
}
.header2__lang .lang-list {
  display: flex;
  margin-left: 2.0833333333vw;
}
@media screen and (max-width: 900px) {
  .header2__lang .lang-list {
    margin-left: 4vw;
  }
}
.header2 .lang-list {
  display: flex;
  border-radius: 0.2604166667vw;
  overflow: hidden;
  background: #ACB4C1;
}
@media screen and (max-width: 900px) {
  .header2 .lang-list {
    border-radius: 1.3333333333vw;
    margin-right: 4vw;
  }
}
.header2 .lang-list__link {
  display: block;
  box-sizing: border-box;
  padding: 0.5208333333vw 0.8333333333vw;
  color: #fff;
  opacity: 0.5;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 0.8333333333vw;
}
@media screen and (max-width: 900px) {
  .header2 .lang-list__link {
    padding: 1.6vw 2.6666666667vw;
    font-size: 2.9333333333vw;
  }
}
.header2 .lang-list__link:hover {
  background: #CDD1DC;
}
.header2 .lang-list__link.-select {
  opacity: 1;
  background: #00a9e0;
}
.header2 .lang-list__link.-select:hover {
  background: #00a9e0;
}
.header2 .menu-btn {
  display: none;
}
@media screen and (max-width: 900px) {
  .header2 .menu-btn {
    display: block;
    width: 4.2666666667vw;
  }
}

.sp-menu-bg {
  display: none;
}
@media screen and (max-width: 900px) {
  .sp-menu-bg {
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    background: #00a9e0;
    width: 100%;
    height: 100vh;
    border-radius: 20vw;
    z-index: 1000;
    /* transform: scale(0.1, 0.05); */
    transform: scale(0, 0);
    transform-origin: 97vw 2vw;
    pointer-events: none;
  }
}
@media screen and (max-width: 900px) {
  .sp-menu-bg.-opened {
    animation: sp_menu_bg_open 1s cubic-bezier(0.23, 1, 0.32, 1) 0s forwards;
  }
}
@media screen and (max-width: 900px) {
  .sp-menu-bg.-remain-open {
    border-radius: 0;
    transform: scale(1, 1);
    transform-origin: 97vw 2vw;
  }
}
@media screen and (max-width: 900px) {
  .sp-menu-bg.-closed {
    animation: sp_menu_bg_open 1s cubic-bezier(0.23, 1, 0.32, 1) 0s reverse forwards;
  }
}

.sp-menu {
  display: none;
}
@media screen and (max-width: 900px) {
  .sp-menu {
    display: block;
    position: fixed;
    left: 0;
    top: -150vh;
    width: 100%;
    min-height: 100vh;
    height: 100%;
    overflow-y: scroll;
    box-sizing: border-box;
    z-index: 1001;
    opacity: 0;
    /* transition: all 1s cubic-bezier(0.23,1,0.32,1) 0s */
  }
}
@media screen and (max-width: 900px) {
  .sp-menu.-opened {
    animation: sp_menu_open 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0.5s forwards;
    /*
    right: 0;
    top: 0;
    */
  }
}
@media screen and (max-width: 900px) {
  .sp-menu.-remain-open {
    top: 0;
    opacity: 1;
  }
}
@media screen and (max-width: 900px) {
  .sp-menu.-closed {
    animation: sp_menu_open 1s cubic-bezier(0.23, 1, 0.32, 1) 0s reverse forwards;
  }
}
.sp-menu .inner {
  padding: 4vw;
  min-height: 100vh;
  box-sizing: border-box;
  background: #00a9e0;
  color: #fff;
  overflow-y: scroll;
}
.sp-menu .inner .menu-btn {
  display: flex;
  justify-content: flex-end;
}
.sp-menu .menu {
  padding: 8vw 0 0;
}
.sp-menu .menu-list {
  display: block;
}
.sp-menu .menu-list__item {
  margin-left: 0;
}
.sp-menu .menu-list__link {
  display: block;
  padding: 3.2vw 0;
  margin-left: 0;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 6.4vw;
  color: #fff;
  position: relative;
}
.sp-menu .menu-list__link:hover {
  opacity: 1;
}
.sp-menu .menu-list__link:after {
  content: "";
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  border-top: none;
  border-left: none;
  width: 2.6666666667vw;
  height: 2.6666666667vw;
  position: absolute;
  right: 2.6666666667vw;
  top: 35%;
  transform: rotate(45deg);
}
.sp-menu .menu-list__link.-opened:after {
  content: "";
  border-top: 2px solid #fff;
  border-left: 2px solid #fff;
  border-right: none;
  border-bottom: none;
  width: 2.6666666667vw;
  height: 2.6666666667vw;
  position: absolute;
  right: 2.6666666667vw;
  top: 45%;
  transform: rotate(45deg);
}
.sp-menu .submenu-list {
  display: none;
  padding: 2.6666666667vw 0;
}
.sp-menu .submenu-list__item {
  margin-bottom: 6.6666666667vw;
}
.sp-menu .submenu-list__link {
  color: #fff;
  font-weight: 3.7333333333vw;
  font-weight: 700;
  display: inline-block;
  position: relative;
  padding-right: 4vw;
  letter-spacing: 0.05em;
}
@media screen and (max-width: 900px) {
  .sp-menu .submenu-list__link:hover {
    opacity: 1;
  }
}
.sp-menu .submenu-list__link:after {
  content: "";
  width: 1.8666666667vw;
  height: 1.8666666667vw;
  border-top: 1px solid #fff;
  border-right: 1px solid #fff;
  position: absolute;
  right: 0;
  top: 30%;
  transform: rotate(45deg);
}
.sp-menu .submenu-list__link.-ext:after {
  content: "";
  width: 2.1333333333vw;
  height: 2.1333333333vw;
  border: none;
  background-image: url(/images/icon/ico_extlink.svg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  position: absolute;
  right: 0;
  top: 25%;
  transform: rotate(45deg);
}
.sp-menu .sns {
  margin-top: 8vw;
}
.sp-menu .sns-list {
  display: flex;
  align-items: center;
}
.sp-menu .sns-list__item {
  width: 9.8666666667vw;
  margin-right: 4vw;
}
.sp-menu .sns-list__item:first-of-type {
  width: 8vw;
}
.sp-menu .info {
  margin-top: 6.6666666667vw;
  padding-bottom: 21.3333333333vw;
}
.sp-menu .info-list {
  display: flex;
  flex-wrap: wrap;
}
.sp-menu .info-list__item {
  margin-right: 4vw;
  margin-bottom: 5.3333333333vw;
}
.sp-menu .info-list__item:last-child {
  margin-right: 0;
}
.sp-menu .info-list__link {
  color: #fff;
}

@keyframes sp_menu_open {
  0% {
    opacity: 0;
    top: 0;
  }
  100% {
    top: 0;
    opacity: 1;
  }
}
@keyframes sp_menu_close {}
@keyframes sp_menu_bg_open {
  0% {
    border-radius: 20vw;
    transform: scale(0, 0);
    transform-origin: 97vw 2vw;
  }
  20% {
    border-radius: 20vw;
  }
  100% {
    border-radius: 0;
    transform: scale(1, 1);
    transform-origin: 97vw 2vw;
  }
}
@keyframes submenu_move {
  0% {
    right: 0;
  }
  50% {
    right: -0.25vw;
  }
  100% {
    right: 0;
  }
}
.footer2 {
  background: #000;
  color: #fff;
}
.footer2__inner {
  padding: 5.2083333333vw 8.3333333333vw;
}
@media screen and (max-width: 900px) {
  .footer2__inner {
    padding: 13.3333333333vw 4vw;
  }
}
.footer2__upper {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 900px) {
  .footer2__upper {
    flex-direction: column;
  }
}
.footer2__upper .left {
  width: 10.625vw;
  box-sizing: border-box;
}
@media screen and (max-width: 900px) {
  .footer2__upper .left {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.footer2__upper .left .btn-to-top {
  display: none;
}
@media screen and (max-width: 900px) {
  .footer2__upper .left .btn-to-top {
    display: block;
  }
}
.footer2__upper .right {
  width: 100%;
}
.footer2__upper .logo {
  width: 10.6770833333vw;
  display: flex;
  justify-content: stretch;
}
@media screen and (max-width: 900px) {
  .footer2__upper .logo {
    width: 17.3333333333vw;
  }
}
.footer2__upper .logo__text {
  font-weight: 700;
  font-size: 1.5885416667vw;
  font-family: "Montserrat", sans-serif;
  margin-top: 1.9791666667vw;
}
@media screen and (max-width: 900px) {
  .footer2__upper .logo__text {
    display: none;
  }
}
.footer2__lower {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 1.5625vw;
}
@media screen and (max-width: 900px) {
  .footer2__lower {
    flex-direction: column;
    align-items: flex-start;
  }
}
.footer2__lower .left {
  width: 50%;
}
@media screen and (max-width: 900px) {
  .footer2__lower .left {
    width: 100%;
  }
}
@media screen and (max-width: 900px) {
  .footer2__lower .right {
    width: 100%;
  }
}
@media screen and (max-width: 900px) {
  .footer2__lower .sns {
    margin-top: 5.3333333333vw;
  }
}
.footer2__lower .sns-list {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}
.footer2__lower .sns-list__item {
  box-sizing: border-box;
  width: 1.5625vw;
  margin-right: 0.625vw;
}
@media screen and (max-width: 900px) {
  .footer2__lower .sns-list__item {
    width: 9.8666666667vw;
    margin-right: 4vw;
  }
}
.footer2__lower .sns-list__item:first-of-type {
  width: 1.3020833333vw;
}
@media screen and (max-width: 900px) {
  .footer2__lower .sns-list__item:first-of-type {
    width: 8.5333333333vw;
  }
}
@media screen and (max-width: 900px) {
  .footer2__lower .info {
    margin-top: 4vw;
    padding-bottom: 8vw;
  }
}
.footer2__lower .info-list {
  display: flex;
  margin-top: 0.78125vw;
}
@media screen and (max-width: 900px) {
  .footer2__lower .info-list {
    margin-top: 0;
    flex-wrap: wrap;
  }
}
.footer2__lower .info-list__item {
  margin-right: 1.0416666667vw;
}
@media screen and (max-width: 900px) {
  .footer2__lower .info-list__item {
    margin-right: 4vw;
    margin-bottom: 5.3333333333vw;
  }
  .footer2__lower .info-list__item:last-child {
    margin-right: 0;
  }
}
.footer2__lower .info-list__link {
  color: #fff;
  font-size: 0.7291666667vw;
  font-weight: 700;
}
@media screen and (max-width: 900px) {
  .footer2__lower .info-list__link {
    font-size: 3.2vw;
  }
}
.footer2__lower .copyright {
  color: #8692A5;
  font-size: 0.625vw;
  margin-top: 0.78125vw;
}
.footer2__lower .sign {
  width: 18.6458333333vw;
}
@media screen and (max-width: 900px) {
  .footer2__lower .sign {
    width: 100%;
    margin-top: 2.6666666667vw;
  }
}
@media screen and (max-width: 900px) {
  .footer2__lower .sign__image {
    opacity: 0.4;
    width: 100%;
  }
}
@media screen and (max-width: 900px) {
  .footer2__lower .logo {
    display: flex;
    align-items: center;
    margin-top: 10.6666666667vw;
  }
}
@media screen and (max-width: 900px) {
  .footer2__lower .logo__image {
    margin-right: 2.6666666667vw;
    width: 17.3333333333vw;
  }
}
@media screen and (max-width: 900px) {
  .footer2__lower .logo i__text {
    font-weight: 700;
    font-family: "Montserrat", sans-serif;
    font-size: 2.4vw;
    color: #fff;
  }
}
@media screen and (max-width: 900px) {
  .footer2__lower .copyright {
    font-size: 2.6666666667vw;
    color: #ACB4C1;
    marin-top: 2.6666666667vw;
  }
}
.footer2__nav .menu-list {
  display: flex;
  justify-content: flex-end;
}
@media screen and (max-width: 900px) {
  .footer2__nav .menu-list {
    justify-content: flex-start;
    flex-direction: column;
    margin-top: 5.3333333333vw;
  }
}
.footer2__nav .menu-list__item {
  margin-left: 5.2083333333vw;
}
@media screen and (max-width: 900px) {
  .footer2__nav .menu-list__item {
    margin-left: 0;
  }
}
.footer2__nav .menu-list__item:first-child {
  margin-left: 0;
}
.footer2__nav .menu-list__link {
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 1.25vw;
  letter-spacing: 0.05em;
}
.footer2__nav .menu-list__link:active, .footer2__nav .menu-list__link:hover {
  opacity: 1;
}
@media screen and (max-width: 900px) {
  .footer2__nav .menu-list__link {
    display: block;
    padding: 2.6666666667vw 0;
    margin-left: 0;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 6.4vw;
    color: #fff;
    position: relative;
  }
  .footer2__nav .menu-list__link:after {
    content: "";
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;
    border-top: none;
    border-left: none;
    width: 2.6666666667vw;
    height: 2.6666666667vw;
    position: absolute;
    right: 2.6666666667vw;
    top: 35%;
    transform: rotate(45deg);
  }
  .footer2__nav .menu-list__link.-opened:after {
    content: "";
    border-top: 2px solid #fff;
    border-left: 2px solid #fff;
    border-right: none;
    border-bottom: none;
    width: 2.6666666667vw;
    height: 2.6666666667vw;
    position: absolute;
    right: 2.6666666667vw;
    top: 45%;
    transform: rotate(45deg);
  }
}
.footer2__nav .submenu-list {
  margin-top: 1.3020833333vw;
}
@media screen and (max-width: 900px) {
  .footer2__nav .submenu-list {
    display: none;
    padding: 2.6666666667vw 0 5.3333333333vw;
  }
}
.footer2__nav .submenu-list__item {
  margin-bottom: 0.9375vw;
}
@media screen and (max-width: 900px) {
  .footer2__nav .submenu-list__item {
    margin-bottom: 6.6666666667vw;
  }
}
.footer2__nav .submenu-list__item:last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 900px) {
  .footer2__nav .submenu-list__item:last-child {
    margin-bottom: 6.6666666667vw;
  }
}
.footer2__nav .submenu-list__link {
  display: block;
  font-size: 0.78125vw;
  position: relative;
  color: #fff;
  letter-spacing: 0.05em;
}
@media screen and (max-width: 900px) {
  .footer2__nav .submenu-list__link {
    color: #fff;
    font-size: 3.7333333333vw;
    font-weight: 700;
    display: inline-block;
    position: relative;
    padding-right: 4vw;
  }
}
.footer2__nav .submenu-list__link:active, .footer2__nav .submenu-list__link:hover {
  opacity: 1;
}
.footer2__nav .submenu-list__link:after {
  content: "";
  border-top: 1px solid #fff;
  border-right: 1px solid #fff;
  width: 0.3125vw;
  height: 0.3125vw;
  position: absolute;
  top: 50%;
  right: -1em;
  transform: rotate(45deg) translateY(-50%);
}
@media screen and (max-width: 900px) {
  .footer2__nav .submenu-list__link:after {
    content: "";
    width: 1.8666666667vw;
    height: 1.8666666667vw;
    border-top: 1px solid #fff;
    border-right: 1px solid #fff;
    position: absolute;
    right: 0;
    top: 30%;
    transform: rotate(45deg);
  }
}
.footer2__nav .submenu-list__link.-ext:after {
  content: "";
  width: 0.3125vw;
  height: 0.3125vw;
  border: none;
  background-image: url(/images/icon/ico_extlink.svg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  position: absolute;
  top: 45%;
  right: -1em;
  transform: rotate(45deg) translateY(-50%);
}
@media screen and (max-width: 900px) {
  .footer2__nav .submenu-list__link.-ext:after {
    content: "";
    width: 1.8666666667vw;
    height: 1.8666666667vw;
    position: absolute;
    right: 0;
    top: 25%;
    transform: rotate(45deg);
  }
}
.footer2__nav .submenu-list__link:hover:after {
  animation: submenu_footer_move 0.4s ease-out 0s forwards;
}

@keyframes submenu_footer_move {
  0% {
    right: -1em;
  }
  50% {
    right: -1.2em;
  }
  100% {
    right: -1em;
  }
}
.home main .contents {
  padding-top: 0;
}
.home .page {
  padding: 0;
}
.home .kv__outer {
  width: 100%;
  border-radius: 0;
  margin: 0;
  height: 100vh;
}
@media screen and (max-width: 900px) {
  .home .kv__outer {
    height: auto;
  }
}
@media screen and (max-width: 900px) {
  .home .video__wrap {
    overflow: hidden;
  }
}
@media screen and (max-width: 900px) {
  .home .video__main {
    margin-left: -150vw;
  }
}
.home .mv__copy {
  position: absolute;
  left: 13.28125vw;
  top: 7.5520833333vw;
  z-index: 10;
  overflow: hidden;
}
@media screen and (max-width: 900px) {
  .home .mv__copy {
    left: 8vw;
    top: 32vw;
  }
}
.home .mv__copy2 {
  position: absolute;
  left: 36.71875vw;
  top: 20.5208333333vw;
  z-index: 10;
  overflow: hidden;
}
@media screen and (max-width: 900px) {
  .home .mv__copy2 {
    left: 32.2666666667vw;
    top: 66.6666666667vw;
  }
}
.home .mv__copy3 {
  position: absolute;
  left: 35.15625vw;
  top: 25.7291666667vw;
  z-index: 10;
  overflow: hidden;
}
@media screen and (max-width: 900px) {
  .home .mv__copy3 {
    left: 1.8666666667vw;
    top: 98.6666666667vw;
  }
}
.home .mv__line {
  margin-bottom: 3.0208333333vw;
}
@media screen and (max-width: 900px) {
  .home .mv__line {
    margin-bottom: 3.7333333333vw;
  }
}
.home .mv__ttl {
  opacity: 0;
  overflow: hidden;
}
.home .mv__ttl.-line1 {
  width: 36.40625vw;
}
@media screen and (max-width: 900px) {
  .home .mv__ttl.-line1 {
    width: 63.7333333333vw;
    display: block;
    font-size: 6px;
    transform: scale(0.8);
    transform-origin: 0 0;
  }
}
.home .mv__ttl.-line2 {
  width: 25vw;
}
@media screen and (max-width: 900px) {
  .home .mv__ttl.-line2 {
    width: 43.7333333333vw;
    display: block;
    font-size: 6px;
    transform: scale(0.8);
    transform-origin: 0 0;
  }
}
.home .mv__ttl.-line3 {
  width: 43.4895833333vw;
}
@media screen and (max-width: 900px) {
  .home .mv__ttl.-line3 {
    width: 76.8vw;
    display: block;
    font-size: 6px;
    transform: scale(0.8);
    transform-origin: 0 0;
  }
}
.home .mv__ttl.-anime {
  animation: mv_ttl 1s cubic-bezier(0.23, 1, 0.32, 1) 0s forwards;
}
.home .mv__ttl.-anime.-line2 {
  animation: mv_ttl 1s cubic-bezier(0.23, 1, 0.32, 1) 0.5s forwards;
}
.home .mv__ttl.-anime.-line3 {
  animation: mv_ttl 1s cubic-bezier(0.23, 1, 0.32, 1) 1s forwards;
}
.home .mv__text {
  box-sizing: border-box;
  margin-top: 1.3541666667vw;
  width: 19.4270833333vw;
  opacity: 0;
  overflow: hidden;
  font-family: "Montserrat", sans-serif;
  font-size: 0.5208333333vw;
  font-weight: 500;
  line-height: 1.25;
}
@media screen and (max-width: 900px) {
  .home .mv__text {
    font-size: 2.4vw;
    transform: scale(0.8);
    margin-top: 1.3333333333vw;
    width: 80vw;
  }
}
.home .mv__text.-anime {
  animation: mv_text 1s cubic-bezier(0.23, 1, 0.32, 1) 0s forwards;
}
.home .mv__text.-anime.-line1 {
  animation: mv_text 1s cubic-bezier(0.23, 1, 0.32, 1) 0.2s forwards;
}
.home .mv__text.-anime.-line2 {
  animation: mv_text 1s cubic-bezier(0.23, 1, 0.32, 1) 0.7s forwards;
}
.home .mv__text.-anime.-line3 {
  animation: mv_text 1s cubic-bezier(0.23, 1, 0.32, 1) 1.2s forwards;
}
.home .mv__sign {
  width: 35.46875vw;
  opacity: 0;
  overflow: hidden;
}
@media screen and (max-width: 900px) {
  .home .mv__sign {
    width: 59.4666666667vw;
  }
}
.home .mv__sign.-anime {
  animation: mv_ttl 1s cubic-bezier(0.23, 1, 0.32, 1) 1.5s forwards;
}
.home .mv__message {
  width: 51.5625vw;
  opacity: 0;
  overflow: hidden;
}
@media screen and (max-width: 900px) {
  .home .mv__message {
    width: 96.2666666667vw;
  }
}
.home .mv__message.-anime {
  animation: mv_text 1s cubic-bezier(0.23, 1, 0.32, 1) 2s forwards;
}
.home .section__mission .section__inner {
  position: relative;
  /* height: vw(917, $pc_w); */
  /* height: vw(960, $pc_w); */
  height: 52.0833333333vw;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
}
@media screen and (max-width: 900px) {
  .home .section__mission .section__inner {
    height: 146.6666666667vw;
  }
}
.home__mission {
  /* padding-top: vw(100, $pc_w); */
}
@media screen and (max-width: 900px) {
  .home__mission {
    padding-top: 0;
  }
}
.home .mission__inner {
  overflow: hidden;
}
.home .mission__text {
  text-align: center;
}
@media screen and (max-width: 900px) {
  .home .mission__text {
    padding-top: 15vw;
  }
}
@media screen and (max-width: 900px) {
  .home .mission__text .sp-button {
    margin: 5vw auto 0;
  }
}
.home .mission__title {
  color: #00a9e0;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  font-size: 1.3020833333vw;
}
@media screen and (max-width: 900px) {
  .home .mission__title {
    font-size: 6.6666666667vw;
  }
}
.home .mission__ttl {
  font-weight: 700;
  font-size: 3.125vw;
  margin-top: 1.5625vw;
  letter-spacing: 0.05em;
}
@media screen and (max-width: 900px) {
  .home .mission__ttl {
    font-size: 6.6666666667vw;
    margin-top: 4.8vw;
    line-height: 1.4;
  }
}
.home .mission__body {
  font-weight: 700;
  margin-top: 1.5625vw;
  font-size: 1.1458333333vw;
  line-height: 2;
  letter-spacing: 0.05em;
}
@media screen and (max-width: 900px) {
  .home .mission__body {
    font-weight: 500;
    font-size: 3.7333333333vw;
    margin-top: 4.8vw;
    line-height: 1.6;
  }
}
.home .mission .sp-button {
  display: none;
}
@media screen and (max-width: 900px) {
  .home .mission .sp-button {
    display: block;
    margin-top: 5.3333333333vw auto 0;
    width: 7.4666666667vw;
  }
}
.home .mission__image {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  z-index: -1;
  /* transition: all 0.8s ease-out 0s forwards; */
  transition: all 0.8s ease-out 0s;
}
.home .mission__image.-img0 {
  width: 17.6041666667vw;
  opacity: 0;
  transform: translate3d(-50%, -50%, 0);
}
.home .mission__image.-img0.-anime {
  opacity: 1;
}
@media screen and (max-width: 900px) {
  .home .mission__image.-img0 {
    width: 21.3333333333vw;
  }
}
.home .mission__image.-img1 {
  width: 16.71875vw;
  opacity: 0;
  /* transform: translate3d(-50%, -175%, 0); */
  transform: translate3d(-50%, -260%, 0);
}
.home .mission__image.-img1.-anime {
  opacity: 1;
  transform: translate3d(-50%, -175%, 0);
}
@media screen and (max-width: 900px) {
  .home .mission__image.-img1.-anime {
    transform: translate3d(-50%, -267%, 0);
  }
}
@media screen and (max-width: 900px) {
  .home .mission__image.-img1 {
    transform: translate3d(-50%, -345%, 0);
    width: 21.3333333333vw;
  }
}
.home .mission__image.-img2 {
  width: 16.71875vw;
  opacity: 0;
  /* transform: translate3d(50%, -125%, 0); */
  transform: translate3d(141%, -195%, 0);
}
.home .mission__image.-img2.-anime {
  opacity: 1;
  transform: translate3d(50%, -125%, 0);
}
@media screen and (max-width: 900px) {
  .home .mission__image.-img2.-anime {
    transform: translate3d(120%, -250%, 0);
  }
}
@media screen and (max-width: 900px) {
  .home .mission__image.-img2 {
    width: 21.3333333333vw;
    transform: translate3d(180%, -320%, 0);
  }
}
.home .mission__image.-img3 {
  width: 19.375vw;
  opacity: 0;
  /* transform: translate3d(125%, -50%, 0); */
  transform: translate3d(250%, -50%, 0);
}
.home .mission__image.-img3.-anime {
  opacity: 1;
  transform: translate3d(125%, -50%, 0);
}
@media screen and (max-width: 900px) {
  .home .mission__image.-img3.-anime {
    transform: translate3d(190%, -50%, 0);
  }
}
@media screen and (max-width: 900px) {
  .home .mission__image.-img3 {
    width: 21.3333333333vw;
    transform: translate3d(250%, -50%, 0);
  }
}
.home .mission__image.-img4 {
  width: 16.71875vw;
  opacity: 0;
  /* transform: translate3d(50%, 25%, 0); */
  transform: translate3d(140%, 90%, 0);
}
.home .mission__image.-img4.-anime {
  opacity: 1;
  transform: translate3d(50%, 25%, 0);
}
@media screen and (max-width: 900px) {
  .home .mission__image.-img4.-anime {
    transform: translate3d(125%, 170%, 0);
  }
}
@media screen and (max-width: 900px) {
  .home .mission__image.-img4 {
    width: 21.3333333333vw;
    transform: translate3d(205%, 250%, 0);
  }
}
.home .mission__image.-img5 {
  width: 16.71875vw;
  opacity: 0;
  /* transform: translate3d(-50%, 100%, 0); */
  transform: translate3d(-50%, 223%, 0);
}
.home .mission__image.-img5.-anime {
  opacity: 1;
  transform: translate3d(-50%, 100%, 0);
}
@media screen and (max-width: 900px) {
  .home .mission__image.-img5.-anime {
    transform: translate3d(-50%, 320%, 0);
  }
}
@media screen and (max-width: 900px) {
  .home .mission__image.-img5 {
    width: 21.3333333333vw;
    transform: translate3d(-50%, 370%, 0);
  }
}
.home .mission__image.-img6 {
  width: 16.7708333333vw;
  opacity: 0;
  /* transform: translate3d(-150%, 25%, 0); */
  transform: translate3d(-232%, 105%, 0);
}
.home .mission__image.-img6.-anime {
  opacity: 1;
  transform: translate3d(-150%, 25%, 0);
}
@media screen and (max-width: 900px) {
  .home .mission__image.-img6.-anime {
    transform: translate3d(-220%, 225%, 0);
  }
}
@media screen and (max-width: 900px) {
  .home .mission__image.-img6 {
    width: 21.3333333333vw;
    transform: translate3d(-300%, 305%, 0);
  }
}
.home .mission__image.-img7 {
  width: 16.71875vw;
  opacity: 0;
  /* transform: translate3d(-250%, -50%, 0); */
  transform: translate3d(-360%, -50%, 0);
}
.home .mission__image.-img7.-anime {
  opacity: 1;
  transform: translate3d(-250%, -50%, 0);
}
@media screen and (max-width: 900px) {
  .home .mission__image.-img7.-anime {
    transform: translate3d(-300%, -50%, 0);
  }
}
@media screen and (max-width: 900px) {
  .home .mission__image.-img7 {
    width: 21.3333333333vw;
    transform: translate3d(-400%, -50%, 0);
  }
}
.home .mission__image.-img8 {
  width: 16.71875vw;
  opacity: 0;
  /* transform: translate3d(-150%, -125%, 0); */
  transform: translate3d(-232%, -184%, 0);
}
.home .mission__image.-img8.-anime {
  opacity: 1;
  transform: translate3d(-150%, -125%, 0);
}
@media screen and (max-width: 900px) {
  .home .mission__image.-img8.-anime {
    transform: translate3d(-225%, -225%, 0);
  }
}
@media screen and (max-width: 900px) {
  .home .mission__image.-img8 {
    width: 21.3333333333vw;
    transform: translate3d(-305%, -305%, 0);
  }
}
.home .mission__image.-imgp {
  width: 16.71875vw;
  opacity: 0;
  /* transform: translate3d(-150%, -125%, 0); */
  transform: translate3d(300%, -300%, 0);
}
.home .mission__image.-imgp.-anime {
  opacity: 1;
  transform: translate3d(200%, -225%, 0);
}
.home__service .section-ttl__en {
  margin: 0 auto;
}
.home__news .section-ttl__en {
  margin: 0 auto;
}
.home .recruit__image {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  border-radius: 0.78125vw;
  overflow: hidden;
}
@media screen and (max-width: 900px) {
  .home .recruit__image {
    border-radius: 4vw;
  }
}
.home .recruit__text {
  position: absolute;
  left: 50%;
  top: 50%;
  color: #fff;
  text-align: center;
  transform: translateX(-50%) translateY(-50%);
}
.home .recruit__ttl {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 4.6875vw;
}
@media screen and (max-width: 900px) {
  .home .recruit__ttl {
    font-size: 8vw;
  }
}
.home .recruit__body {
  margin-top: 0.78125vw;
  font-size: 1.25vw;
  font-weight: 700;
}
@media screen and (max-width: 900px) {
  .home .recruit__body {
    margin-top: 2.6666666667vw;
    font-size: 4.8vw;
    letter-spacing: 0.05em;
    line-height: 1.5;
    white-space: nowrap;
  }
}
.home .recruit__button {
  border: 1px solid #fff;
  border-radius: 0.5208333333vw;
  display: flex;
  justify-content: center;
  width: 7.65625vw;
  padding: 0.78125vw 0;
  box-sizing: border-box;
  margin: 1.3020833333vw auto 0;
}
@media screen and (max-width: 900px) {
  .home .recruit__button {
    margin: 5.3333333333vw auto 0;
    width: 33.8666666667vw;
    padding: 2.6666666667vw 0;
    border: none;
    background: #00a9e0;
    border-radius: 2.6666666667vw;
  }
}
.home .recruit__button--text {
  position: relative;
  font-size: 0.7291666667vw;
  font-weight: 700;
  margin-left: -1em;
}
@media screen and (max-width: 900px) {
  .home .recruit__button--text {
    font-size: 3.7333333333vw;
  }
}
.home .recruit__button--text:after {
  content: "";
  border-top: 1px solid #fff;
  border-right: 1px solid #fff;
  width: 0.46875vw;
  height: 0.46875vw;
  position: absolute;
  top: 32%;
  right: -1em;
  transform: rotate(45deg) translateY(-50%);
}
@media screen and (max-width: 900px) {
  .home .recruit__button--text:after {
    top: 32%;
    width: 2.4vw;
    height: 2.4vw;
  }
}
.home .office {
  margin-top: 1.5625vw;
}
@media screen and (max-width: 900px) {
  .home .office {
    margin-top: 5.3333333333vw;
  }
}
.home .office .office-list {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 900px) {
  .home .office .office-list {
    flex-direction: column;
  }
}
.home .office .office-list__item {
  width: 32.125%;
}
@media screen and (max-width: 900px) {
  .home .office .office-list__item {
    width: 100%;
    margin-bottom: 5.3333333333vw;
  }
}
.home .office .office-list__image {
  overflow: hidden;
  border-radius: 0.78125vw;
}
@media screen and (max-width: 900px) {
  .home .office .office-list__image {
    border-radius: 4vw;
  }
}
.home .office .office-list__image--image {
  height: 13.0208333333vw;
  object-fit: cover;
}
@media screen and (max-width: 900px) {
  .home .office .office-list__image--image {
    height: 32vw;
  }
}
.home .office .office-list__info {
  margin-top: 1.0416666667vw;
}
@media screen and (max-width: 900px) {
  .home .office .office-list__info {
    margin-top: 2.6666666667vw;
  }
}
.home .office .office-list__title {
  display: flex;
  align-items: center;
}
.home .office .office-list__icon {
  width: 0.8854166667vw;
  margin-right: 0.3645833333vw;
  box-sizing: border-box;
}
@media screen and (max-width: 900px) {
  .home .office .office-list__icon {
    width: 3.7333333333vw;
    margin-right: 1.3333333333vw;
  }
}
.home .office .office-list__ttl {
  font-size: 1.3541666667vw;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
}
@media screen and (max-width: 900px) {
  .home .office .office-list__ttl {
    font-size: 4.2666666667vw;
  }
}
.home .office .office-list__ext {
  display: none;
}
@media screen and (max-width: 900px) {
  .home .office .office-list__ext {
    display: block;
    margin-left: 1.3333333333vw;
    width: 2.6666666667vw;
  }
}
.home .office .office-list__address {
  margin-top: 0.5208333333vw;
  font-size: 0.625vw;
}
@media screen and (max-width: 900px) {
  .home .office .office-list__address {
    margin-top: 2.6666666667vw;
    font-size: 3.2vw;
    line-height: 1.4;
  }
}
.home .scroll-dot {
  position: absolute;
  left: 50%;
  /* top: 49.5vw; */
  top: 43vw;
  transform: translateX(-50%);
  z-index: 200;
}
@media screen and (max-width: 900px) {
  .home .scroll-dot {
    top: 140vw;
  }
}
.home .scroll-dot__wrap {
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
}
.home .scroll-dot__inner {
  position: relative;
  height: 4.1666666667vw;
}
@media screen and (max-width: 900px) {
  .home .scroll-dot__inner {
    height: 21.3333333333vw;
  }
}
.home .scroll-dot__inner:before {
  content: "";
  width: 0.5px;
  height: 100%;
  background: #000;
  position: absolute;
  top: 0;
  left: 50%;
}
.home .scroll-dot__point {
  width: 0.7291666667vw;
  height: 0.7291666667vw;
  position: absolute;
  left: 50%;
  top: -0.4166666667vw;
  transform: translateX(-50%);
  animation: point_scroll_front 2.6s ease-out infinite;
}
@media screen and (max-width: 900px) {
  .home .scroll-dot__point {
    width: 2.9333333333vw;
    height: 2.9333333333vw;
    animation: point_scroll_front_sp 2.6s ease-out infinite;
    top: -1.6vw;
  }
}
.home .scroll-dot__point:before {
  content: "";
  /* background: rgba(0,0,0,.08); */
  background: rgba(130, 169, 224, 0.8);
  border-radius: 50%;
  width: 1.5625vw;
  height: 1.5625vw;
  position: absolute;
  opacity: 0;
  top: -1.5625vw;
  left: 50%;
  z-index: 3;
  transform: translateX(-50%);
  animation: point_scroll_back 2.6s ease-out infinite;
}
@media screen and (max-width: 900px) {
  .home .scroll-dot__point:before {
    width: 8vw;
    height: 8vw;
    top: -4vw;
    animation: point_scroll_back_sp 2.6s ease-out infinite;
  }
}
.home .scroll-dot__point:after {
  content: "";
  background: url(/images/dot.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  width: 0.7291666667vw;
  height: 0.7291666667vw;
  z-index: 5;
  position: absolute;
  left: 0;
  top: -0.4166666667vw;
}
@media screen and (max-width: 900px) {
  .home .scroll-dot__point:after {
    width: 2.9333333333vw;
    height: 2.9333333333vw;
    top: -2.1333333333vw;
  }
}
.home .scroll-dot__label {
  margin-top: 1.0416666667vw;
}
@media screen and (max-width: 900px) {
  .home .scroll-dot__label {
    margin-top: 5.3333333333vw;
  }
}
.home .scroll-dot__text {
  font-family: "Montserrat", sans-serif;
  font-size: 0.625vw;
  font-weight: 700;
}
@media screen and (max-width: 900px) {
  .home .scroll-dot__text {
    font-size: 3.2vw;
  }
}

@keyframes mv_ttl {
  0% {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes mv_text {
  0% {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes point_scroll_front {
  0% {
    top: -0.4166666667vw;
  }
  100% {
    top: 4.1666666667vw;
  }
}
@keyframes point_scroll_front_sp {
  0% {
    top: -1.3333333333vw;
  }
  100% {
    top: 22.9333333333vw;
  }
}
@keyframes point_scroll_back {
  0% {
    top: -0.78125vw;
  }
  90% {
    opacity: 1;
  }
  95% {
    opacity: 1;
  }
  100% {
    opacity: 0.4;
    top: -0.78125vw;
  }
}
@keyframes point_scroll_back_sp {
  0% {
    top: -4.5333333333vw;
  }
  90% {
    opacity: 1;
  }
  95% {
    opacity: 1;
  }
  100% {
    opacity: 0.4;
    top: -4.5333333333vw;
  }
}
.company .mv {
  box-sizing: border-box;
  padding: 3.125vw 0;
  border-radius: 1.5625vw;
  overflow: hidden;
}
@media screen and (max-width: 900px) {
  .company .mv {
    padding: 4vw 0 2.6666666667vw;
    border-radius: 2.6666666667vw;
  }
}
.company .company__info {
  box-sizing: border-box;
  padding: 0 14.0625vw;
}
@media screen and (max-width: 900px) {
  .company .company__info {
    padding: 0;
  }
}
.company .company__info .info-list__item {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 1.5625vw 0;
  border-bottom: 1px solid #D8DCE6;
}
@media screen and (max-width: 900px) {
  .company .company__info .info-list__item {
    padding: 4vw 0;
  }
}
.company .company__info .info-list__item:last-child {
  border: none;
}
.company .company__info .info-list__ttl {
  font-weight: 700;
  font-size: 1.0416666667vw;
  margin-right: 1.5625vw;
  width: 5.7291666667vw;
  box-sizing: border-box;
  letter-spacing: 0.05em;
}
@media screen and (max-width: 900px) {
  .company .company__info .info-list__ttl {
    font-size: 3.2vw;
    margin-right: 2.6666666667vw;
    width: 16vw;
  }
}
.company .company__info .info-list__body {
  font-size: 1.0416666667vw;
  line-height: 1.5;
  letter-spacing: 0.05em;
}
@media screen and (max-width: 900px) {
  .company .company__info .info-list__body {
    font-size: 3.2vw;
  }
}
.company .company__map {
  margin-top: 3.125vw;
}
@media screen and (max-width: 900px) {
  .company .company__map {
    margin-top: 6.6666666667vw;
  }
}
.company .company__map .map-list {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 900px) {
  .company .company__map .map-list {
    flex-direction: column;
  }
}
.company .company__map .map-list a:hover {
  opacity: 1;
}
.company .company__map .map-list__item {
  width: 32%;
  box-sizing: border-box;
}
@media screen and (max-width: 900px) {
  .company .company__map .map-list__item {
    width: 100%;
    margin-bottom: 4vw;
  }
}
@media screen and (max-width: 900px) {
  .company .company__map .map-list__item:last-child {
    margin-bottom: 0;
  }
}
.company .company__map .map-list__link {
  display: block;
}
@media screen and (max-width: 900px) {
  .company .company__map .map-list__link {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.company .company__map .map-list__map {
  width: 100%;
  border-radius: 1.5625vw;
  overflow: hidden;
  /*
  img {
    transition: all 0.5s;
  }
  &:hover {
    img {
      transform: scale(1.05, 1.05);
    }
  }
  */
}
@media screen and (max-width: 900px) {
  .company .company__map .map-list__map {
    border-radius: 2.6666666667vw;
    width: 21.3333333333vw;
    height: 21.3333333333vw;
  }
  .company .company__map .map-list__map img {
    width: 21.3333333333vw;
    height: 21.3333333333vw;
    object-fit: cover;
    object-position: center center;
  }
}
.company .company__map .map-list__map:hover {
  opacity: 1;
}
@media screen and (max-width: 900px) {
  .company .company__map .map-list__ttl {
    display: flex;
    align-items: center;
  }
}
@media screen and (max-width: 900px) {
  .company .company__map .map-list__ttl--icon {
    width: 2.6666666667vw;
    margin-right: 1.3333333333vw;
    box-sizing: border-box;
  }
}
.company .company__map .map-list__ttl--text {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 1.3541666667vw;
  color: #4A5562;
}
@media screen and (max-width: 900px) {
  .company .company__map .map-list__ttl--text {
    font-size: 4vw;
    color: #000;
  }
}
.company .company__map .map-list__info {
  margin-top: 1.0416666667vw;
}
@media screen and (max-width: 900px) {
  .company .company__map .map-list__info {
    width: 58.1333333333vw;
    white-space: normal;
  }
}
.company .company__map .map-list__info--ttl {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 1.3541666667vw;
  color: #F4F7FB;
}
@media screen and (max-width: 900px) {
  .company .company__map .map-list__info--ttl {
    display: flex;
    align-items: center;
  }
}
.company .company__map .map-list__info--data {
  margin-top: 0.5208333333vw;
  display: flex;
}
@media screen and (max-width: 900px) {
  .company .company__map .map-list__info--data {
    display: block;
    align-items: center;
  }
}
.company .company__map .map-list__info--icon {
  width: 0.78125vw;
  margin-right: 0.4166666667vw;
}
@media screen and (max-width: 900px) {
  .company .company__map .map-list__info--icon {
    display: none;
  }
}
.company .company__map .map-list__info--text {
  font-size: 0.8333333333vw;
  line-height: 1.4;
}
@media screen and (max-width: 900px) {
  .company .company__map .map-list__info--text {
    line-height: 1.5;
    font-size: 3.2vw;
    letter-spacing: 0.05em;
  }
}
@media screen and (max-width: 900px) {
  .company .company__map .map-list__info--data {
    margin-top: 1.3333333333vw;
  }
}
.company .company__map .map-list__button {
  display: none;
}
@media screen and (max-width: 900px) {
  .company .company__map .map-list__button {
    display: block;
    width: 4.8vw;
  }
}
.company .company__map .map-list__map {
  filter: grayscale(100%);
  height: 13.0208333333vw;
}
@media screen and (max-width: 900px) {
  .company .company__map .map-list__map {
    height: 21.4vw;
  }
}
.company .company__map .map-list__map:hover {
  filter: grayscale(0);
}
.company .company__member .grid__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  box-sizing: border-box;
}
.company .company__member .grid__list:after {
  content: "";
  display: block;
  width: 32%;
}
.company .company__member .grid__item {
  width: 32%;
  margin-top: 0;
  margin-bottom: 3.125vw;
}
@media screen and (max-width: 900px) {
  .company .company__member .grid__item {
    width: 47.82%;
    margin-bottom: 4vw;
  }
}
.company .company__member .grid__thumb {
  height: 13.0208333333vw;
  width: 100%;
  overflow: hidden;
  border-radius: 1.5625vw;
  background: linear-gradient(76.69deg, #CEE1E8 0%, #EEF2F5 100%);
  box-sizing: border-box;
}
@media screen and (max-width: 900px) {
  .company .company__member .grid__thumb {
    width: 100%;
    height: 44vw;
    border-radius: 2.6666666667vw;
  }
}
.company .company__member .grid__thumb img {
  width: 26.7708333333vw;
  height: 13.0208333333vw;
  object-fit: cover;
  object-position: center center;
}
.company .company__member .grid__thumb img.-pos {
  object-fit: cover;
  object-position: center 40%;
}
@media screen and (max-width: 900px) {
  .company .company__member .grid__thumb img {
    width: 44vw;
    height: 44vw;
    object-fit: contain;
    object-position: center center;
  }
  .company .company__member .grid__thumb img.-pos {
    width: 100%;
    object-fit: cover;
    object-position: center 40%;
  }
}
.company .company__member .memberinfo {
  margin-top: 1.0416666667vw;
  line-height: 1;
}
@media screen and (max-width: 900px) {
  .company .company__member .memberinfo {
    margin-top: 4vw;
  }
}
.company .company__member .memberinfo__title, .company .company__member .memberinfo__name {
  font-weight: 700;
  font-size: 1.0416666667vw;
  letter-spacing: 0.05em;
}
@media screen and (max-width: 900px) {
  .company .company__member .memberinfo__title, .company .company__member .memberinfo__name {
    font-size: 3.2vw;
    line-height: 1.5;
  }
}
@media screen and (max-width: 900px) {
  .company .company__member .memberinfo__title .-title-small, .company .company__member .memberinfo__name .-title-small {
    font-size: 2.9333333333vw;
  }
}
.company .company__member .memberinfo__name {
  margin-top: 0.7291666667vw;
}
@media screen and (max-width: 900px) {
  .company .company__member .memberinfo__name {
    margin-top: 1.3333333333vw;
  }
}
.company .company__member .memberinfo__name-ja {
  font-size: 1.0416666667vw;
}
@media screen and (max-width: 900px) {
  .company .company__member .memberinfo__name-ja {
    font-size: 3.2vw;
  }
}
.company .company__member .memberinfo__summary {
  color: #000;
  font-size: 0.9375vw;
  line-height: 1.5;
  margin-top: 0.5208333333vw;
  letter-spacing: 0.05em;
}
@media screen and (max-width: 900px) {
  .company .company__member .memberinfo__summary {
    font-size: 3.2vw;
    margin-top: 1.6vw;
  }
}
.company .company__history .timeline {
  width: 55.1041666667vw;
  margin: 0 auto;
  position: relative;
}
@media screen and (max-width: 900px) {
  .company .company__history .timeline {
    width: 100%;
  }
}
.company .company__history .timeline:after {
  content: "";
  width: 0.1041666667vw;
  height: calc(100% - 3vw);
  position: absolute;
  background: #00a9e0;
  left: 1.4vw;
  top: 1.09375vw;
  z-index: 0;
}
@media screen and (max-width: 900px) {
  .company .company__history .timeline:after {
    width: 0.5333333333vw;
    top: 5.6vw;
    left: 7vw;
    height: calc(100% - 18vw);
  }
}
.company .company__history .timeline__list {
  display: flex;
  box-sizing: border-box;
  padding: 1.0416666667vw 0;
  border-top: 1px solid #D8DCE6;
}
@media screen and (max-width: 900px) {
  .company .company__history .timeline__list {
    padding: 5.3333333333vw 0;
  }
}
.company .company__history .timeline__list:last-child {
  border-bottom: 1px solid #D8DCE6;
}
.company .company__history .timeline__index {
  width: 2.7604166667vw;
  box-sizing: border-box;
  background: transparent;
  position: relative;
  padding-top: 0;
  margin-right: 1.5625vw;
}
@media screen and (max-width: 900px) {
  .company .company__history .timeline__index {
    width: 14.4vw;
    margin-right: 3.2vw;
  }
}
.company .company__history .timeline__index span {
  box-sizing: border-box;
  font-size: 0.8333333333vw;
  color: #fff;
  font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", "メイリオ", "Hiragino Kaku Gothic ProN", "Hiragino Sans", sans-serif;
  background: #00a9e0;
  display: block;
  padding: 0.5208333333vw 0;
  border-radius: 0.5208333333vw;
  text-align: center;
  width: 2.7604166667vw;
}
@media screen and (max-width: 900px) {
  .company .company__history .timeline__index span {
    width: 14.4vw;
    padding: 1.3333333333vw 0;
    border-radius: 1.3333333333vw;
    font-size: 3.2vw;
  }
}
.company .company__history .timeline__content {
  padding-top: 0.2083333333vw;
  padding-bottom: 0;
  width: calc(100% - 4.3229166667vw);
  box-sizing: border-box;
  align-items: flex-start;
}
@media screen and (max-width: 900px) {
  .company .company__history .timeline__content {
    padding-top: 1.0666666667vw;
    width: calc(100% - 19.7333333333vw);
  }
}
.company .company__history .timeline__month, .company .company__history .timeline__detail {
  padding-top: 2.0833333333vw;
  border: none;
}
@media screen and (max-width: 900px) {
  .company .company__history .timeline__month, .company .company__history .timeline__detail {
    padding-top: 5.3333333333vw;
  }
}
.company .company__history .timeline__month:first-of-type, .company .company__history .timeline__detail:first-of-type {
  padding-top: 0;
}
.company .company__history .timeline__month {
  width: 2.34375vw;
  box-sizing: border-box;
  line-height: 1;
}
@media screen and (max-width: 900px) {
  .company .company__history .timeline__month {
    width: 8vw;
  }
}
.company .company__history .timeline__month span {
  padding: 0;
  font-size: 1.0416666667vw;
  font-weight: bold;
  line-height: 1.5;
  font-weight: 700;
  color: #000;
}
@media screen and (max-width: 900px) {
  .company .company__history .timeline__month span {
    font-size: 3.2vw;
  }
}
.company .company__history .timeline__detail {
  box-sizing: border-box;
  width: calc(100% - 2.34375vw);
  box-sizing: border-box;
  letter-spacing: 0.05em;
}
@media screen and (max-width: 900px) {
  .company .company__history .timeline__detail {
    width: calc(100% - 8vw);
  }
}
.company .company__history .timeline__detail p {
  padding-left: 1.5625vw;
  font-size: 1.0416666667vw;
  line-height: 1.5;
}
@media screen and (max-width: 900px) {
  .company .company__history .timeline__detail p {
    padding-left: 2.6666666667vw;
    font-size: 3.2vw;
  }
}
.company .mission__desc {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 900px) {
  .company .mission__desc {
    flex-direction: column;
    align-items: center;
  }
}
.company .mission__title {
  width: 32.2916666667vw;
  margin-right: 5.2083333333vw;
  box-sizing: border-box;
}
@media screen and (max-width: 900px) {
  .company .mission__title {
    width: 69.3333333333vw;
    margin-right: 0;
  }
}
.company .mission__ttl {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 900px) {
  .company .mission__ttl {
    flex-direction: column;
  }
}
.company .mission__ttl--en {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 2.7604166667vw;
  margin-right: 1.09375vw;
}
@media screen and (max-width: 900px) {
  .company .mission__ttl--en {
    font-size: 7.4666666667vw;
    margin-right: 0;
  }
}
.company .mission__ttl--ja {
  font-weight: 700;
  font-size: 1.09375vw;
}
@media screen and (max-width: 900px) {
  .company .mission__ttl--ja {
    margin-top: 2.6666666667vw;
    font-size: 3.2vw;
  }
}
.company .mission__body {
  margin-top: 1.3020833333vw;
  font-size: 1.25vw;
  line-height: 1.5;
  letter-spacing: 0.05em;
}
@media screen and (max-width: 900px) {
  .company .mission__body {
    margin-top: 4vw;
    font-size: 3.7333333333vw;
    text-align: center;
  }
}

/* missionページ {{{ */
@media screen and (max-width: 900px) {
  .company.mission main .contents {
    padding-top: 0;
  }
}
.company.mission .mission__movie {
  margin-bottom: 0;
}
@media screen and (max-width: 900px) {
  .company.mission .mission__movie {
    margin-bottom: 30vw;
  }
}
.company.mission .movie {
  height: 59vw;
  overflow: hidden;
}
@media screen and (max-width: 900px) {
  .company.mission .movie {
    height: 130vw;
  }
}
.company.mission .movie video {
  height: 59vw;
  min-width: 100%;
  object-fit: cover;
  object-position: center center;
}
@media screen and (max-width: 900px) {
  .company.mission .movie video {
    height: 130vw;
    width: 100vw;
  }
}
.company.mission .mv {
  /* companyとかぶってる */
  padding: 0;
}
.company.mission .mv__inner {
  /* position: relative; */
  padding-bottom: 0;
  border-radius: 0;
}
@media screen and (max-width: 900px) {
  .company.mission .mv__inner {
    box-sizing: border-box;
  }
}
.company.mission .mv .catch {
  position: absolute;
  left: 0;
  top: 24%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  /* transform: translate3d(-50%, 0, 0); */
  transition: all 3s cubic-bezier(0.23, 1, 0.32, 1) 3s;
}
@media screen and (max-width: 900px) {
  .company.mission .mv .catch {
    top: 20%;
    /*
    // 旧SP
    position: static;
    display: block;
    left: auto;
    top: auto;
    transition: none;
    margin-top: vw(30, $sp_w);
    overflow: hidden;
    transition: all 1s cubic-bezier(0.23,1,0.32,1) 0.5s;
    */
  }
}
.company.mission .mv .catch.-move {
  transform: scale(0.65, 0.65);
  top: 10%;
}
@media screen and (max-width: 900px) {
  .company.mission .mv .catch.-move {
    transform: scale(0.8, 0.8);
    top: 14%;
  }
}
@media screen and (max-width: 900px) {
  .company.mission .mv .catch.-anime {
    margin-top: -20vw;
  }
}
.company.mission .mv .catch__inner {
  font-size: 6.6666666667vw;
  font-weight: 700;
  text-align: center;
  /* letter-spacing: 0.2em; */
  letter-spacing: 0.05em;
  white-space: nowrap;
  opacity: 0;
  /* font-feature-settings: "palt"; */
}
@media screen and (max-width: 900px) {
  .company.mission .mv .catch__inner {
    font-size: 8.5333333333vw;
  }
}
@media screen and (max-width: 900px) {
  .company.mission .mv .catch__inner.-anime {
    animation: mission_ttl 1s linear 0s forwards;
  }
}
.company.mission .mv .catch__row {
  margin-bottom: 3.125vw;
}
.company.mission .mv .catch__row .char {
  opacity: 0;
}
.company.mission .mv .catch__row .char.-show {
  opacity: 1;
}
.company.mission .mv .message {
  position: absolute;
  left: 0;
  top: 40%;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 900px) {
  .company.mission .mv .message {
    top: 35%;
    /*
    // 旧SP
    position: static;
    left: auto;
    top: auto;
    */
  }
}
.company.mission .mv .message__inner {
  font-weight: 700;
  font-size: 1.6666666667vw;
  text-align: center;
  letter-spacing: 0.05em;
  transform: translateY(20%);
  opacity: 0;
  transition: all 1s cubic-bezier(0.23, 1, 0.32, 1) 3.5s;
}
@media screen and (max-width: 900px) {
  .company.mission .mv .message__inner {
    font-size: 3.7333333333vw;
    /*
    // 旧SP
    margin-top: vw(20, $sp_w);
    font-size: vw(14, $sp_w);
    opacity: 1;
    transform: none;
    transition: none;
    */
  }
}
.company.mission .mv .message__inner.-show {
  transform: translateY(0);
  opacity: 1;
}
@media screen and (max-width: 900px) {
  .company.mission .mv .message__inner.-show {
    opacity: 1;
    transform: none;
  }
}
.company.mission .mv .message__row {
  margin-bottom: 1.5625vw;
}
@media screen and (max-width: 900px) {
  .company.mission .mv .message__row {
    line-height: 2;
  }
}
.company.mission .mv .message__row .char {
  opacity: 0;
  transition: opacity 0.5s linear 0s;
}
@media screen and (max-width: 900px) {
  .company.mission .mv .message__row .char {
    opacity: 1;
    transition: none;
  }
}
.company.mission .mv .message__row .char.-show {
  opacity: 1;
}
.company.mission .mv .sp-movie {
  display: none;
}
@media screen and (max-width: 900px) {
  .company.mission .mv .sp-movie {
    display: block;
    /* position: absolute; */
    width: 100%;
    left: 0;
    padding-top: 40vw;
    transition: all 1s cubic-bezier(0.23, 1, 0.32, 1) 0.5s;
  }
  .company.mission .mv .sp-movie.-move {
    padding-top: 20vw;
    transform: scale(0.45, 0.45);
  }
}
.company.mission .mv .sp-movie__wrap {
  box-sizing: border-box;
  padding: 0 4vw;
}
.company.mission .mv .sp-movie .mv__copy {
  position: relative;
}
.company.mission .mv .sp-movie .mv__line {
  margin-bottom: 4vw;
}
.company.mission .mv .sp-movie .mv__ttl.-line1 {
  width: 90vw;
}
.company.mission .mv .sp-movie .mv__ttl.-line2 {
  width: 60vw;
}
.company.mission .mv .sp-movie .mv__ttl.-line3 {
  width: 94vw;
}
.company.mission .mv .sp-movie .mv__text.-line1 {
  width: 90vw;
}
.company.mission .mv .sp-movie .mv__text.-line2 {
  width: 90vw;
}
.company.mission .mv .sp-movie .mv__text.-line3 {
  width: 90vw;
}
.company.mission .mv .sp-movie .mv__copy2 {
  position: absolute;
  bottom: 19vw;
  right: 0;
}
.company.mission .mv .sp-movie .mv__sign {
  width: 65vw;
}
.company.mission .mission__body {
  letter-spacing: 0.05em;
  white-space: nowrap;
}
@media screen and (max-width: 900px) {
  .company.mission .mission__body {
    line-height: 1.75;
  }
}
.company.mission .scroll__wrap {
  margin: 2.6041666667vw 0 0;
  position: relative;
  /* width: 100%; */
  height: 23.4375vw;
  box-sizing: border-box;
  padding-left: 8.3333333333vw;
}
@media screen and (max-width: 900px) {
  .company.mission .scroll__wrap {
    height: auto;
    margin: 13.3333333333vw auto 0;
    padding: 0 4vw;
  }
}
.company.mission .scroll__list {
  position: absolute;
  display: flex;
}
@media screen and (max-width: 900px) {
  .company.mission .scroll__list {
    position: static;
    flex-direction: column;
  }
}
.company.mission .scroll__item {
  width: 36.4583333333vw;
  margin-right: 1.5625vw;
  border-radius: 1.5625vw;
  overflow: hidden;
  box-sizing: border-box;
}
@media screen and (max-width: 900px) {
  .company.mission .scroll__item {
    width: 100%;
    margin-right: 0;
    margin-bottom: 4vw;
    border-radius: 4vw;
  }
}
.company.mission .scroll__item.-dummy {
  width: 13.5416666667vw;
  box-sizing: border-box;
}
@media screen and (max-width: 900px) {
  .company.mission .scroll__item.-dummy {
    display: none;
  }
}
.company.mission .scroll__image {
  position: relative;
}
@media screen and (max-width: 900px) {
  .company.mission .scroll__image {
    height: 80vw;
  }
}
@media screen and (max-width: 900px) {
  .company.mission .scroll__image img {
    height: 100%;
    object-fit: cover;
  }
}
.company.mission .scroll__text {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  text-align: center;
}
.company.mission .scroll__value {
  width: 4.8958333333vw;
  margin: 0 auto 1.5625vw;
}
@media screen and (max-width: 900px) {
  .company.mission .scroll__value {
    width: 18.6666666667vw;
    margin: 0 auto 5.3333333333vw;
  }
}
.company.mission .scroll__ttl {
  color: #00a9e0;
  font-weight: 700;
  font-size: 2.6041666667vw;
  text-align: center;
  white-space: nowrap;
  letter-spacing: 0.05em;
}
@media screen and (max-width: 900px) {
  .company.mission .scroll__ttl {
    font-size: 10.6666666667vw;
    line-height: 1.125;
  }
}
.company.mission .scroll__body {
  margin-top: 2.0833333333vw;
  font-weight: 700;
  font-size: 1.25vw;
  line-height: 1.5;
  text-align: center;
  white-space: nowrap;
  letter-spacing: 0.05em;
}
@media screen and (max-width: 900px) {
  .company.mission .scroll__body {
    margin-top: 2.6666666667vw;
    font-size: 3.7333333333vw;
    letter-spacing: 0.07em;
  }
}
.company.mission .message__box {
  height: 51.5625vw;
  width: 100%;
  background: url(/images/company/message_bg.png);
  background-repeat: no-repeat;
  background-position: left top;
  background-size: cover;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 2.6041666667vw 0 2.6041666667vw 7.03125vw;
  border-radius: 1.0416666667vw;
  overflow: hidden;
}
@media screen and (max-width: 900px) {
  .company.mission .message__box {
    flex-direction: column;
    background: url(/images/company/message_bg_sp.png);
    padding: 13.3333333333vw 1.3333333333vw 2.6666666667vw 8vw;
    height: 131.7333333333vw;
    border-radius: 2.6666666667vw;
  }
}
.company.mission .message__box--left {
  width: 18.75vw;
}
@media screen and (max-width: 900px) {
  .company.mission .message__box--left {
    width: 100%;
    padding-right: 6.6666666667vw;
  }
}
.company.mission .message__box--right {
  box-sizing: border-box;
  width: 51.5625vw;
  height: 100%;
  overflow-y: auto;
}
@media screen and (max-width: 900px) {
  .company.mission .message__box--right {
    width: 100%;
    margin-top: 8vw;
  }
}
.company.mission .message__title--en {
  font-weight: 700;
  font-size: 2.7604166667vw;
  font-family: "Montserrat", sans-serif;
  color: #00a9e0;
}
@media screen and (max-width: 900px) {
  .company.mission .message__title--en {
    font-size: 7.4666666667vw;
    text-align: center;
  }
}
.company.mission .message__title--ja {
  font-weight: 700;
  font-size: 1.09375vw;
  margin-top: 0.5208333333vw;
}
@media screen and (max-width: 900px) {
  .company.mission .message__title--ja {
    font-size: 3.2vw;
    margin-top: 2.6666666667vw;
    text-align: center;
  }
}
.company.mission .message__ttl {
  font-weight: 700;
  font-size: 3.6458333333vw;
  margin-top: 1.5625vw;
  letter-spacing: 0.05em;
  white-space: nowrap;
  line-height: 1.2;
}
@media screen and (max-width: 900px) {
  .company.mission .message__ttl {
    font-size: 6.6666666667vw;
    margin-top: 4vw;
    text-align: center;
  }
}
.company.mission .message__body {
  font-weight: 700;
  font-size: 1.0416666667vw;
  line-height: 2;
  box-sizing: border-box;
  padding: 0 2.6041666667vw 0 0.5208333333vw;
  letter-spacing: 0.05em;
}
@media screen and (max-width: 900px) {
  .company.mission .message__body {
    padding: 0 6.6666666667vw 0 0;
    font-size: 3.7333333333vw;
  }
}
.company.mission .dot-wrap {
  margin-top: 1.0416666667vw;
}
@media screen and (max-width: 900px) {
  .company.mission .dot-wrap {
    margin-top: 5.3333333333vw;
  }
}
.company.mission .dot-list {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  margin-top: 2.0833333333vw;
}
@media screen and (max-width: 900px) {
  .company.mission .dot-list {
    display: none;
  }
}
.company.mission .dot-list__item {
  box-sizing: border-box;
  display: block;
  width: 0.5208333333vw;
  height: 0.5208333333vw;
  border-radius: 100vw;
  background: #ACB4C1;
  margin: 0 0.2604166667vw;
}
@media screen and (max-width: 900px) {
  .company.mission .dot-list__item {
    width: 1.3333333333vw;
    height: 1.3333333333vw;
    margin: 0 2.6666666667vw;
  }
}
.company.mission .dot-list__item.-active {
  background: #00a9e0;
}

/* body.mission }}} */
@keyframes mission_ttl {
  0% {
    color: #000;
    transform: translate3d(0, 100%, 0);
  }
  50% {
    transform: translate3d(0, 0, 0);
    color: #000;
  }
  100% {
    color: #00a9e0;
    transform: translate3d(0, 0, 0);
  }
}
.presskit .section.guideline {
  background: #F4F9FF;
}
.presskit .presskit-ttl {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 900px) {
  .presskit .presskit-ttl {
    flex-direction: column;
    align-items: flex-start;
  }
}
.presskit .presskit-ttl__en {
  font-family: "Montserrat", sans-serif;
  color: #00a9e0;
  font-weight: 700;
  font-size: 2.6041666667vw;
  margin-right: 1.0416666667vw;
}
@media screen and (max-width: 900px) {
  .presskit .presskit-ttl__en {
    font-size: 7.4666666667vw;
    margin-right: 0;
    margin-bottom: 2.6666666667vw;
  }
}
.presskit .presskit-ttl__ja {
  color: #000;
  font-weight: 700;
  font-size: 1.0416666667vw;
}
@media screen and (max-width: 900px) {
  .presskit .presskit-ttl__ja {
    font-size: 3.2vw;
    margin-top: 0;
  }
}
.presskit .presskit__guideline {
  padding: 4.6875vw 8.3333333333vw;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 900px) {
  .presskit .presskit__guideline {
    padding: 8vw 4vw;
    flex-direction: column;
  }
}
.presskit .presskit__guideline .guideline-desc {
  width: 38.5416666667vw;
}
@media screen and (max-width: 900px) {
  .presskit .presskit__guideline .guideline-desc {
    width: 100%;
  }
}
.presskit .presskit__guideline .guideline-image {
  max-width: 40.9375vw;
}
@media screen and (max-width: 900px) {
  .presskit .presskit__guideline .guideline-image {
    width: 100%;
    max-width: none;
    margin-top: 5.3333333333vw;
  }
}
.presskit .presskit__guideline .guideline-image img {
  width: 100%;
}
.presskit .guideline-desc .desc {
  margin-top: 2.6041666667vw;
  line-height: 2;
  font-weight: 700;
  font-size: 1.1458333333vw;
}
@media screen and (max-width: 900px) {
  .presskit .guideline-desc .desc {
    margin-top: 5.3333333333vw;
    font-size: 3.7333333333vw;
  }
}
.presskit .dl-btn {
  margin-top: 2.6041666667vw;
}
@media screen and (max-width: 900px) {
  .presskit .dl-btn {
    margin-top: 4vw;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
  }
}
.presskit .dl-btn__link {
  background: #00a9e0;
  color: #fff;
  border-radius: 0.5208333333vw;
  position: relative;
  font-weight: 700;
  font-size: 0.7291666667vw;
  padding: 0.6770833333vw 2.6041666667vw 0.6770833333vw 1.5625vw;
  box-sizing: border-box;
}
@media screen and (max-width: 900px) {
  .presskit .dl-btn__link {
    font-size: 3.7333333333vw;
    border-radius: 2.6666666667vw;
    padding: 3.4666666667vw 13.3333333333vw 3.4666666667vw 8vw;
  }
}
.presskit .dl-btn__link:after {
  content: "";
  background-image: url(/images/icon/dl.svg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  width: 0.5208333333vw;
  height: 0.625vw;
  position: absolute;
  right: 1.5625vw;
  top: 50%;
  transform: translateY(-50%);
}
@media screen and (max-width: 900px) {
  .presskit .dl-btn__link:after {
    width: 2.6666666667vw;
    height: 3.2vw;
    right: 8vw;
  }
}
.presskit .section.lower {
  margin-top: 9.375vw;
  box-sizing: border-box;
  padding: 0 12.7604166667vw;
}
@media screen and (max-width: 900px) {
  .presskit .section.lower {
    margin-top: 24vw;
    padding: 0 4vw;
  }
}
.presskit .lower-section {
  margin-bottom: 8.3333333333vw;
}
@media screen and (max-width: 900px) {
  .presskit .lower-section {
    margin-bottom: 24vw;
  }
}
.presskit .lower-section:last-of-type {
  margin-bottom: 0;
}
.presskit .box {
  display: flex;
  margin-top: 2.6041666667vw;
}
@media screen and (max-width: 900px) {
  .presskit .box {
    margin-top: 8vw;
    justify-content: flex-start;
    flex-direction: column;
  }
}
.presskit .box__left {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #F4F9FF;
  height: 14.5833333333vw;
  border-radius: 1.0416666667vw;
  width: 36.4583333333vw;
  overflow: hidden;
  margin-right: 2.6041666667vw;
}
@media screen and (max-width: 900px) {
  .presskit .box__left {
    width: 100%;
    flex-direction: column;
    padding: 0 4vw;
    height: 37.3333333333vw;
    border-radius: 2.6666666667vw;
    margin-right: 0;
  }
}
.presskit .box__right {
  width: 32.8125vw;
}
@media screen and (max-width: 900px) {
  .presskit .box__right {
    width: 100%;
    box-sizing: border-box;
  }
}
.presskit .box .desc {
  font-size: 1.1458333333vw;
  line-height: 1.5;
}
@media screen and (max-width: 900px) {
  .presskit .box .desc {
    font-size: 3.2vw;
    padding-top: 4vw;
  }
}
.presskit .box__desc {
  font-size: 1.1458333333vw;
  line-height: 1.5;
}
@media screen and (max-width: 900px) {
  .presskit .box__desc {
    font-size: 3.2vw;
    padding-top: 4vw;
  }
}
.presskit .box .-image1 {
  width: 10.4166666667vw;
}
@media screen and (max-width: 900px) {
  .presskit .box .-image1 {
    width: 28.8vw;
  }
}
.presskit .box .-image2 {
  width: 14.3229166667vw;
}
@media screen and (max-width: 900px) {
  .presskit .box .-image2 {
    width: 34.6666666667vw;
  }
}
.presskit .box .-image3 {
  width: 16.1458333333vw;
}
@media screen and (max-width: 900px) {
  .presskit .box .-image3 {
    width: 41.8666666667vw;
  }
}
.presskit .box .-image4 {
  width: 18.2291666667vw;
}
@media screen and (max-width: 900px) {
  .presskit .box .-image4 {
    width: 44.8vw;
  }
}
.presskit .box .-image5 {
  width: 23.125vw;
}
@media screen and (max-width: 900px) {
  .presskit .box .-image5 {
    width: 74.6666666667vw;
  }
}
.presskit .box .-image6 {
  width: 100%;
}

.news-content .section__inner {
  width: 46.875vw;
  margin: 0 auto;
  padding: 0;
  box-sizing: border-box;
}
@media screen and (max-width: 900px) {
  .news-content .section__inner {
    width: 100%;
    padding: 0 4vw;
  }
}
@media screen and (max-width: 900px) {
  .news-content .news__kv {
    width: 100vw;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    position: relative;
  }
}
.news-content .news__kv img {
  width: 100%;
}
.news-content .news__desc {
  margin: 1.4583333333vw 0;
}
@media screen and (max-width: 900px) {
  .news-content .news__desc {
    margin: 5.3333333333vw 0;
  }
}
.news-content .news__category {
  font-size: 0.8333333333vw;
  font-weight: 700;
  color: #00a9e0;
}
@media screen and (max-width: 900px) {
  .news-content .news__category {
    font-size: 3.2vw;
  }
}
.news-content .news__ttl {
  margin-top: 0.78125vw;
  margin-bottom: 0.78125vw;
  font-size: 1.4583333333vw;
  font-weight: 700;
  line-height: 1.5;
}
@media screen and (max-width: 900px) {
  .news-content .news__ttl {
    font-size: 4.8vw;
    margin: 4vw 0 4vw;
  }
}
.news-content .news__info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
}
.news-content .news__info.-bottom {
  justify-content: flex-end;
}
@media screen and (max-width: 900px) {
  .news-content .news__info.-bottom {
    justify-content: center;
  }
}
.news-content .news__date {
  font-size: 1.0416666667vw;
  opacity: 0.5;
}
@media screen and (max-width: 900px) {
  .news-content .news__date {
    font-size: 3.7333333333vw;
  }
}
.news-content .news__sns {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.news-content .news__sns--ttl {
  font-family: "Montserrat", sans-serif;
  font-size: 1.0416666667vw;
  opacity: 0.5;
}
@media screen and (max-width: 900px) {
  .news-content .news__sns--ttl {
    font-size: 3.7333333333vw;
  }
}
.news-content .news__sns--item {
  width: 1.3020833333vw;
  margin-left: 0.5208333333vw;
  box-sizing: border-box;
}
@media screen and (max-width: 900px) {
  .news-content .news__sns--item {
    width: 6.4vw;
    margin-left: 1.3333333333vw;
  }
}
.news-content .news__body {
  line-height: 2;
  font-size: 1.1458333333vw;
}
@media screen and (max-width: 900px) {
  .news-content .news__body {
    font-size: 4vw;
    margin-top: 4vw;
    margin-bottom: 4vw;
  }
}
.news-content .news__body h3 {
  font-weight: 700;
  font-size: 1.25vw;
  margin: 1.5625vw 0;
}
@media screen and (max-width: 900px) {
  .news-content .news__body h3 {
    font-size: 4.8vw;
    margin: 5.3333333333vw 0;
  }
}
.news-content .news__body strong {
  font-weight: 700;
}
@media screen and (max-width: 900px) {
  .news-content .news__body .cmn-section {
    word-break: break-all;
  }
}
.news-content .news__comment {
  padding: 2.6041666667vw;
  border-radius: 0.5208333333vw;
  overflow: hidden;
  background: #F4F9FF;
  box-sizing: border-box;
  margin: 2.6041666667vw 0 1.5625vw;
  line-height: 2;
  font-size: 1.1458333333vw;
}
@media screen and (max-width: 900px) {
  .news-content .news__comment {
    padding: 8vw 4vw;
    margin: 5.3333333333vw 0 4vw;
    font-size: 4.2666666667vw;
    border-radius: 2.6666666667vw;
  }
}
.news-content .news__comment strong {
  font-weight: 700;
}
@media screen and (max-width: 900px) {
  .news-content__bottom {
    margin-bottom: 8vw;
  }
}
.news-content__bottom.section {
  margin-bottom: 0.5208333333vw;
}
.news-content__bottom .pickup-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 1.0416666667vw 0;
}
@media screen and (max-width: 900px) {
  .news-content__bottom .pickup-list {
    margin: 4vw 0 4vw;
  }
}
.news-content__bottom .pickup-list__item {
  width: 26.0416666667vw;
  box-sizing: border-box;
}
@media screen and (max-width: 900px) {
  .news-content__bottom .pickup-list__item {
    width: 44vw;
  }
}
.news-content__bottom .pickup-list__item img {
  width: 26.0416666667vw;
  height: 13.6979166667vw;
  object-position: center center;
  object-fit: cover;
}
@media screen and (max-width: 900px) {
  .news-content__bottom .pickup-list__item img {
    width: 44vw;
    height: 22.9333333333vw;
  }
}
.news-content__bottom .pickup-list__item .indexNews__name {
  line-clamp: 2;
  -webkit-line-clamp: 2;
}
@media screen and (max-width: 900px) {
  .news-content__bottom .pickup-list__item .indexNews__name {
    line-clamp: 5;
    -webkit-line-clamp: 5;
  }
}
.news-content__bottom .news__pager {
  border-top: 1px solid rgba(8, 19, 26, 0.14);
  box-sizing: border-box;
  margin-top: 2.6041666667vw;
  margin-bottom: 2.6041666667vw;
}
.news-content__bottom .pager-list {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 900px) {
  .news-content__bottom .pager-list {
    flex-direction: column;
    margin-bottom: 8vw;
  }
}
.news-content__bottom .pager-list__item {
  width: 50%;
  box-sizing: border-box;
  position: relative;
}
@media screen and (max-width: 900px) {
  .news-content__bottom .pager-list__item {
    width: 100%;
    border-bottom: 1px solid rgba(8, 19, 26, 0.14);
  }
}
.news-content__bottom .pager-list__item:first-child:after {
  content: "";
  height: 4.5833333333vw;
  width: 1px;
  background: #e4e5e6;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
@media screen and (max-width: 900px) {
  .news-content__bottom .pager-list__item:first-child:after {
    content: none;
  }
}
@media screen and (max-width: 900px) {
  .news-content__bottom .pager-list__item:first-child.-no-content {
    border-bottom: none;
  }
}
.news-content__bottom .pager-list__item:first-child .pager-list__link:after {
  content: "";
  border-top: 3px solid #00a9e0;
  border-left: 3px solid #00a9e0;
  width: 0.78125vw;
  height: 0.78125vw;
  position: absolute;
  left: 0.2604166667vw;
  top: 50%;
  transform: rotate(-45deg) translateY(-50%);
}
@media screen and (max-width: 900px) {
  .news-content__bottom .pager-list__item:first-child .pager-list__link:after {
    content: none;
  }
}
.news-content__bottom .pager-list__item:last-child .pager-list__link:after {
  content: "";
  border-top: 3px solid #00a9e0;
  border-right: 3px solid #00a9e0;
  width: 0.78125vw;
  height: 0.78125vw;
  position: absolute;
  left: auto;
  right: 0.2604166667vw;
  top: 50%;
  transform: rotate(45deg) translateY(-50%);
}
@media screen and (max-width: 900px) {
  .news-content__bottom .pager-list__item:last-child .pager-list__link:after {
    content: none;
  }
}
.news-content__bottom .pager-list__link {
  display: block;
  box-sizing: border-box;
  padding: 2.6041666667vw;
  box-sizing: border-box;
  position: relative;
}
@media screen and (max-width: 900px) {
  .news-content__bottom .pager-list__link {
    padding: 4vw 0;
  }
}
@media screen and (max-width: 900px) {
  .news-content__bottom .pager-list__link--wrap {
    display: flex;
    width: 100%;
  }
}
.news-content__bottom .pager-list__link--wrap.-next {
  justify-content: flex-end;
}
.news-content__bottom .pager-list__link--ttl {
  color: #00a9e0;
  font-size: 1.0416666667vw;
  font-weight: 700;
  margin-bottom: 1.0416666667vw;
}
@media screen and (max-width: 900px) {
  .news-content__bottom .pager-list__link--ttl {
    font-size: 3.2vw;
    margin-bottom: 1.3333333333vw;
    position: relative;
  }
}
@media screen and (max-width: 900px) {
  .news-content__bottom .pager-list__link--ttl.-prev {
    padding-left: 4.5333333333vw;
  }
}
@media screen and (max-width: 900px) {
  .news-content__bottom .pager-list__link--ttl.-prev:after {
    content: "";
    border-top: 2px solid #00a9e0;
    border-left: 2px solid #00a9e0;
    width: 2.1333333333vw;
    height: 2.1333333333vw;
    position: absolute;
    left: 2.1333333333vw;
    top: 50%;
    transform-origin: bottom left;
    transform: rotate(-45deg) translateY(-50%);
  }
}
@media screen and (max-width: 900px) {
  .news-content__bottom .pager-list__link--ttl.-next {
    padding-right: 4.5333333333vw;
  }
}
@media screen and (max-width: 900px) {
  .news-content__bottom .pager-list__link--ttl.-next:after {
    content: "";
    border-top: 2px solid #00a9e0;
    border-right: 2px solid #00a9e0;
    width: 2.1333333333vw;
    height: 2.1333333333vw;
    position: absolute;
    left: auto;
    right: 2.1333333333vw;
    top: 50%;
    transform-origin: bottom right;
    transform: rotate(45deg) translateY(-50%);
  }
}
.news-content__bottom .pager-list__link--body {
  line-height: 1.5;
  font-size: 1.0416666667vw;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  min-height: 3em;
}
@media screen and (max-width: 900px) {
  .news-content__bottom .pager-list__link--body {
    font-size: 3.4666666667vw;
  }
}

.news-page__tags--wrap {
  display: flex;
  align-items: center;
  margin-left: 1.5625vw;
  margin-top: -1.5vw;
  padding-bottom: 2.0833333333vw;
}
@media screen and (max-width: 900px) {
  .news-page__tags--wrap {
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: 0;
    margin-left: 0;
    padding-left: 2.1333333333vw;
    padding-bottom: 2.6666666667vw;
  }
}
.news-page__tags--item {
  margin-right: 2.6041666667vw;
}
@media screen and (max-width: 900px) {
  .news-page__tags--item {
    margin-right: 8vw;
  }
}
.news-page__tags--item label {
  font-weight: 700;
  font-size: 16px;
}
@media screen and (max-width: 900px) {
  .news-page__tags--item label {
    font-size: 3.7333333333vw;
  }
}
@media screen and (max-width: 900px) {
  .news-page__tags--item:first-of-type {
    margin-bottom: 4vw;
  }
}
.news-page__tags--radio {
  appearance: none;
}
.news-page__tags--radio:checked + .news-page__tags--text:after {
  content: "";
  display: block;
  border-radius: 50%;
  width: 0.9375vw;
  height: 0.9375vw;
  background-color: #00a9e0;
  position: absolute;
  left: -1.3020833333vw;
  top: 50%;
  transform: translateY(-50%);
}
@media screen and (max-width: 900px) {
  .news-page__tags--radio:checked + .news-page__tags--text:after {
    width: 3.7333333333vw;
    height: 3.7333333333vw;
    left: -0.6666666667vw;
  }
}
.news-page__tags--text {
  padding-left: 0.2604166667vw;
  position: relative;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 900px) {
  .news-page__tags--text {
    white-space: nowrap;
    padding-left: 6.4vw;
  }
}
.news-page__tags--text:before {
  content: "";
  display: block;
  border-radius: 50%;
  border: 1px solid #00a9e0;
  width: 1.25vw;
  height: 1.25vw;
  position: absolute;
  left: -1.4583333333vw;
  top: 50%;
  transform: translateY(-50%);
}
@media screen and (max-width: 900px) {
  .news-page__tags--text:before {
    width: 5.3333333333vw;
    height: 5.3333333333vw;
  }
}

blockquote {
  box-sizing: border-box;
  padding: 2.6041666667vw;
  border-radius: 0.5208333333vw;
  background: #F4F9FF;
}
@media screen and (max-width: 900px) {
  blockquote {
    padding: 8vw, 4vw;
    border-radius: 2.6666666667vw;
  }
}

.recruit .main .recruit-index-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.recruit .main .recruit-index-list__item {
  width: 19.6875vw;
  opacity: 0;
  margin-top: 1.2em;
  transition: all 1.4s ease-out;
}
.recruit .main .recruit-index-list__item:nth-child(2) {
  transition: all 1.4s ease-out 0.2s;
}
.recruit .main .recruit-index-list__item:nth-child(3) {
  transition: all 1.4s ease-out 0.4s;
}
.recruit .main .recruit-index-list__item:nth-child(4) {
  transition: all 1.4s ease-out 0.6s;
}
@media screen and (max-width: 900px) {
  .recruit .main .recruit-index-list__item {
    width: 44vw;
    margin-bottom: 5.3333333333vw;
  }
}
.recruit .main .recruit-index-list__item.-anime {
  opacity: 1;
  margin-top: 0;
}
.recruit .main .recruit-index-list__item--img {
  width: 100%;
}
@media screen and (max-width: 900px) {
  .recruit .main .recruit-index-list__item:nth-last-child(-n+2) {
    margin-bottom: 0;
  }
}
.recruit .main .recruit-index-list__link {
  display: block;
  position: relative;
}
.recruit .main .recruit-index-list__text {
  position: absolute;
  left: 50%;
  top: 50%;
  text-align: center;
  transform: translateX(-50%) translateY(-50%);
  color: #fff;
  font-weight: 700;
}
.recruit .main .recruit-index-list__ttl {
  font-size: 1.5625vw;
  font-family: "Montserrat", sans-serif;
  white-space: nowrap;
}
@media screen and (max-width: 900px) {
  .recruit .main .recruit-index-list__ttl {
    font-size: 5.3333333333vw;
  }
}
.recruit .main .recruit-index-list__subttl {
  margin-top: 0.5729166667vw;
  font-size: 0.8333333333vw;
}
@media screen and (max-width: 900px) {
  .recruit .main .recruit-index-list__subttl {
    margin-top: 2.1333333333vw;
    font-size: 2.6666666667vw;
  }
}
.recruit .main .topics {
  margin-top: 5.2083333333vw;
}
@media screen and (max-width: 900px) {
  .recruit .main .topics {
    margin-top: 13.3333333333vw;
  }
}
@media screen and (max-width: 900px) {
  .recruit .main .topics__inner {
    margin-top: 8vw;
  }
}
.recruit .main .topics .section-topics__wrap {
  padding: 2.6041666667vw 0 0;
}
@media screen and (max-width: 900px) {
  .recruit .main .topics .section-topics__wrap {
    padding: 8vw 0 0;
  }
}
.recruit .main .topics .section-ttl__en {
  font-size: 2.6041666667vw;
}
@media screen and (max-width: 900px) {
  .recruit .main .topics .section-ttl__en {
    font-size: 7.4666666667vw;
  }
}
.recruit .main .topics-list {
  margin-top: 2.6041666667vw;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.recruit .main .topics-list:after {
  content: "";
  display: block;
  width: 19.6875vw;
}
@media screen and (max-width: 900px) {
  .recruit .main .topics-list:after {
    content: none;
  }
}
.recruit .main .topics-list:before {
  content: "";
  display: block;
  width: 19.6875vw;
  order: 1;
}
@media screen and (max-width: 900px) {
  .recruit .main .topics-list:before {
    content: none;
  }
}
.recruit .main .topics-list__item {
  width: 19.6875vw;
  margin-bottom: 2.6041666667vw;
  overflow: hidden;
}
@media screen and (max-width: 900px) {
  .recruit .main .topics-list__item {
    width: 48%;
    margin-bottom: 5.3333333333vw;
  }
}
.recruit .main .topics-list__item:nth-last-child(-n+4) {
  margin-bottom: 0;
}
@media screen and (max-width: 900px) {
  .recruit .main .topics-list__item:nth-last-child(-n+4) {
    margin-bottom: 5.3333333333vw;
  }
}
@media screen and (max-width: 900px) {
  .recruit .main .topics-list__item:nth-last-child(-n+2) {
    margin-bottom: 0;
  }
}
.recruit .main .topics-list__link {
  display: block;
}
.recruit .main .topics-list__link:hover .topics-list__image--img {
  transform: scale(1.05);
}
.recruit .main .topics-list__image {
  overflow: hidden;
  border-radius: 0.5208333333vw;
}
@media screen and (max-width: 900px) {
  .recruit .main .topics-list__image {
    border-radius: 2.6666666667vw;
  }
}
.recruit .main .topics-list__image--img {
  transition: transform 0.5s;
}
.recruit .main .topics-list__text {
  margin-top: 0.78125vw;
}
@media screen and (max-width: 900px) {
  .recruit .main .topics-list__text {
    margin-top: 4vw;
  }
}
.recruit .main .topics-list__dt {
  color: #ACB4C1;
  font-size: 0.625vw;
  font-weight: 700;
  margin-right: 0.78125vw;
  margin-top: 0;
}
@media screen and (max-width: 900px) {
  .recruit .main .topics-list__dt {
    font-size: 2.9333333333vw;
    margin-right: 1.6vw;
  }
}
.recruit .main .topics-list__body {
  margin-top: 0.5208333333vw;
  font-size: 0.9375vw;
  line-height: 1.5;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  line-clamp: 2;
  -webkit-line-clamp: 2;
}
@media screen and (max-width: 900px) {
  .recruit .main .topics-list__body {
    margin-top: 2.6666666667vw;
    font-size: 3.2vw;
    letter-spacing: 0.05em;
    line-clamp: 5;
    -webkit-line-clamp: 5;
  }
}