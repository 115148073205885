
// color
$blk: #000;
$black: #000;
$white: #fff;
$red: #ff0000;

$yappli_blue: #00a9e0;
$yblue: $yappli_blue;
$yblue_9: #C4E6F0;

$gray_main: #ACB4C1;
$gray_main2: #8692A5;
$gray_dark9: #4A5562;
$gray_6: #CDD1DC;
$gray_7: #D8DCE6;
$gray_8: #EEF1F9;
$gray_9: #F4F7FB;

$gray_light6: #CDD1DC;
$gray_light7: #D8DCE6;
$gray_light8: #EEF1F9;
$gray_light9: #F4F7FB;


$gradient_blue: linear-gradient(180deg, #537fc5 0%, #2d44ae 100%);
$gradient_red_vert: linear-gradient(180deg, #61080c 0%, #a2171c 100%);
$gradient_gray_vert: linear-gradient(180deg, #efefef 0%, #ddd 100%);
$gradient_blue_vert: linear-gradient(180deg, #3C80C5 0%, #0043AD 100%);
$gradient_red: linear-gradient(90deg, #61080c 0%, #a2171c 100%);


