
.header2 {
  
  position: fixed;
  width: 100%;
  z-index: 1000;
  left: 0;
  top: 0;
  transition: all 0.8s;

  &.-hide {
    top: -20vw;
  }
  &.-white {
    background: #fff;
  }

  &__inner {
    padding: vw(30, $pc_w);
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include mq(sp) {
      padding: vw(15, $sp_w);
    }
  }
  &__logo {
    width: vw(108, $pc_w);
    @include mq(sp) {
      width: vw(53, $sp_w);
    }
  }

  .right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__nav {
    @include mq(sp) {
      display: none;
    }
  }

  /* spは別に記載 */
  .menu-list {
    display: flex;
    &__item {
    }

    &__link {
      font-family: $font_en;
      font-weight: 700;
      font-size: vw(17, $pc_w);
      color: $black;
      margin-left: vw(40, $pc_w);
      position: relative;
      &.-opened {
        color: $yblue;
      }
      &:hover {
        color: $yblue;
      }
    }
  }

  .submenu-list {
    display: none;
    width: vw(230, $pc_w);
    box-sizing: border-box;
    padding: vw(20, $pc_w) vw(30, $pc_w);
    background: $yblue;
    position: absolute;
    top: 2vw;
    left: 1vw;
    border-radius: vw(15, $pc_w);
    overflow: hidden;
    z-index: 1001;

    &.-show {
      display: block;
    }

    &__item {
    }
    &__link {
      display: block;
      padding: vw(14, $pc_w) 0;
      color: $white;
      font-weight: bold;
      font-size: vw(14, $pc_w);
      font-weight: 700;
      position: relative;
      width: 100%;
      box-sizing: border-box;
      letter-spacing: 0.05em;
      &:after {
        content: "";
        border-top: 1px solid $white;
        border-right: 1px solid $white;
        width: vw(8, $pc_w);
        height: vw(8, $pc_w);
        position: absolute;
        top: 40%;
        right: 0;
        transform: rotate(45deg);
      }
      &.-ext {
        &:after {
          content: "";
          width: vw(9, $pc_w);
          height: vw(9, $pc_w);
          border: none;
          background-image: url(/images/icon/ico_extlink.svg);
          background-repeat: no-repeat;
          background-position: center center;
          background-size: contain;
          position: absolute;
          top: 40%;
          right: 0;
          transform: rotate(45deg);
        }
      }
      &:hover {
        &:after {
          animation: submenu_move 0.4s ease-out 0s forwards;
        }
      }
    }
  }

  &__lang {
    .lang-list {
      display: flex;
      margin-left: vw(40, $pc_w);
      @include mq(sp) {
        margin-left: vw(15, $sp_w);
      }
    }

    &--inner {
    }
  }

  .lang-list {
    display: flex;
    border-radius: vw(5, $pc_w);
    overflow: hidden;
    background: $gray_main;
    @include mq(sp) {
      border-radius: vw(5, $sp_w);
      margin-right: vw(15, $sp_w);
    }

    &__link {
      display: block;
      box-sizing: border-box;
      padding: vw(10, $pc_w) vw(16, $pc_w);
      color: $white;
      opacity: 0.5;
      font-family: $font_en;
      font-weight: 700;
      font-size: vw(16, $pc_w);
      @include mq(sp) {
        padding: vw(6, $sp_w) vw(10, $sp_w);
        font-size: vw(11, $sp_w);
      }
      &:hover {
        background: $gray_6;
      }

      &.-select {
        opacity: 1;
        background: $yblue;
        &:hover {
          background: $yblue;
        }
      }
    }
  }


  /* sp menu button */
  .menu-btn {
    display: none;
    @include mq(sp) {
      display: block;
      width: vw(16, $sp_w);
    }
  }
}

.sp-menu-bg {
  display: none;
  @include mq(sp) {
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    background: $yblue;
    width: 100%;
    height: 100vh;
    border-radius: 20vw;
    z-index: 1000;
    /* transform: scale(0.1, 0.05); */
    transform: scale(0, 0);
    transform-origin: 97vw 2vw;
    pointer-events: none;
  }
  &.-opened {
    @include mq(sp) {
      animation: sp_menu_bg_open 1s cubic-bezier(0.23,1,0.32,1) 0s forwards;
    }
  }
  &.-remain-open {
    @include mq(sp) {
      border-radius: 0;
      transform: scale(1, 1);
      transform-origin: 97vw 2vw;
    }
  }
  &.-closed {
    @include mq(sp) {
      animation: sp_menu_bg_open 1s cubic-bezier(0.23,1,0.32,1) 0s reverse forwards;
    }
  }
}

.sp-menu {
  display: none;

  @include mq(sp) {
    display: block;
    position: fixed;
    left: 0;
    top: -150vh;
    width: 100%;
    min-height: 100vh;
    height: 100%;
    overflow-y: scroll;
    box-sizing: border-box;
    z-index: 1001;
    opacity: 0;
    /* transition: all 1s cubic-bezier(0.23,1,0.32,1) 0s */
  }
  &.-opened {
    @include mq(sp) {
      animation: sp_menu_open 0.5s cubic-bezier(0.23,1,0.32,1) 0.5s forwards;
      /*
      right: 0;
      top: 0;
      */
    }
  }
  &.-remain-open {
    @include mq(sp) {
      top: 0;
      opacity: 1;
    }
  }
  &.-closed {
    @include mq(sp) {
      animation: sp_menu_open 1s cubic-bezier(0.23,1,0.32,1) 0s reverse forwards;
    }
  }

  .inner {
    padding: vw(15, $sp_w);
    min-height: 100vh;
    box-sizing: border-box;
    background: $yblue;
    color: $white;
    overflow-y: scroll;

    .menu-btn {
      display: flex;
      justify-content: flex-end;
    }
  }
  .menu {
    padding: vw(30, $sp_w) 0 0;
  }
  .menu-list {
    display: block;

    &__item {
      margin-left: 0;
    }
    &__link {
      display: block;
      padding: vw(12, $sp_w) 0;
      margin-left: 0;
      font-family: $font_en;
      font-weight: 700;
      font-size: vw(24, $sp_w);
      color: $white;
      position: relative;
      &:hover {
        opacity: 1;
      }
      &:after {
        content: "";
        border-right: 2px solid $white;
        border-bottom: 2px solid $white;
        border-top: none;
        border-left: none;
        width: vw(10, $sp_w);
        height: vw(10, $sp_w);
        position: absolute;
        right: vw(10, $sp_w);;
        top: 35%;
        transform: rotate(45deg);
      }
      &.-opened {
        &:after {
          content: "";
          border-top: 2px solid $white;
          border-left: 2px solid $white;
          border-right: none;
          border-bottom: none;
          width: vw(10, $sp_w);
          height: vw(10, $sp_w);
          position: absolute;
          right: vw(10, $sp_w);;
          top: 45%;
          transform: rotate(45deg);
        }
      }
    }
    
  }
  .submenu-list {
    display: none;
    padding: vw(10, $sp_w) 0;

    &__item {
      margin-bottom: vw(25, $sp_w);
    }
    &__link {
      color: $white;
      font-weight: vw(14, $sp_w);
      font-weight: 700;
      display: inline-block;
      position: relative;
      padding-right: vw(15, $sp_w);
      letter-spacing: 0.05em;
      &:hover {
        @include mq(sp) {
          opacity: 1;
        }
      }
      &:after {
        content: "";
        width: vw(7, $sp_w);
        height: vw(7, $sp_w);
        border-top: 1px solid $white;
        border-right: 1px solid $white;
        position: absolute;
        right: 0;
        top: 30%;
        transform: rotate(45deg);
      }
      &.-ext {
        &:after {
          content: "";
          width: vw(8, $sp_w);
          height: vw(8, $sp_w);
          border: none;
          background-image: url(/images/icon/ico_extlink.svg);
          background-repeat: no-repeat;
          background-position: center center;
          background-size: contain;
          position: absolute;
          right: 0;
          top: 25%;
          transform: rotate(45deg);

        }
      }
    }
  }

  .sns {
    margin-top: vw(30, $sp_w);
  }
  .sns-list {
    display: flex;
    align-items: center;

    &__item {
      width: vw(37, $sp_w);
      margin-right: vw(15, $sp_w);

      &:first-of-type {
        width: vw(30, $sp_w);
      }
    }
  }
  .info {
    margin-top: vw(25, $sp_w);
    padding-bottom: vw(80, $sp_w);

    &-list {
      display: flex;
      flex-wrap: wrap;
      &__item {
        margin-right: vw(15, $sp_w);
        margin-bottom: vw(20, $sp_w);
        &:last-child {
          margin-right: 0;
        }
      }
      &__link {
        color: $white;
      }
    }
  }

}

@keyframes sp_menu_open {
  0% {
    opacity: 0;
    top: 0;
  }
  100% {
    top: 0;
    opacity: 1;
  }
}
@keyframes sp_menu_close {
  0% {
  }
  100% {
  }
}

@keyframes sp_menu_bg_open {
  0% {
    border-radius: 20vw;
    transform: scale(0, 0);
    transform-origin: 97vw 2vw;
  }
  20% {
    border-radius: 20vw;
  }
  100% {
    border-radius: 0;
    transform: scale(1, 1);
    transform-origin: 97vw 2vw;
  }
}

@keyframes submenu_move {
  0% {
    right: 0;
  }
  50% {
    right: -0.25vw;
  }
  100% {
    right: 0;
  }
}
