
.footer2 {
  background: $black;
  color: $white;

  &__inner {
    padding: vw(100, $pc_w) vw(160, $pc_w);
    @include mq(sp) {
      padding: vw(50, $sp_w) vw(15, $sp_w);
    }
  }
  &__upper {
    display: flex;
    justify-content: space-between;
    @include mq(sp) {
      flex-direction: column;
    }
    
    .left {
      width: vw(204, $pc_w);
      box-sizing: border-box;
      @include mq(sp) {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .btn-to-top {
        display: none;
        @include mq(sp) {
          display: block;
        }
      }

    }
    .right {
      width: 100%;
    }
    .logo {
      width: vw(205, $pc_w);
      display: flex;
      justify-content: stretch;
      @include mq(sp) {
        width: vw(65, $sp_w);
      }
      &__text {
        font-weight: 700;
        font-size: vw(30.5, $pc_w);
        font-family: $font_en;
        margin-top: vw(38, $pc_w);
        @include mq(sp) {
          display: none;
        }
      }
    }
  }
  &__lower {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: vw(30, $pc_w);
    @include mq(sp) {
      flex-direction: column;
      align-items: flex-start;
    }

    .left {
      width: 50%;
      @include mq(sp) {
        width: 100%;
      }
    }
    .right {
      @include mq(sp) {
        width: 100%;
      }
    }

    .sns {
      @include mq(sp) {
        margin-top: vw(20, $sp_w);
      }
      &-list {
        display: flex;
        align-items: center;
          flex-wrap: nowrap;

        &__item {
          box-sizing: border-box;
          width: vw(30, $pc_w);
          margin-right: vw(12, $pc_w);
          @include mq(sp) {
            width: vw(37, $sp_w);
            margin-right: vw(15, $sp_w);
          }
          &:first-of-type {
            width: vw(25, $pc_w);
            @include mq(sp) {
              width: vw(32, $sp_w);
            }
          }
        }
      }
    }
    .info {
      @include mq(sp) {
        margin-top: vw(15, $sp_w);
        padding-bottom: vw(30, $sp_w);
      }
      &-list {
        display: flex;
        margin-top: vw(15, $pc_w);
        @include mq(sp) {
          margin-top: 0;
          flex-wrap: wrap;
        }

        &__item {
          margin-right: vw(20, $pc_w);
          @include mq(sp) {
            margin-right: vw(15, $sp_w);
            margin-bottom: vw(20, $sp_w);
            &:last-child {
              margin-right: 0;
            }
          }
        }
        &__link {
          color: $white;
          font-size: vw(14, $pc_w);
          font-weight: 700;
          @include mq(sp) {
            font-size: vw(12, $sp_w);
          }
        }
      }
    }
    .copyright {
      color: $gray_main2;
      font-size: vw(12, $pc_w);
      margin-top: vw(15, $pc_w);
    }
    .sign {
      width: vw(358, $pc_w);
      @include mq(sp) {
        width: 100%;
        margin-top: vw(10, $sp_w);
      }
      &__image {
        @include mq(sp) {
          opacity: 0.4;
          width: 100%;
        }
      }
    }
    .logo {
      @include mq(sp) {
        display: flex;
        align-items: center;
        margin-top: vw(40, $sp_w);
      }
      &__image {
        @include mq(sp) {
          margin-right: vw(10, $sp_w);
          width: vw(65, $sp_w);
        }
      }
      i__text {
        @include mq(sp) {
          font-weight: 700;
          font-family: $font_en;
          font-size: vw(9, $sp_w);
          color: $white;
        }
      }
    }
    .copyright {
      @include mq(sp) {
        font-size: vw(10, $sp_w);
        color: $gray_main;
        marin-top: vw(10, $sp_w);
      }
    }

  }
  //lower

  &__nav {
    .menu-list {
      display: flex;
      justify-content: flex-end;
      @include mq(sp) {
        justify-content: flex-start;
        flex-direction: column;
        margin-top: vw(20, $sp_w);
      }

      &__item {
        margin-left: vw(100, $pc_w);
        @include mq(sp) {
          margin-left: 0;
        }
        &:first-child {
          margin-left: 0;
        }
      }
      &__link {
        color: $white;
        font-family: $font_en;
        font-weight: 700;
        font-size: vw(24, $pc_w);
        letter-spacing: 0.05em;
        &:active,
        &:hover {
          opacity: 1;
        }

        @include mq(sp) {
          display: block;
          padding: vw(10, $sp_w) 0;
          margin-left: 0;
          font-family: $font_en;
          font-weight: 700;
          font-size: vw(24, $sp_w);
          color: $white;
          position: relative;
          &:after {
            content: "";
            border-right: 2px solid $white;
            border-bottom: 2px solid $white;
            border-top: none;
            border-left: none;
            width: vw(10, $sp_w);
            height: vw(10, $sp_w);
            position: absolute;
            right: vw(10, $sp_w);;
            top: 35%;
            transform: rotate(45deg);
          }
          &.-opened {
            &:after {
              content: "";
              border-top: 2px solid $white;
              border-left: 2px solid $white;
              border-right: none;
              border-bottom: none;
              width: vw(10, $sp_w);
              height: vw(10, $sp_w);
              position: absolute;
              right: vw(10, $sp_w);;
              top: 45%;
              transform: rotate(45deg);
            }
          }
        }
      }
    }
    .submenu-list {
      margin-top: vw(25, $pc_w);

      @include mq(sp) {
        display: none;
        padding: vw(10, $sp_w) 0 vw(20, $sp_w);
      }

      &__item {
        margin-bottom: vw(18, $pc_w);
        @include mq(sp) {
          margin-bottom: vw(25, $sp_w);
        }
        &:last-child {
          margin-bottom: 0;
          @include mq(sp) {
            margin-bottom: vw(25, $sp_w);
          }
        }
      }
      &__link {
        display: block;
        font-size: vw(15, $pc_w);
        position: relative;
        color: $white;
        letter-spacing: 0.05em;
        @include mq(sp) {
          color: $white;
          font-size: vw(14, $sp_w);
          font-weight: 700;
          display: inline-block;
          position: relative;
          padding-right: vw(15, $sp_w);
        }
        &:active,
        &:hover {
          opacity: 1;
        }

        &:after {
          content: "";
          border-top: 1px solid $white;
          border-right: 1px solid $white;
          width: vw(6, $pc_w);
          height: vw(6, $pc_w);
          position: absolute;
          top: 50%;
          right: -1em;
          transform: rotate(45deg) translateY(-50%);
          @include mq(sp) {
            content: "";
            width: vw(7, $sp_w);
            height: vw(7, $sp_w);
            border-top: 1px solid $white;
            border-right: 1px solid $white;
            position: absolute;
            right: 0;
            top: 30%;
            transform: rotate(45deg);
          }
        }
        &.-ext {
          &:after {
            content: "";
            width: vw(6, $pc_w);
            height: vw(6, $pc_w);
            border: none;
            background-image: url(/images/icon/ico_extlink.svg);
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;
            position: absolute;
            top: 45%;
            right: -1em;
            transform: rotate(45deg) translateY(-50%);
            @include mq(sp) {
              content: "";
              width: vw(7, $sp_w);
              height: vw(7, $sp_w);
              position: absolute;
              right: 0;
              top: 25%;
              transform: rotate(45deg);
            }
          }
        }
        &:hover {
          &:after {
            animation: submenu_footer_move 0.4s ease-out 0s forwards;
          }
        }
      }
    }
  }
  //nav

}

@keyframes submenu_footer_move {
  0% {
    right: -1em;
  }
  50% {
    right: -1.2em;
  }
  100% {
    right: -1em;
  }
}
