
.recruit {
.main {

  .recruit-index {
    @include mq(sp) {
    }
  }
  .recruit-index-list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    //margin-bottom: vw(100, $pc_w);

    &__item {
      width: vw(378, $pc_w);
      opacity: 0;
      margin-top: 1.2em;
      transition: all 1.4s ease-out;
      &:nth-child(2) {
        transition: all 1.4s ease-out 0.2s;
      }
      &:nth-child(3) {
        transition: all 1.4s ease-out 0.4s;
      }
      &:nth-child(4) {
        transition: all 1.4s ease-out 0.6s;
      }

      @include mq(sp) {
        width: vw(165, $sp_w);
        margin-bottom: vw(20, $sp_w);
      }
      &.-anime {
        opacity: 1;
        margin-top: 0;
      }

      &--img {
        width: 100%;
      }

      &:nth-last-child(-n+2) {
        @include mq(sp) {
          margin-bottom: 0;
        }
      }
    }

    &__link {
      display: block;
      position: relative;
    }
    &__text {
      position: absolute;
      left: 50%;
      top: 50%;
      text-align: center;
      transform: translateX(-50%) translateY(-50%);
      color: $white;
      font-weight: 700;
    }
    &__ttl {
      font-size: vw(30, $pc_w);
      font-family: $font_en;
      white-space: nowrap;
      @include mq(sp) {
        font-size: vw(20, $sp_w);
      }
    }
    &__subttl {
      margin-top: vw(11, $pc_w);
      font-size: vw(16, $pc_w);
      @include mq(sp) {
        margin-top: vw(8, $sp_w);
        font-size: vw(10, $sp_w);
      }
    }
  }

  .topics {
    margin-top: vw(100, $pc_w);
    @include mq(sp) {
      margin-top: vw(50, $sp_w);
    }

    &__inner {
      @include mq(sp) {
        margin-top: vw(30, $sp_w);
      }
    }

    .section-topics__wrap {
      padding: vw(50, $pc_w) 0 0;
      @include mq(sp) {
        padding: vw(30, $sp_w) 0 0;
      }
    }

    .section-ttl__en {
      font-size: vw(50, $pc_w);
      @include mq(sp) {
        font-size: vw(28, $sp_w);
      }
    }
  }

  .topics-list {
    margin-top: vw(50, $pc_w);
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    &:after {
      content: "";
      display: block;
      width: vw(378, $pc_w);
      @include mq(sp) {
        content: none;
      }
    }
    &:before {
      content: "";
      display: block;
      width: vw(378, $pc_w);
      order: 1;
      @include mq(sp) {
        content: none;
      }
    }

    &__item {
      width: vw(378, $pc_w);
      margin-bottom: vw(50, $pc_w);
      overflow: hidden;
      @include mq(sp) {
        width: 48%;
        margin-bottom: vw(20, $sp_w);
      }
      &:nth-last-child(-n+4) {
        margin-bottom: 0;
        @include mq(sp) {
          margin-bottom: vw(20, $sp_w);
        }
      }
      &:nth-last-child(-n+2) {
        @include mq(sp) {
          margin-bottom: 0;
        }
      }
    }

    &__link {
      display: block;
      &:hover {
        .topics-list__image--img {
          transform: scale(1.05);
        }
      }
    }

    &__image {
      overflow: hidden;
      border-radius: vw(10, $pc_w);
      @include mq(sp) {
        border-radius: vw(10, $sp_w);
      }
      &--img {
        transition: transform 0.5s;
      }
    }

    &__text {
      margin-top: vw(15, $pc_w);
      @include mq(sp) {
        margin-top: vw(15, $sp_w);
      }
    }

    &__dt {
      color: $gray_main;
      font-size: vw(12, $pc_w);
      font-weight: 700;
      margin-right: vw(15, $pc_w);
      margin-top: 0;
      @include mq(sp) {
        font-size: vw(11, $sp_w);
        margin-right: vw(6, $sp_w);
      }
    }

    &__body {
      margin-top: vw(10, $pc_w);
      font-size: vw(18, $pc_w);
      line-height: 1.5;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      line-clamp: 2;
      -webkit-line-clamp: 2;

      @include mq(sp) {
        margin-top: vw(10, $sp_w);
        font-size: vw(12, $sp_w);
        letter-spacing: 0.05em;
        line-clamp: 5;
        -webkit-line-clamp: 5;
      }
    }
  }



} // main
} // recruit
