//
// ニュース詳細ページ
// indexNews__list は _page.scssにある
//

.news.lowerpage.-ja {
  main {
    .contents {
      //padding-top: 6vw;
      @include mq(sp) {
        //padding-top: 14vw;
      }
    }
  }
  .section-ttl__en {
    //font-size: vw(30, $pc_w);
    //@include mq(sp) {
    //  font-size: vw(20, $sp_w);
    //}
  }
}

.news-content {

  .section__inner {
    width: vw(900, $pc_w);
    margin: 0 auto;
    padding: 0;
    box-sizing: border-box;

    @include mq(sp) {
      width: 100%;
      padding: 0 vw(15, $sp_w);
    }
  }

  .news {
    &__kv {

      @include mq(sp) {
        width: 100vw;
        left: 50%;
        right: 50%;
        margin-left: -50vw;
        margin-right: -50vw;
        position: relative;
      }

      img {
        width: 100%;
      }
    }
    &__text {
    }
    &__desc {
      margin: vw(28, $pc_w) 0;
      @include mq(sp) {
        margin: vw(20, $sp_w) 0;
      }
    }
    &__category {
      font-size: vw(16, $pc_w);
      font-weight: 700;
      color: $yappli_blue;
      @include mq(sp) {
        font-size: vw(12, $sp_w);
      }
    }
    &__ttl {
      margin-top: vw(15, $pc_w);
      margin-bottom: vw(15, $pc_w);
      font-size: vw(28, $pc_w);
      font-weight: 700;
      line-height: 1.5;
      @include mq(sp) {
        font-size: vw(18, $sp_w);
        margin: vw(15, $sp_w) 0 vw(15, $sp_w);
      }
    }
    &__info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      box-sizing: border-box;
      &.-bottom {
        justify-content: flex-end;
        @include mq(sp) {
          justify-content: center;
        }
      }
    }
    &__date {
      font-size: vw(20, $pc_w);
      opacity: 0.5;
      @include mq(sp) {
        font-size: vw(14, $sp_w);
      }
    }
    &__sns {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      &--ttl {
        font-family: $font_en;
        font-size: vw(20, $pc_w);
        opacity: 0.5;
        @include mq(sp) {
          font-size: vw(14, $sp_w);
        }
      }
      &--item {
        width: vw(25, $pc_w);
        margin-left: vw(10, $pc_w);
        box-sizing: border-box;
        @include mq(sp) {
          width: vw(24, $sp_w);
          margin-left: vw(5, $sp_w);
        }
      }

    }
    &__body {
      line-height: 2;
      font-size: vw(22, $pc_w);
      @include mq(sp) {
        font-size: vw(15, $sp_w);
        margin-top: vw(15, $sp_w);
        margin-bottom: vw(15, $sp_w);
      }

      h3 {
        font-weight: 700;
        font-size: vw(24, $pc_w);
        margin: vw(30, $pc_w) 0;
        @include mq(sp) {
          font-size: vw(18, $sp_w);
          margin: vw(20, $sp_w) 0;
        }
      }

      strong {
        font-weight: 700;

      }

      .cmn-section {
        @include mq(sp) {
          word-break: break-all;
        }
      }
    }
    &__comment {
      padding: vw(50, $pc_w);
      border-radius: vw(10, $pc_w);
      overflow: hidden;
      background: #F4F9FF;
      box-sizing: border-box;
      margin: vw(50, $pc_w) 0 vw(30, $pc_w);
      line-height: 2;
      font-size: vw(22, $pc_w);
      @include mq(sp) {
        padding: vw(30, $sp_w) vw(15, $sp_w);
        margin: vw(20, $sp_w) 0 vw(15, $sp_w);
        font-size: vw(16, $sp_w);
        border-radius: vw(10, $sp_w);
      }

      strong {
        font-weight: 700;
      }
    }

  }
  //news

  &__bottom {

    @include mq(sp) {
      margin-bottom: vw(30, $sp_w);
    }

    &.section {
      margin-bottom: vw(10, $pc_w);
    }

    .pickup-list {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin: vw(20, $pc_w) 0;
      @include mq(sp) {
        margin: vw(15, $sp_w) 0 vw(15, $sp_w);
      }
      
      &__item {
        width: vw(500, $pc_w);
        box-sizing: border-box;
        @include mq(sp) {
          width: vw(165, $sp_w);
        }

        img {
          width: vw(500, $pc_w);
          height: vw(263, $pc_w);
          object-position: center center;
          object-fit: cover;
          @include mq(sp) {
            width: vw(165, $sp_w);
            height: vw(86, $sp_w);
          }
        }

        // override
        .indexNews__name {
          line-clamp: 2;
          -webkit-line-clamp: 2;
          @include mq(sp) {
            line-clamp: 5;
            -webkit-line-clamp: 5;
          }
        }
      }
    }

    .news__pager {
      border-top: 1px solid rgba(8, 19, 26, 0.14);
      box-sizing: border-box;
      margin-top: vw(50, $pc_w);
      margin-bottom: vw(50, $pc_w);
    }
    .pager-list {
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      @include mq(sp) {
        flex-direction: column;
        margin-bottom: vw(30, $sp_w);
      }
      
      &__item {
        width: 50%;
        box-sizing: border-box;
        position: relative;
        @include mq(sp) {
          width: 100%;
          border-bottom: 1px solid rgba(8, 19, 26, 0.14);
        }

        &:first-child {
          &:after {
            content: "";
            height: vw(88, $pc_w);
            width: 1px;
            background: #e4e5e6;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);

            @include mq(sp) {
              content: none;
            }
          }
          &.-no-content {
            @include mq(sp) {
              border-bottom: none;
            }
          }
        }

        &:first-child {
          .pager-list__link {
            &:after {
              content: "";
              border-top: 3px solid $yappli_blue;
              border-left: 3px solid $yappli_blue;
              width: vw(15, $pc_w);
              height: vw(15, $pc_w);
              position: absolute;
              left: vw(5, $pc_w);
              top: 50%;
              transform: rotate(-45deg) translateY(-50%);

              @include mq(sp) {
                content: none;
              }
            }
          }
        }
        &:last-child {
          .pager-list__link {
            &:after {
              content: "";
              border-top: 3px solid $yappli_blue;
              border-right: 3px solid $yappli_blue;
              width: vw(15, $pc_w);
              height: vw(15, $pc_w);
              position: absolute;
              left: auto;
              right: vw(5, $pc_w);
              top: 50%;
              transform: rotate(45deg) translateY(-50%);

              @include mq(sp) {
                content: none;
              }
            }
          }
        }
      }
      //pager-list__item
      &__link {
        display: block;
        box-sizing: border-box;
        padding: vw(50, $pc_w);
        box-sizing: border-box;
        position: relative;
        @include mq(sp) {
          padding: vw(15, $sp_w) 0;
        }

        &--wrap {
          @include mq(sp) {
            display: flex;
            width: 100%;
          }
          &.-next {
            justify-content: flex-end;
          }
        }
          
        &--ttl {
          color: $yappli_blue;
          font-size: vw(20, $pc_w);
          font-weight: 700;
          margin-bottom: vw(20, $pc_w);
          @include mq(sp) {
            font-size: vw(12, $sp_w);
            margin-bottom: vw(5, $sp_w);
            position: relative;
          }
          &.-prev {
            @include mq(sp) {
              padding-left: vw(17, $sp_w);
            }
            &:after {
              @include mq(sp) {
                content: "";
                border-top: 2px solid $yappli_blue;
                border-left: 2px solid $yappli_blue;
                width: vw(8, $sp_w);
                height: vw(8, $sp_w);
                position: absolute;
                left: vw(8, $sp_w);
                top: 50%;
                transform-origin: bottom left;
                transform: rotate(-45deg) translateY(-50%);
              }
            }
          }
          &.-next {
            @include mq(sp) {
              padding-right: vw(17, $sp_w);
            }
            &:after {
              @include mq(sp) {
                content: "";
                border-top: 2px solid $yappli_blue;
                border-right: 2px solid $yappli_blue;
                width: vw(8, $sp_w);
                height: vw(8, $sp_w);
                position: absolute;
                left: auto;
                right: vw(8, $sp_w);
                top: 50%;
                transform-origin: bottom right;
                transform: rotate(45deg) translateY(-50%);
              }
            }
          }
        }
        &--body {
          line-height: 1.5;
          font-size: vw(20, $pc_w);

          display: -webkit-box;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-box-orient: vertical;
          line-clamp: 2;
          -webkit-line-clamp: 2;
          min-height: 3em;

          @include mq(sp) {
            font-size: vw(13, $sp_w);
          }
        }
      }
    }

  }
  //news-content__bottom

}

.news-page {
  &__tags {
    &--wrap {
      display: flex;
      align-items: center;
      margin-left: vw(30, $pc_w);
      margin-top: -1.5vw;
      padding-bottom: vw(40, $pc_w);
      @include mq(sp) {
        align-items: flex-start;
        flex-wrap: wrap;
        margin-top: 0;
        margin-left: 0;
        padding-left: vw(8, $sp_w);
        padding-bottom: vw(10, $sp_w);
      }
    }

    &--item {
      margin-right: vw(50, $pc_w);
      @include mq(sp) {
        margin-right: vw(30, $sp_w);
      }

      label {
        font-weight: 700;
        font-size: 16px;
        @include mq(sp) {
          font-size: vw(14, $sp_w);
        }
      }

      &:first-of-type {
        @include mq(sp) {
          margin-bottom: vw(15, $sp_w);
        }
      }
    }

    &--radio {
      appearance: none;

      &:checked {
        &+.news-page__tags--text:after {
          content: '';
          display: block;
          border-radius: 50%;
          width: vw(18, $pc_w);
          height: vw(18, $pc_w);
          background-color: $yappli_blue;
          position: absolute;
          left: vw(-25, $pc_w);
          top: 50%;
          transform: translateY(-50%);
          @include mq(sp) {
            width: vw(14, $sp_w);
            height: vw(14, $sp_w);
            left: vw(-2.5, $sp_w);
          }
        }
      }
    }
    &--text {
      padding-left: vw(5, $pc_w);
      position: relative;
      display: flex;
      align-items: center;

      @include mq(sp) {
        white-space: nowrap;
        padding-left: vw(24, $sp_w);
      }

      &:before {
        content: '';
        display: block;
        border-radius: 50%;
        border: 1px solid $yappli_blue;
        width: vw(24, $pc_w);
        height: vw(24, $pc_w);
        position: absolute;
        left: vw(-28, $pc_w);
        top: 50%;
        transform: translateY(-50%);

        @include mq(sp) {
          width: vw(20, $sp_w);
          height: vw(20, $sp_w);
        }
      }
    }
  }
}


blockquote {
  box-sizing: border-box;
  padding: vw(50, $pc_w);
  border-radius: vw(10, $pc_w);
  background: #F4F9FF;
  @include mq(sp) {
    padding: vw(30, $sp_w), vw(15, $sp_w);
    border-radius: vw(10, $sp_w);
  }
}


