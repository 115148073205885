
// company {{{
.company {

  .mv {
    box-sizing: border-box;
    padding: vw(60, $pc_w) 0;
    border-radius: vw(30, $pc_w);
    overflow: hidden;

    @include mq(sp) {
      padding: vw(15, $sp_w) 0 vw(10, $sp_w);
      border-radius: vw(10, $sp_w);
    }
  }

  .company {

    &__info {
      box-sizing: border-box;
      padding: 0 vw(270, $pc_w);

      @include mq(sp) {
        padding: 0;
      }

      .info-list {
        &__item {
          box-sizing: border-box;
          display: flex;
          align-items: center;
          padding: vw(30, $pc_w) 0;
          border-bottom: 1px solid $gray_7;
          @include mq(sp) {
            padding: vw(15, $sp_w) 0;
          }

          &:last-child {
            border: none;
          }
        }
        &__ttl {
          font-weight: 700;
          font-size: vw(20, $pc_w);
          margin-right: vw(30, $pc_w);
          width: vw(110, $pc_w);
          box-sizing: border-box;
          letter-spacing: 0.05em;
          @include mq(sp) {
            font-size: vw(12, $sp_w);
            margin-right: vw(10, $sp_w);
            width: vw(60, $sp_w);
          }
        }
        &__body {
          font-size: vw(20, $pc_w);
          line-height: 1.5;
          letter-spacing: 0.05em;
          @include mq(sp) {
            font-size: vw(12, $sp_w);
          }
        }
      }
    }
    //info

    &__map {
      margin-top: vw(60, $pc_w);
      @include mq(sp) {
        margin-top: vw(25, $sp_w);
      }

      .map-list {
        display: flex;
        justify-content: space-between;
        @include mq(sp) {
          flex-direction: column;
        }
        a {
          &:hover {
            opacity: 1;
          }
        }

        &__item {
          width: 32%;
          box-sizing: border-box;
          @include mq(sp) {
            width: 100%;
            margin-bottom: vw(15, $sp_w);
          }
          &:last-child {
            @include mq(sp) {
              margin-bottom: 0;
            }
          }
        }
        &__link {
          display: block;
          @include mq(sp) {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
        }
        &__map {
          width: 100%;
          border-radius: vw(30, $pc_w);
          overflow: hidden;
          @include mq(sp) {
            border-radius: vw(10, $sp_w);
            width: vw(80, $sp_w);
            height: vw(80, $sp_w);
            img {
              width: vw(80, $sp_w);
              height: vw(80, $sp_w);
              object-fit: cover;
              object-position: center center;
            }
          }
          &:hover {
            opacity: 1;
          }
          /*
          img {
            transition: all 0.5s;
          }
          &:hover {
            img {
              transform: scale(1.05, 1.05);
            }
          }
          */
        }
        &__ttl {
          @include mq(sp) {
            display: flex;
            align-items: center;
          }
          &--icon {
            @include mq(sp) {
              width: vw(10, $sp_w);
              margin-right: vw(5, $sp_w);
              box-sizing: border-box;
            }
          }
          &--text {
            font-family: $font_en;
            font-weight: 700;
            font-size: vw(26, $pc_w);
            color: $gray_dark9;
            @include mq(sp) {
              font-size: vw(15, $sp_w);
              color: $black;
            }
          }
        }
        &__info {
          margin-top: vw(20, $pc_w);
          @include mq(sp) {
            width: vw(218, $sp_w); 
            white-space: normal;
          }

          &--ttl {
            font-family: $font_en;
            font-weight: 700;
            font-size: vw(26, $pc_w);
            color: $gray_9;
            @include mq(sp) {
              display: flex;
              align-items: center;
            }
          }
          &--data {
            margin-top: vw(10, $pc_w);
            display: flex;
            //align-items: center;
            @include mq(sp) {
              display: block;
              align-items: center;
            }
          }
          &--icon {
            width: vw(15, $pc_w);
            margin-right: vw(8, $pc_w);
            @include mq(sp) {
              display: none;
            }
          }
          &--text {
            font-size: vw(16, $pc_w);
            line-height: 1.4;
            @include mq(sp) {
              line-height: 1.5;
              font-size: vw(12, $sp_w);
              letter-spacing: 0.05em;
            }
          }
          &--data {
            @include mq(sp) {
              margin-top: vw(5, $sp_w);
            }
          }
        }

        &__button {
          display: none;
          @include mq(sp) {
            display: block;
            width: vw(18, $sp_w);
          }
        }
        &__map {
          filter: grayscale(100%);
          height: vw(250, $pc_w);
          @include mq(sp) {
            height: vw(80.25, $sp_w);
          }
          &:hover {
            filter: grayscale(0);
          }
        }

      }
      //map-list
    }
    //map

    &__member {
      .grid {
        &__list {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          box-sizing: border-box;
          &:after {
            content: "";
            display: block;
            width: 32%;
          }
          @include mq(sp) {
          }
        }
        &__item {
          width: 32%;
          margin-top: 0;
          margin-bottom: vw(60, $pc_w);
          @include mq(sp) {
            width: 47.82%;
            margin-bottom: vw(15, $sp_w);
          }
        }
        &__thumb {
          height: vw(250, $pc_w);
          width: 100%;
          overflow: hidden;
          border-radius: vw(30, $pc_w);
          background: linear-gradient(76.69deg, #CEE1E8 0%, #EEF2F5 100%);
          box-sizing: border-box;
          @include mq(sp) {
            width: 100%;
            height: vw(165, $sp_w);
            border-radius: vw(10, $sp_w);
          }
          img {
            width: vw(514, $pc_w);
            height: vw(250, $pc_w);
            object-fit: cover;
            object-position: center center;
            &.-pos {
              object-fit: cover;
              object-position: center 40%;
            }
            @include mq(sp) {
              width: vw(165, $sp_w);
              height: vw(165, $sp_w);
              object-fit: contain;
              object-position: center center;
              &.-pos {
                width: 100%;
                object-fit: cover;
                object-position: center 40%;
              }
            }
          }
        }
      }
      //grid

      .memberinfo {
        margin-top: vw(20, $pc_w);
        line-height: 1;
        @include mq(sp) {
          margin-top: vw(15, $sp_w);
        }

        &__title,
        &__name {
          font-weight: 700;
          font-size: vw(20, $pc_w);
          letter-spacing: 0.05em;
          @include mq(sp) {
            font-size: vw(12, $sp_w);
            line-height: 1.5
          }
          .-title-small {
            @include mq(sp) {
              font-size: vw(11, $sp_w);
            }
          }
        }
        &__name {
          margin-top: vw(14, $pc_w);
          @include mq(sp) {
            margin-top: vw(5, $sp_w);
          }
          &-ja {
            font-size: vw(20, $pc_w);
            @include mq(sp) {
              font-size: vw(12, $sp_w);
            }
          }
        }
        &__summary {
          color: $black;
          font-size: vw(18, $pc_w);
          line-height: 1.5;
          margin-top: vw(10, $pc_w);
          letter-spacing: 0.05em;
          @include mq(sp) {
            font-size: vw(12, $sp_w);
            margin-top: vw(6, $sp_w);
          }
        }
      }
      //memberinfo
    }
    //member

    &__history {
      .timeline {

        width: vw(1058, $pc_w);
        margin: 0 auto;
        position: relative;

        @include mq(sp) {
          width: 100%;
        }
        &:after {
          content: "";
          width: vw(2, $pc_w);
          height: calc(100% - 3vw);
          position: absolute;
          background: $yblue;
          left: 1.4vw;
          top: vw(21, $pc_w);
          z-index: 0;

          @include mq(sp) {
            width: vw(2, $sp_w);
            top: vw(21, $sp_w);
            left: 7vw;
            height: calc(100% - 18vw);
          }
        }

        &__list {
          display: flex;
          box-sizing: border-box;
          padding: vw(20, $pc_w) 0;
          border-top: 1px solid $gray_7;
          @include mq(sp) {
            padding: vw(20, $sp_w) 0;
          }
          &:last-child {
            border-bottom: 1px solid $gray_7;
          }
        }
        &__index {
          width: vw(53, $pc_w);
          box-sizing: border-box;
          background: transparent;
          position: relative;
          padding-top: 0;
          margin-right: vw(30, $pc_w);
          @include mq(sp) {
            width: vw(54, $sp_w);
            margin-right: vw(12, $sp_w);
          }

          span {
            box-sizing: border-box;
            font-size: vw(16, $pc_w);
            color: $white;
            font-family: $font_jp;
            background: $yblue;
            display: block;
            padding: vw(10, $pc_w) 0;
            border-radius: vw(10, $pc_w);
            text-align: center;
            width: vw(53, $pc_w);
            @include mq(sp) {
              width: vw(54, $sp_w);
              padding: vw(5, $sp_w) 0;
              border-radius: vw(5, $sp_w);
              font-size: vw(12, $sp_w);
            }
          }
        }
        &__content {
          padding-top: vw(4, $pc_w);
          padding-bottom: 0;
          width: calc(100% - vw(83, $pc_w));
          box-sizing: border-box;
          align-items: flex-start;
          @include mq(sp) {
            padding-top: vw(4, $sp_w);
            width: calc(100% - vw(74, $sp_w));
          }
        }
        &__month,
        &__detail {
          padding-top: vw(40, $pc_w);
          border: none;
          @include mq(sp) {
            padding-top: vw(20, $sp_w);
          }
          &:first-of-type {
            padding-top: 0;
          }
        }
        &__month {
          width: vw(45, $pc_w);
          box-sizing: border-box;
          line-height: 1;
          @include mq(sp) {
            width: vw(30, $sp_w);
          }

          span {
            padding: 0;
            font-size: vw(20, $pc_w);
            font-weight: bold;
            line-height: 1.5;
            font-weight: 700;
            color: $black;
            @include mq(sp) {
              font-size: vw(12, $sp_w);
            }
          }
        }
        &__detail {
          box-sizing: border-box;
          width: calc(100% - vw(45, $pc_w));
          box-sizing: border-box;
          letter-spacing: 0.05em;
          @include mq(sp) {
            width: calc(100% - vw(30, $sp_w));
          }

          p {
            padding-left: vw(30, $pc_w);
            font-size: vw(20, $pc_w);
            line-height: 1.5;
            @include mq(sp) {
              padding-left: vw(10, $sp_w);
              font-size: vw(12, $sp_w);
            }
          }
        }
      }
    }
    //history
  }
  //company


  .mission {
    &__desc {
      display: flex;
      align-items: center;
      @include mq(sp) {
        flex-direction: column;
        align-items: center;
      }
    }
    &__title {
      width: vw(620, $pc_w);
      margin-right: vw(100, $pc_w);
      box-sizing: border-box;
      @include mq(sp) {
        width: vw(260, $sp_w);
        margin-right: 0;
      }
    }
    &__detail {
      
    }
    &__ttl {
      display: flex;
      align-items: center;
      @include mq(sp) {
        flex-direction: column;
      }
      &--en {
        font-family: $font_en;
        font-weight: 700;
        font-size: vw(53, $pc_w);
        margin-right: vw(21, $pc_w);
        @include mq(sp) {
          font-size: vw(28, $sp_w);
          margin-right: 0
        }
      }
      &--ja {
        font-weight: 700;
        font-size: vw(21, $pc_w);
        @include mq(sp) {
          margin-top: vw(10, $sp_w);
          font-size: vw(12, $sp_w);
        }
      }
    }
    &__body {
      margin-top: vw(25, $pc_w);
      font-size: vw(24, $pc_w);
      line-height: 1.5;
      letter-spacing: 0.05em;
      @include mq(sp) {
        margin-top: vw(15, $sp_w);
        font-size: vw(14, $sp_w);
        text-align: center;
      }
    }
  }
  // mission
}
// body.company }}}

/* missionページ {{{ */
.company.mission {

  main {
    .contents {
      @include mq(sp) {
        padding-top: 0;
      }
    }
  }

  .mission {
    &__movie {
      margin-bottom: 0;
      @include mq(sp) {
        margin-bottom: 30vw;
      }
    }
  }

  .movie {
    height: 59vw;
    overflow: hidden;
    @include mq(sp) {
      //display: none;
      height: 130vw;
    }
    video {
      height: 59vw;
      min-width: 100%;
      object-fit: cover;
      object-position: center center;
      @include mq(sp) {
        height: 130vw;
        width: 100vw;
      }
    }
  }
  .mv {
    /* companyとかぶってる */
    padding: 0;
    &__inner {
      /* position: relative; */
      padding-bottom: 0;
      border-radius: 0;
      @include mq(sp) {
        box-sizing: border-box;
      }
    }
    
    .catch {
      position: absolute;
      left: 0;
      top: 24%;
      width: 100%;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      /* transform: translate3d(-50%, 0, 0); */
      transition: all 3s cubic-bezier(0.23,1,0.32,1) 3s;
      @include mq(sp) {
        top: 20%;
        /*
        // 旧SP
        position: static;
        display: block;
        left: auto;
        top: auto;
        transition: none;
        margin-top: vw(30, $sp_w);
        overflow: hidden;
        transition: all 1s cubic-bezier(0.23,1,0.32,1) 0.5s;
        */
      }
      &.-move {
        transform: scale(0.65, 0.65);
        top: 10%;
        @include mq(sp) {
          transform: scale(0.8, 0.8);
          top: 14%;
        }
      }
      // SP用 *OBSOLETE*
      &.-anime {
        @include mq(sp) {
          margin-top: -20vw;
        }
      }

      &__inner {
        font-size: vw(128, $pc_w);
        font-weight: 700;
        text-align: center;
        /* letter-spacing: 0.2em; */
        letter-spacing: 0.05em;
        white-space: nowrap;
        opacity: 0;
        /* font-feature-settings: "palt"; */
        @include mq(sp) {
          //opacity: 1;
          font-size: vw(32, $sp_w);
          //transform: translate3d(0, 100%, 0);
        }
        &.-anime {
          @include mq(sp) {
            animation: mission_ttl 1s linear 0s forwards;
          }
        }
      }
      &__row {
        margin-bottom: vw(60, $pc_w);
        @include mq(sp) {
        }

        .char {
          opacity: 0;
          @include mq(sp) {
          }
          &.-show {
            opacity: 1;
          }
        }
      }
    }
    .message {
      position: absolute;
      left: 0;
      top: 40%;
      box-sizing: border-box;
      width: 100%;
      display: flex;
      justify-content: center;
      @include mq(sp) {
        top: 35%;
        /*
        // 旧SP
        position: static;
        left: auto;
        top: auto;
        */
      }

      &__inner {
        font-weight: 700;
        font-size: vw(32, $pc_w);
        text-align: center;
        letter-spacing: 0.05em;
        transform: translateY(20%);
        opacity: 0;
        transition: all 1s cubic-bezier(0.23,1,0.32,1) 3.5s;
        @include mq(sp) {
          font-size: vw(14, $sp_w);
          /*
          // 旧SP
          margin-top: vw(20, $sp_w);
          font-size: vw(14, $sp_w);
          opacity: 1;
          transform: none;
          transition: none;
          */
        }
        // PC動作変更分
        &.-show {
          transform: translateY(0);
          opacity: 1;
          @include mq(sp) {
            opacity: 1;
            transform: none;
          }
        }
      }
      &__row {
        margin-bottom: vw(30, $pc_w);
        @include mq(sp) {
          line-height: 2;
        }

        .char {
          opacity: 0;
          transition: opacity 0.5s linear 0s;
          @include mq(sp) {
            opacity: 1;
            transition: none;
          }
          &.-show {
            opacity: 1;
          }
        }
      }
    }
    .sp-movie {
      display: none;
      @include mq(sp) {
        display: block;
        /* position: absolute; */
        width: 100%;
        left: 0;
        padding-top: 40vw;
        transition: all 1s cubic-bezier(0.23,1,0.32,1) 0.5s;

        &.-move {
          padding-top: 20vw;
          transform: scale(0.45, 0.45);
        }
      }
      &__wrap {
        box-sizing: border-box;
        padding: 0 4vw;
      }
      .mv {
        &__copy {
          position: relative;
        }
        &__line {
          margin-bottom: vw(15, $sp_w);
        }
        &__ttl {
          &.-line1 {
            width: 90vw;
          }
          &.-line2 {
            width: 60vw;
          }
          &.-line3 {
            width: 94vw;
          }
        }
        &__text {
          &.-line1 {
            width: 90vw;
          }
          &.-line2 {
            width: 90vw;
          }
          &.-line3 {
            width: 90vw;
          }
        }
        &__copy2 {
          position: absolute;
          bottom: 19vw;
          right: 0;
        }
        &__sign {
          width: 65vw;
        }
      }
    }
  }

  .mission {
    &__body {
      letter-spacing: 0.05em;
      white-space: nowrap;
      @include mq(sp) {
        line-height: 1.75;
      }
    }
  }

  .scroll {
    &__wrap {
      margin: vw(50, $pc_w) 0 0;
      position: relative;
      /* width: 100%; */
      height: vw(450, $pc_w);
      box-sizing: border-box;
      padding-left: vw(160, $pc_w);
      @include mq(sp) {
        height: auto;
        margin: vw(50, $sp_w) auto 0;
        padding: 0 vw(15, $sp_w);
      }
    }
    &__list {
      position: absolute;
      display: flex;
      @include mq(sp) {
        position: static;
        flex-direction: column;
      }
    }
    &__item {
      width: vw(700, $pc_w);
      margin-right: vw(30, $pc_w);
      border-radius: vw(30, $pc_w);
      overflow: hidden;
      box-sizing: border-box;
      @include mq(sp) {
        width: 100%;
        margin-right: 0;
        margin-bottom: vw(15, $sp_w);
        border-radius: vw(15, $sp_w);
      }
      &.-dummy {
        width: vw(260, $pc_w);
        box-sizing: border-box;
        @include mq(sp) {
          display: none;
        }
      }
    }
    &__image {
      position: relative;
      @include mq(sp) {
        height: vw(300, $sp_w);
      }
      img {
        @include mq(sp) {
          height: 100%;
          object-fit: cover;
        }
      }
    }
    &__text {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      text-align: center;
    }
    &__value {
      width: vw(94, $pc_w);
      margin: 0 auto vw(30, $pc_w);
      @include mq(sp) {
        width: vw(70, $sp_w);
        margin: 0 auto vw(20, $sp_w);
      }
    }
    &__ttl {
      color: $yblue;
      font-weight: 700;
      font-size: vw(50, $pc_w);
      text-align: center;
      white-space: nowrap;
      letter-spacing: 0.05em;
      @include mq(sp) {
        font-size: vw(40, $sp_w);
        line-height: 1.125;
      }
    }
    &__body {
      margin-top: vw(40, $pc_w);
      font-weight: 700;
      font-size: vw(24, $pc_w);
      line-height: 1.5;
      text-align: center;
      white-space: nowrap;
      letter-spacing: 0.05em;
      @include mq(sp) {
        margin-top: vw(10, $sp_w);
        font-size: vw(14, $sp_w);
        letter-spacing: 0.07em;
      }
    }
  }
  // scroll

  .message {
    &__inner {
    }
    &__box {
      height: vw(990, $pc_w);
      width: 100%;
      background: url(/images/company/message_bg.png);
      background-repeat: no-repeat;
      background-position: left top;
      background-size: cover;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      padding: vw(50, $pc_w) 0 vw(50, $pc_w) vw(135, $pc_w);
      border-radius: vw(20, $pc_w);
      overflow: hidden;
      @include mq(sp) {
        flex-direction: column;
        background: url(/images/company/message_bg_sp.png);
        padding: vw(50, $sp_w) vw(5, $sp_w) vw(10, $sp_w) vw(30, $sp_w);
        height: vw(494, $sp_w);
        border-radius: vw(10, $sp_w);
      }

      &--left {
        width: vw(360, $pc_w);
        @include mq(sp) {
          width: 100%;
          padding-right: vw(25, $sp_w);
        }
      }
      &--right {
        box-sizing: border-box;
        width: vw(990, $pc_w);
        height: 100%;
        overflow-y: auto;
        @include mq(sp) {
          width: 100%;
          margin-top: vw(30, $sp_w);
        }
      }
    }
    &__title {
      &--en {
        font-weight: 700;
        font-size: vw(53, $pc_w);
        font-family: $font_en;
        color: $yblue;
        @include mq(sp) {
          font-size: vw(28, $sp_w);
          text-align: center;
        }
      }
      &--ja {
        font-weight: 700;
        font-size: vw(21, $pc_w);
        margin-top: vw(10, $pc_w);
        @include mq(sp) {
          font-size: vw(12, $sp_w);
          margin-top: vw(10, $sp_w);
          text-align: center;
        }
      }
    }
    &__ttl {
      font-weight: 700;
      font-size: vw(70, $pc_w);
      margin-top: vw(30, $pc_w);
      letter-spacing: 0.05em;
      white-space: nowrap;
      line-height: 1.2;
      @include mq(sp) {
        font-size: vw(25, $sp_w);
        margin-top: vw(15, $sp_w);
        text-align: center;
      }
    }
    &__body {
      font-weight: 700;
      font-size: vw(20, $pc_w);
      line-height: 2;
      box-sizing: border-box;
      padding: 0 vw(50, $pc_w) 0 vw(10, $pc_w);
      letter-spacing: 0.05em;
      @include mq(sp) {
        padding: 0 vw(25, $sp_w) 0 0;
        font-size: vw(14, $sp_w);
        }
    }
  }

  .dot-wrap {
    margin-top: vw(20, $pc_w);
    @include mq(sp) {
      margin-top: vw(20, $sp_w);
    }
  }
  .dot-list {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    margin-top: vw(40, $pc_w);
    @include mq(sp) {
      display: none;
    }

    &__item {
      box-sizing: border-box;
      display: block;
      width: vw(10, $pc_w);
      height: vw(10, $pc_w);
      border-radius: 100vw;
      background: $gray_main;
      margin: 0 vw(5, $pc_w);
      @include mq(sp) {
        width: vw(5, $sp_w);
        height: vw(5, $sp_w);
        margin: 0 vw(10, $sp_w);
      }
      &.-active {
        background: $yblue;
      }
    }
  }
}
/* body.mission }}} */

@keyframes mission_ttl {
  0% {
    color: $black;
    transform: translate3d(0,100%,0);
  }
  50% {
    transform: translate3d(0,0,0);
    color: $black;
  }
  100% {
    color: $yblue;
    transform: translate3d(0,0,0);
  }
}
