
.home {
  .main {
    margin-top: 0;
  }
}

.main {
  @include mq(sp) {
    padding-bottom: 0;
  }
}

.page {
  padding-left: 0;
  padding: 0;
}

.innerelem {
  max-width: none;
}

.pageheader__title {
  @include mq(sp) {
    bottom: 0;
  }
}

/*
body.recruit-interview,
body.recruit,
body.news,
body.ir {
  .contents {
    padding: 0 vw(160, $pc_w);
    @include mq(sp) {
      padding: 0 vw(15, $sp_w);
    }
  }
}
*/

body.ir {
  .ir-contents * {
    font-size: vw(24, $pc_w);
    @include mq(sp) {
      font-size: vw(12, $sp_w);
    }
  }
}
.ir__topInfoTxt {
	font-size: 1rem;
  @include mq(sp) {
	  font-size: 1rem;
    line-height: 1.5;
  }
}
body.sustainability {
  .para {
    line-height: 1.5;
  }
  .box-info {
    .text {
      line-height: 1.5;
    }
  }
}

body.recruit {
  .interview__title {
    font-size: vw(24, $pc_w);
    line-height: 1.5;
    @include mq(sp) {
      font-size: vw(16, $sp_w);
    }
  }
}

body.recruit-interview {
  .voice__kv-inner {
    left: 0;
    @include mq(sp) {
    }
  }
  .voice__content-inner {
    width: 60%;
    @include mq(sp) {
      width: 100%;
    }
  }

  .voice__title {
    font-size: vw(36, $pc_w);
    line-height: 1.5;
    @include mq(sp) {
      font-size: vw(20, $sp_w);
    }
  }
  .voice__name {
    @include mq(sp) {
      font-size: vw(14, $sp_w);
    }
  }
  .voice__header {
    padding-top: vw(50, $pc_w);
    @include mq(sp) {
      padding-top: vw(50, $sp_w);
    }
  }
  .tag.-jobname {
    @include mq(sp) {
      font-size: vw(12, $sp_w);
    }
  }
  .voice__profileIndex {
    line-height: 1.5;
  }
  .voice__profileDetail {
    @include mq(sp) {
      font-size: vw(12, $sp_w);
    }
  }

  .voice__qaQuestion {
    @include mq(sp) {
      font-size: vw(14, $sp_w);
      line-height: 1.5;
    }
  }
  .voice__qaTitle {
    font-size: vw(36, $pc_w);
    font-weight: 700;
    @include mq(sp) {
      font-size: vw(16, $sp_w);
      line-height: 1.5;
    }
  }
  .voice__qaText {
    font-size: vw(24, $pc_w);
    @include mq(sp) {
      font-size: vw(14, $sp_w);
    }
  }

  .pageelem__title {
    font-size: vw(32, $pc_w);
    line-height: 1.5;
    @include mq(sp) {
      font-size: vw(20, $sp_w);
    }
  }

  .pageelem__desc {
    font-size: vw(24, $pc_w);
    @include mq(sp) {
      font-size: vw(14, $sp_w);
    }
  }
}

body.recruit-interview {
  font-size: vw(14, $pc_w);
  @include mq(sp) {
    font-size: vw(14, $sp_w);
  }
  .voice__qaQuestion {
    font-size: vw(26, $pc_w);
    @include mq(sp) {
      font-size: vw(16, $sp_w);
    }
  }
  .voice__qaTitle {
    font-size: vw(26, $pc_w);
    @include mq(sp) {
      font-size: vw(16, $sp_w);
    }
  }
  .tag.-jobname {
    font-size: vw(16, $pc_w);
    @include mq(sp) {
      font-size: vw(10, $sp_w);
    }
  }
}

/* リクルートの青文字 */
.linkPanel__header .rec__title-en {
  font-size: vw(36, $pc_w);
  @include mq(sp) {
    font-size: vw(16, $sp_w);
  }
}
.rec__title-ja {
	font-size: 1rem;
  @include mq(sp) {
    font-size: 0.75rem;
  }
}
.-small .menuCard__name {
  font-size: 1rem;
}

.bradcrumb__item-text a,
.topicpath__item-text a {
  color: initial;
}

.topicpath__item-text::after {
  content: none;
}

.graph-caption {
  sup {
    top: auto;
  }
  span {
    white-space: nowrap;
  }
  .item {
    margin-bottom: 1em;
    @include mq(sp) {
      line-height: 1.5;
    }
  }
}
.graph-note {
  @include mq(sp) {
    line-height: 1.5;
  }
}

/* プレスリリースなどのタイトル */
.pageelem__title {
	font-size: 1.4rem;
  @include mq(sp) {
    font-size: 1rem;
  }
}

.indexNews__header {
  @include mq(sp) {
	  margin-top: 0.85714em;
  }
}

/* もっと読む */
.indexNews__btn .btn__text {
	font-size: 1.2rem;
  @include mq(sp) {
    font-size: 1rem;
  }
}
.rec__more span {
  @include mq(sp) {
    font-size: 0.75rem;
  }
}
/* ir */
.ir-contents  {
  .s_yearController {
    font-size: 1rem;
  }
}

.eir_pdfNote_item {
  img {
    @include mq(sp) {
      width: 40vw;
    }
  }
}
.pnexIcon,
.eir_pnexNote_item-right {
  display: flex;
  justify-content: flex-end;
}
body.financial-highlights {
  line-height: 1.5;
}
.ir__stockQuoteBtn {
  width: vw(250, $pc_w);
  @include mq(sp) {
    width: vw(120, $sp_w);
  }
  img {
  }
}

body.recruit-benefit {
  .benefit__name {
    font-size: 1rem;
    font-weight: 700;
  }
  .benefit__summary {
    font-size: 0.8rem;
  }
}

.cards__comment-ttl {
  font-size: 1rem;
}
.cards__comment-more, .cards__comment-summary {
  @include mq(sp) {
    font-size: 0.75rem;
  }
}
.cards__filter-btn {
  @include mq(sp) {
    font-size: 1rem;
  }
}

.-benefit-line {
  display: inline-block;
  vertical-align: bottom;
}
/* sustainability */
.main section.innerelem .section-ttl {
  @include mq(sp) {
    font-size: 1.2rem;
    line-height: 1.5;
  }
}
.main section.innerelem .sub-ttl {
  @include mq(sp) {
    font-size: 1rem;
  }
}
body.sustainability .para {
  @include mq(sp) {
    font-size: 0.8rem;
    line-height: 1.5;
  }
}
.sustainability__table {
  table {
    @include mq(sp) {
      font-size: 0.75rem;
    }
    tbody {
      td {
        &:first-child {
          @include mq(sp) {
            vertical-align: middle;
          }
        }
      }
    }
  }
}


