
// breakpoint
$breakpoint: (
  pc: 'screen and (min-width: 901px)',
  sp: 'screen and (max-width: 900px)'
);

@mixin mq($bp) {
  @media #{map-get($breakpoint, $bp)} {
   @content;
  }
}

