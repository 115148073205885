
body {
  &.-fixed {
    // ハンバーガーメニュー表示
    @include mq(sp) {
      width: 100%;
      height: 100%;
      position: fixed;
    }
  }
}


main {
  .contents {
    padding-top: 8vw;
    padding-bottom: 0;
    @include mq(sp) {
      padding-top: vw(60, $sp_w);
    }
  }
}

.page {

  &-ttl {
    &__wrap {
      overflow: hidden;
    }
    &__en {
      font-family: $font_en;
      font-size: vw(150, $pc_w);
      font-weight: 700;
      color: $black;
      opacity: 0;
      @include mq(sp) {
        font-size: vw(45, $sp_w);
      }
      &.-anime {
        /* animation: "page_ttl_en" 3s cubic-bezier(0.23,1,0.32,1) 0s forwards; */
        animation: "page_ttl_en" 1.2s linear 0s forwards;
      }
    }
    &__ja {
      font-size: vw(25, $pc_w);
      font-weight: 700;
      color: $black;
      opacity: 0;
      /* transition: all 1s cubic-bezier(0.23,1,0.32,1) 0.5s; */
      transition: all 1.5s linear 0.5s;
      @include mq(sp) {
        font-size: vw(14, $sp_w);
        margin-top: vw(15, $sp_w);
      }
      &.-anime {
        opacity: 1;
      }
    }
  }

  // /news/ 
  .indexNews__list {
    &.news.press_release.-ja {
      flex-wrap: wrap;

      .indexNews__item  {
        width: vw(378, $pc_w);
        margin-bottom: vw(50, $pc_w);
        @include mq(sp) {
          width: 48%;
          margin-bottom: vw(40, $sp_w);
        }
      }
    }
  }
  .news-link {
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    margin-top: vw(30, $pc_w);
    @include mq(sp) {
      margin-top: vw(20, $sp_w);
    }
    
    &__anchor {
      box-sizing: border-box;
      background: #E0E3EA;
      color: #000;
      position: relative;
      border-radius: vw(8, $pc_w);
      padding: vw(10, $pc_w) vw(40, $pc_w) vw(10, $pc_w) vw(30, $pc_w);
      text-align: center;
      font-size: vw(14, $pc_w);
      text-align: center;
      font-weight: 700;
      @include mq(sp) {
        font-size: vw(14, $sp_w);
        padding: vw(8, $sp_w) vw(30, $sp_w) vw(8, $sp_w) vw(20, $sp_w);
        border-radius: vw(8, $sp_w);
      }

      &:after {
        content: "";
        border-top: 1px solid #000;
        border-right: 1px solid #000;
        width: vw(8, $pc_w);
        height: vw(8, $pc_w);
        position: absolute;
        right: vw(30, $pc_w);
        top: 43%;
        transform: rotate(45deg) translateY(-50%);
        @include mq(sp) {
          width: vw(8, $sp_w);
          height: vw(8, $sp_w);
          right: vw(20, $sp_w);
        }

      }
    }
  }

  .pager {
    .pager-block {
      //margin: vw(50, $pc_w) auto vw(70, $pc_w);
      margin: vw(10, $pc_w) auto 0;
      @include mq(sp) {
        width: 100%;
        margin: vw(30, $sp_w) auto vw(50, $sp_w);
      }

      .paging {
        display: flex;
        justify-content: center;

        .item {
          margin: 0 vw(15, $pc_w);
          @include mq(sp) {
            margin: 0 vw(8, $sp_w);
          }

          a {
            display: block;
            background: #F9FAFB;
            color: #132638;
            box-sizing: border-box;
            width: vw(50, $pc_w);
            height: vw(50, $pc_w);
            border-radius: vw(5, $pc_w);
            filter: drop-shadow(0 1px 1px rgba(0,0,0,0.3));
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 500;
            font-size: vw(20, $pc_w);
            font-family: $font_en;
            transform: translateZ(0);
            @include mq(sp) {
              //width: vw(50, $sp_w);
              width: vw(34, $sp_w);
              height: vw(50, $sp_w);
              font-size: vw(16, $sp_w);
              border-radius: vw(5, $sp_w);
            }
            &.-recruit {
              @include mq(sp) {
                width: vw(50, $sp_w);
              }
            }

            &.previous-link {
              position: relative;

              &:after {
                content: "";
                display: block;
                border-top: 2px solid #132638;
                border-left: 2px solid #132638;
                width: vw(10, $pc_w);
                height: vw(10, $pc_w);
                position: absolute;
                left: 60%;
                top: 40%;
                transform: rotate(-45deg) translate(-50%,-50%);
                @include mq(sp) {
                  width: vw(10, $sp_w);
                  height: vw(10, $sp_w);
                }
              }
              &.-no-page {
                &:after {
                  border-top: 2px solid $gray_6;
                  border-left: 2px solid $gray_6;
                }
              }
            }
            //previous-link

            &.next-link {
              position: relative;

              &:after {
                content: "";
                display: block;
                border-top: 2px solid #132638;
                border-right: 2px solid #132638;
                width: vw(10, $pc_w);
                height: vw(10, $pc_w);
                position: absolute;
                left: 40%;
                top: 55%;
                transform: rotate(45deg) translate(-50%,-50%);
                @include mq(sp) {
                  width: vw(10, $sp_w);
                  height: vw(10, $sp_w);
                }
              }
              &.-no-page {
                &:after {
                  border-top: 2px solid $gray_6;
                  border-right: 2px solid $gray_6;
                }
              }
            }
            //next-link
          }
          //a

          &.current {
            a {
              //background: transparent;
              //filter: none;
              color: $yappli_blue;
              cursor: default;
              font-weight: 700;
            }
          }
        }
        //item
      }
      //paging
    }
    //pager-block
  }
  //pager

}
//page

@keyframes page_ttl_en {
  0% {
    opacity: 0;
    transform: translate3d(0,100%,0);
  }
  60% {
    opacity: 1;
    transform: translate3d(0,0,0);
    color: $black;
  }
  100% {
    transform: translate3d(0,0,0);
    opacity: 1;
    color: $yblue;
  }
}


.section {
  margin-bottom: vw(170, $pc_w);
  @include mq(sp) {
    margin-bottom: vw(80, $sp_w);
  }
  &.-header {
    margin-bottom: vw(100, $pc_w);
    @include mq(sp) {
      margin-bottom: vw(40, $sp_w);
    }

    &.-news {
      margin-bottom: vw(60, $pc_w);
    }
    &.-index {
      margin-bottom: vw(80, $pc_w);
      @include mq(sp) {
        margin-bottom: vw(25, $sp_w);
      }
    }
  }

  &__inner {
    padding: 0 vw(160, $pc_w);
    @include mq(sp) {
      padding: 0 vw(15, $sp_w);
    }
  }

  &-ttl {
    box-sizing: border-box;

    &__wrap {
      box-sizing: border-box;
      display: flex;
      align-items: center;
      overflow: hidden;
      @include mq(sp) {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    &__en {
      color: $yblue;
      font-family: $font_en;
      font-weight: 700;
      font-size: vw(50, $pc_w);
      opacity: 0;
      transform: translate3d(0,100%,0);
      @include mq(sp) {
        font-size: vw(28, $sp_w);
      }
      &.-anime {
        animation: "page_ttl_en" 1.2s linear 0s forwards;
      }

      &.-show {
        opacity: 1;
        transform: none;
      }
    }
    &__ja {
      margin-left: vw(20, $pc_w);
      color: $black;
      font-weight: 700;
      font-size: vw(20, $pc_w);
      opacity: 0;
      transition: all 2s linear 1s;
      @include mq(sp) {
        font-size: vw(12, $sp_w);
        margin-left: 0;
        margin-top: vw(10, $sp_w);
      }
      &.-anime {
        opacity: 1;
      }
    }
  }

  &__service {
    position: relative;
    .info-box {
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;;
      width: 100%;
      margin-top: vw(20, $pc_w);
      position: relative;
      z-index: 1;
      @include mq(sp) {
        flex-direction: column;
        margin-top: vw(15, $sp_w);
      }
      &:after {
        content: "";
        background: url(/images/type/service.png) no-repeat center center;
        width: 86.82vw;
        height: 16.14vw;
        background-size: contain;
        position: absolute;
        right: -100vw;
        top: 28%;
        z-index: -1;
        transition: all 0.5s linear 0s;
        pointer-events: none;
        @include mq(sp) {
          content: "";
          background: url(/images/type/service.png) no-repeat center center;
          width: 92vw;
          background-size: contain;
          position: absolute;
          right: -100vw;
          top: 50%;
          z-index: -1;
          transition: all 0.5s linear 0s;
          pointer-events: none;
        }
      }
      &.-anime {
      //&:hover {
        &:after {
          right: 0;
          top: 28%;
          @include mq(sp) {
            right: 0;
            top: 50%;
          }
        }
      }

      .item {
        //width: vw(786, $pc_w);
        //width: vw(454, $pc_w);
        width: 26.5vw;
        height: vw(681, $pc_w);
        box-sizing: border-box;
        display: flex;
        justiy-content: center;
        align-items: center;
        /* background: $yblue_9; */
        border-radius: vw(20, $pc_w);
        @include mq(sp) {
          border-radius: vw(10, $sp_w);
          padding-bottom: 5vw;
        }

        &:hover {
          background: rgba(0, 169, 224, 0.05);
        }

        @include mq(sp) {
          width: 100%;
          height: auto;
          margin-bottom: vw(40, $sp_w);
        }
        &:last-child {
          @include mq(sp) {
            margin-bottom: 0;
          }
        }


        &__inner {
          a {
            img {
              transform: scale(1.2);
              @include mq(sp) {
                transform: none;
              }
            }
          }
        }
        &__link {
          &:hover {
            opacity: 1;
          }
        }

        .text-wrap {
          text-align: center;
        }
        .logo {
          width: vw(82, $pc_w);
          margin: 0 auto;
          @include mq(sp) {
            width: vw(82, $sp_w);
          }
          &.-crm {
            width: vw(140, $pc_w);
            @include mq(sp) {
              width: vw(140, $sp_w);
            }
          }
        }
        .ttl {
          font-weight: 700;
          color: $black;
          font-size: vw(24, $pc_w);
          margin-top: vw(12, $pc_w);
          letter-spacing: 0.05em;
          @include mq(sp) {
            font-size: vw(18, $sp_w);
            margin-top: vw(12, $sp_w);
            letter-spacing: 0.08em;
          }
        }
        .body {
          color: $black;
          font-size: vw(18, $pc_w);
          line-height: 1.5;
          margin-top: vw(20, $pc_w);
          letter-spacing: 0.05em;
          padding: 0 vw(20, $pc_w);
          @include mq(sp) {
            font-size: vw(14, $sp_w);
            margin-top: vw(10, $sp_w);
            letter-spacing: 0.08em;
            padding: 0;
          }
        }
        .button {
          display: none;
          @include mq(sp) {
            display: inline-block;
            width: vw(28, $sp_w);
            margin-top: vw(10, $sp_w);
          }
        }
      }
      //info-box item
    }
  }
  //service



}
//section

.bradcrumb,
.topicpath {
  /* section__innerと同じ */
  padding: 0 vw(160, $pc_w);
  @include mq(sp) {
    display: block;
    padding: 0;
  }

  &__inner {
    background: #F4F9FF;
    border-radius: vw(10, $pc_w);
    overflow: hidden;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    margin: 0;
    padding: 0;
    height: vw(50, $pc_w);
    max-width: none;
    @include mq(sp) {
      padding: 0 vw(15, $sp_w);
      background: transparent;
      height: vw(50, $sp_w);
      border-radius: vw(10, $sp_w);
    }
  }
  &__list {
    position: static;
    transform: none;
    height: vw(50, $pc_w);
    align-items: center;
    box-sizing: border-box;
    @include mq(sp) {
      height: vw(50, $sp_w);
    }

  }
  &__item {
    line-height: 1;
    margin-right: vw(20, $pc_w);

    &.-home {
      background: $black;
      width: vw(54, $pc_w);
      height: 100%;
      box-sizing: border-box;
      @include mq(sp) {
        background: transparent;
        width: vw(20, $sp_w);
        margin-top: 1vw;
      }

      a {
        display: flex;
        justify-content: center;
        align-items: center;
        height: vw(50, $pc_w);
        box-sizing: border-box;
        width: 100%;
        position: relative;
        @include mq(sp) {
          height: vw(44, $sp_w);
          justify-content: flex-start;
        }

        img {
          width: vw(16, $pc_w);
          @include mq(sp) {
            width: vw(10, $sp_w);
          }
        }
      }
    }
    &-text {
      color: $gray_main;
      font-size: vw(12, $pc_w);
      line-height: 1;
      padding: 0;
      margin: 0;
      position: relative;
      display: block;
      padding-left: 2em;
      @include mq(sp) {
        font-size: vw(9, $sp_w);
        color: $black;
      }

      &:before {
        content: "";
        border-top: 1px solid $gray_main;
        border-right: 1px solid $gray_main;
        width: vw(8, $pc_w);
        height: vw(8, $pc_w);
        position: absolute;
        top: 50%;
        left: 0;
        transform-origin: bottom right;
        transform: rotate(45deg) translateY(-50%);
        @include mq(sp) {
          border-top: 1px solid $black;
          border-right: 1px solid $black;
          width: vw(6, $sp_w);
          height: vw(6, $sp_w);
        }
      }
      a {
        opacity: 1 !important;;
        color: $gray_main;
        padding-bottom: 0;
        padding-top: 0;
        @include mq(sp) {
          color: $black;
        }
        &:hover {
          opacity: 1;
        }
        &.-bold {
          font-weight: 700;
        }
      }
    }
    &:first-child {
      .topicpath__item-text {
        padding-left: 0;
        &:before {
          content: none;
        }
      }
    }


  }
}

.section.banner {
  background: $gray_9;
  box-sizing: border-box;
  width: 100%;
  margin-top: vw(30, $pc_w);
  margin-bottom: 0;

  @include mq(sp) {
  }

  .bnr {
    background: none;
    flex-wrap: wrap;
    padding: vw(50, $pc_w) 0;
    @include mq(sp) {
      padding: vw(20, $sp_w) 0;
    }

    &__item {
      box-sizing: border-box;
      width: 23.6%;
      border-radius: vw(10, $pc_w);
      overflow: hidden;
      transition: all 1s cubic-bezier(0.23,1,0.32,1);
      opacity: 0;
      transform: translateY(20%);
      @include mq(sp) {
        width: 47.82%;
        margin-top: 0;
        margin-bottom: vw(15, $sp_w);
        background: $white;
        border-radius: vw(10, $sp_w);
        overflow: hidden;
      }
      &:nth-of-type(1) {
        transition: all 1s cubic-bezier(0.23,1,0.32,1);
      }
      &:nth-of-type(2) {
        transition: all 1s cubic-bezier(0.23,1,0.32,1) 0.1s;
      }
      &:nth-of-type(3) {
        transition: all 1s cubic-bezier(0.23,1,0.32,1) 0.2s;
      }
      &:nth-of-type(4) {
        transition: all 1s cubic-bezier(0.23,1,0.32,1) 0.3s;
      }
      &.-anime {
        transform: translateY(0);
        opacity: 1;
      }
      &:nth-of-type(n+3) {
        @include mq(sp) {
          margin-bottom: 0;
        }
      }
      a {
        border: none; // override
      }
      img {
        height: vw(100, $pc_w);
        min-width: 100%;
        object-fit: cover;
        object-position: center center;
        @include mq(sp) {
          height: vw(57, $sp_w);
        }
      }
    }
  }

  .innerelem {
    margin: 0;
    padding: 0;
  }

  .text {
    background: $white;
    box-sizing: border-box;
    padding: vw(15, $pc_w) vw(10, $pc_w);
    text-align: center;
    font-size: vw(15, $pc_w);
    font-weight: 700;
    line-height: 1.5;
    font-feature-settings: "palt";
    color: $gray_main2;
    letter-spacing: 0.05em;
    @include mq(sp) {
      font-size: vw(10, $sp_w);
      padding: vw(15, $sp_w) 0;
    }
  }
}
//banner

.section.contact {
  background: $gray_9;
  width: 100%;
  margin-bottom: 0;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: -300vw;
    z-index: 0;
    background: rgba(0, 169, 224, 1);
    /* background: $yblue; */
    width: 200vw;
    height: 100%;
    transform: skewX(-25deg);
    transition: all 1s;
  }

  .contact {
    &__wrap {
      background: url(/images/type/corporate.svg) no-repeat center bottom;
      background-size: 100vw auto;
      position: relative;
      z-index: 2;
    }

    &-list {
      display: flex;
      justify-content: space-between;

      @include mq(sp) {
        flex-direction: column;
      }

      &__wrap {
        padding: vw(150, $pc_w) 0;
        @include mq(sp) {
          padding: vw(65, $sp_w) 0;
        }
      }

      &__item {
        width: 49.375%;
        box-sizing: border-box;
        border-radius: vw(15, $pc_w);
        overflow: hidden;
        @include mq(sp) {
          width: 100%;
          border-radius: vw(10, $sp_w);
        }

        &:first-child {
          @include mq(sp) {
            margin-bottom: vw(14, $sp_w);
          }
        }
      }
      &__link {
        display: block;
        background: $yblue;
        color: $white;
        box-sizing: border-box;
        padding: vw(70, $pc_w) 0;
        text-align: center;
        transition: all 0.5s;

        @include mq(sp) {
          padding: vw(20, $sp_w) 0;
        }
      }
      &__text {
        &.-en {
          font-weight: 700;
          font-family: $font_en;
          font-size: vw(55, $pc_w);
          @include mq(sp) {
            font-size: vw(30, $sp_w);
          }
        }
        &.-ja {
          font-weight: 700;
          font-size: vw(20, $pc_w);
          margin-top: vw(15, $pc_w);
          letter-spacing: 0.05em;
          @include mq(sp) {
            font-size: vw(12, $sp_w);
            margin-top: vw(7, $sp_w);
          }
        }
      }
    }
  }
  //&:hover {
  &.-anime {
    &:before {
      left: -50vw;
      /* animation: skewanime .5s forwards; */
    }
    .contact {
      &-list {
        &__link {
          background: $white;
          color: $yblue;
        }
      }
    }
  }


}
//contact

@keyframes skewanime {
  100% {
    left: -150vw;
  }
}


.btn-more {
  width: vw(90, $pc_w);
  position: absolute;
  left: 50%;
  top: 0;
  opacity: 0;
  z-index: 100;

  @include mq(sp) {
    display: none;
  }
}

.sp-button {
  display: none;
  @include mq(sp) {
    display: block;
    width: vw(28, $sp_w);
  }
}

.stalker {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  opacity: 0;
  transition: all 0.8s ease-out 0s;
  pointer-events: none;

  /* mix-blend-mode: difference; */
  /*
  pointer-events: none;
  transform: translate3d(-10px, -10px, 0);
  */

  @include mq(sp) {
    display: none;
  }

  &.-show {
    opacity: 1;
  }

  &__inner {
    width: vw(90, $pc_w);
    height: vw(90, $pc_w);
    background: url(/images/btn_more.svg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }
}

.-ja .indexNews {
  &__inner {
    width: 100%;
  }

  &__thumb {

    img {
      width: vw(387, $pc_w);
      height: vw(198, $pc_w);
      object-position: center center;
      object-fit: cover;
      @include mq(sp) {
        width: vw(165, $sp_w);
        height: vw(86, $sp_w);
      }
    }
  }
}

.indexNews {
  &__list {
    margin-top: vw(50, $pc_w);
    @include mq(sp) {
      margin-top: vw(15, $sp_w);
    }
    &.-index {
      margin-top: vw(20, $pc_w);
    }
  }
  &__content {
    width: 100%;
  }
  &__item {
    width: 24.2%;
    @include mq(sp) {
      width: 47.6%;
      margin-bottom: vw(22, $sp_w);
    }
  }
  &__header {
    &--dt {
      display: flex;
      justify-content: flex-start;
    }
  }
  &__cat {
    color: $gray_main;
    font-size: vw(12, $pc_w);
    font-weight: 700;
    @include mq(sp) {
      font-size: vw(11, $sp_w);
    }
  }
  &__date {
    color: $gray_main;
    font-size: vw(12, $pc_w);
    font-weight: 700;
    margin-right: vw(15, $pc_w);
    margin-top: 0;
    @include mq(sp) {
      font-size: vw(11, $sp_w);
      margin-right: vw(6, $sp_w);
    }
  }
  &__name {
    font-size: vw(18, $pc_w);
    line-height: 1.5;

    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    line-clamp: 3;
    -webkit-line-clamp: 3;

    @include mq(sp) {
      font-size: vw(12, $sp_w);
      letter-spacing: 0.05em;
      line-clamp: 5;
      -webkit-line-clamp: 5;
    }
  }
  &__button {
    margin-top: vw(50, $pc_w);
    display: flex;
    justify-content: center;
    @include mq(sp) {
      margin-top: vw(16, $pc_w);
    }

    &--link {
      display: block;
      width: vw(160, $pc_w);
      background: $gray_8;
      border-radius: vw(10, $pc_w);
      text-align: center;
      display: flex;
      justify-content: center;
      padding: vw(15, $pc_w) 0;
      box-sizing: border-box;
      @include mq(sp) {
        width: vw(28, $sp_w);
        background: transparent;
        border-radius: 0;
        padding: 0;
      }
    }
    &--text {
      position: relative;
      font-size: vw(14, $pc_w);
      font-weight: 700;
      box-sizing: border-box;
      margin-left: -1em;

      &:after {
        content: "";
        border-top: 1px solid $black;
        border-right: 1px solid $black;
        width: vw(9, $pc_w);
        height: vw(9, $pc_w);
        position: absolute;
        top: 15%;
        right: -1em;
        transform: rotate(45deg);
      }
    }
  }
}
// indexNews

.page {
  .indexNews {
    &__list {
      &.news.press_release.-ja {
        &:before {
          content: "";
          width: 24.2%;
          order: 1;
        }
        &:after {
          content: "";
          width: 24.2%;
          order: 2;
        }
        @include mq(sp) {
          margin-bottom: vw(10, $sp_w);
        }
        .indexNews__item {
          width: 24.2%;
          @include mq(sp) {
            width: 48%;
            margin-bottom: vw(22, $sp_w);
          }

          //&:nth-child(n+7) {
          //  @include mq(sp) {
          //    margin-bottom: 0;
          //  }
          //}
        }
      }
    }
  }
}

