
// presskit {{{
.presskit {

  .section.guideline {
    background: #F4F9FF;
  }
  .presskit {
    &-ttl {
      display: flex;
      align-items: center;
      @include mq(sp) {
        flex-direction: column;
        align-items: flex-start;
      }

      &__en {
        font-family: $font_en;
        color: $yblue;
        font-weight: 700;
        font-size: vw(50, $pc_w);
        margin-right: vw(20, $pc_w);
        @include mq(sp) {
          font-size: vw(28, $sp_w);
          margin-right: 0;
          margin-bottom: vw(10, $sp_w);
        }
      }
      &__ja {
        color: $black;
        font-weight: 700;
        font-size: vw(20, $pc_w);
        @include mq(sp) {
          font-size: vw(12, $sp_w);
          margin-top: 0;
        }
      }
    }
    &__guideline {
      padding: vw(90, $pc_w) vw(160, $pc_w);
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      @include mq(sp) {
        padding: vw(30, $sp_w) vw(15, $sp_w);
        flex-direction: column;
      }

      .guideline-desc {
        width: vw(740, $pc_w);
        @include mq(sp) {
          width: 100%;
        }
      }
      .guideline-image {
        max-width: vw(786, $pc_w);
        @include mq(sp) {
          width: 100%;
          max-width: none;
          margin-top: vw(20, $sp_w);
        }

        img {
          width: 100%;
        }
      }
    }
  }
  //presskit

  .guideline {
    &-desc {
      .desc {
        margin-top: vw(50, $pc_w);
        line-height: 2;
        font-weight: 700;
        font-size: vw(22, $pc_w);
        @include mq(sp) {
          margin-top: vw(20, $sp_w);
          font-size: vw(14, $sp_w);
        }
      }
    }
    &-image {
    }
  }
  .dl-btn {
    margin-top: vw(50, $pc_w);
    @include mq(sp) {
      margin-top: vw(15, $sp_w);
      box-sizing: border-box;
      display: flex;
      justify-content: center;
    }
    &__link {
      background: $yblue;
      color: $white;
      border-radius: vw(10, $pc_w);
      position: relative;
      font-weight: 700;
      font-size: vw(14, $pc_w);
      padding: vw(13, $pc_w) vw(50, $pc_w) vw(13, $pc_w) vw(30, $pc_w);
      box-sizing: border-box;
      @include mq(sp) {
        font-size: vw(14, $sp_w);
        border-radius: vw(10, $sp_w);
        padding: vw(13, $sp_w) vw(50, $sp_w) vw(13, $sp_w) vw(30, $sp_w);
        
      }

      &:after {
        content: "";
        background-image: url(/images/icon/dl.svg);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        width: vw(10, $pc_w);
        height: vw(12, $pc_w);
        position: absolute;
        right: vw(30, $pc_w);
        top: 50%;
        transform: translateY(-50%);
        @include mq(sp) {
          width: vw(10, $sp_w);
          height: vw(12, $sp_w);
          right: vw(30, $sp_w);
        }
      }
    }
  }

  .section.lower {
    margin-top: vw(180, $pc_w);
    box-sizing: border-box;
    padding: 0 vw(245, $pc_w);
    @include mq(sp) {
      margin-top: vw(90, $sp_w);
      padding: 0 vw(15, $sp_w);
    }
  }
  .lower-section {
    margin-bottom: vw(160, $pc_w);
    @include mq(sp) {
      margin-bottom: vw(90, $sp_w);
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }
  .box {
    display: flex;
    margin-top: vw(50, $pc_w);
    //justify-content: space-between;
    @include mq(sp) {
      margin-top: vw(30, $sp_w);
      justify-content: flex-start;
      flex-direction: column;
    }

    &__left {
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #F4F9FF;
      height: vw(280, $pc_w);
      border-radius: vw(20, $pc_w);
      width: vw(700, $pc_w);
      overflow: hidden;
      margin-right: vw(50, $pc_w);
      @include mq(sp) {
        width: 100%;
        flex-direction: column;
        padding: 0 vw(15, $sp_w);
        height: vw(140, $sp_w);
        border-radius: vw(10, $sp_w);
        margin-right: 0;
      }
    }
    &__right {
      width: vw(630, $pc_w);
      @include mq(sp) {
        width: 100%;
        box-sizing: border-box;
      }
    }

    .desc {
      font-size: vw(22, $pc_w);
      line-height: 1.5;
      @include mq(sp) {
        font-size: vw(12, $sp_w);
        padding-top: vw(15, $sp_w);
      }
    }
    &__desc {
      font-size: vw(22, $pc_w);
      line-height: 1.5;
      @include mq(sp) {
        font-size: vw(12, $sp_w);
        padding-top: vw(15, $sp_w);
      }
    }
    .-image1 {
      width: vw(200, $pc_w);
      @include mq(sp) {
        width: vw(108, $sp_w);
      }
    }
    .-image2 {
      width: vw(275, $pc_w);
      @include mq(sp) {
        width: vw(130, $sp_w);
      }
    }
    .-image3 {
      width: vw(310, $pc_w);
      @include mq(sp) {
        width: vw(157, $sp_w);
      }
    }
    .-image4 {
      width: vw(350, $pc_w);
      @include mq(sp) {
        width: vw(168, $sp_w);
      }
    }
    .-image5 {
      width: vw(444, $pc_w);
      @include mq(sp) {
        width: vw(280, $sp_w);
      }
    }
    .-image6 {
      width: 100%;
    }
  }
  //box

}
